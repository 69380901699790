<template>
  <li>
    <div class="title">
      <span>경력*</span>
    </div>
    <div class="col">
      <template v-if="profiles.length > 0">
        <div v-for="type in typeOrder" :key="type" class="row">
          <div class="sub-title">
            <span>{{ getTypeLabel(type) }}</span>
          </div>
          <div class="field">
            <div class="inner-list">
              <div v-for="(profile, index) in groupedCareer[type]" class="li">
                <div class="input-wrap">
                  <select v-model="profile.year">
                    <option value="" disabled>연도</option>
                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                  </select>
                  <input type="text" v-model="profile.title" maxlength="20" placeholder="전시명"/>
                  <input type="text" v-model="profile.description" maxlength="20" placeholder="장소"/>
                  <input type="text" v-model="profile.location" maxlength="20" placeholder="지역"/>
                </div>
                <button v-if="index === 0" type="button" class="add-list" @click="addListItem(profile.type, profile.typeLabel)">
                  <img src="@/assets/images/icon/ico_add2.svg">
                </button>
                <button v-else type="button" class="add-list" @click="removeItem(profile.type, index)">
                  <img src="@/assets/images/icon/ico_remove.svg">
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
      </template>
    </div>
  </li>

</template>

<script setup>
import {computed, ref, toRefs} from 'vue';

const props = defineProps({
  title: String,
  profiles: Array,
  years: Array
});

const emit = defineEmits(['update-profile']);

const { profiles, years } = toRefs(props);

// 데이터 그룹화 헬퍼 함수
function groupByType(profiles) {
  return profiles.value.reduce((groups, item) => {
    const group = groups[item.type] || [];
    group.push(item);
    groups[item.type] = group;
    return groups;
  }, {});
}
// 그룹화된 데이터를 computed로 정의
const groupedCareer = computed(() => groupByType(profiles));
// 타입에 따른 라벨 반환 함수
const getTypeLabel = (type) => {
  const labels = {
    EXHIBITION: '개인전',
    GROUP_EXHIBITION: '그룹전',
    WORKSHOP: '워크숍',
    RESIDENCY: '레지던시',
  };
  return labels[type] || type;
};

// 출력 순서 배열
const typeOrder = ['EXHIBITION', 'GROUP_EXHIBITION', 'RESIDENCY', 'WORKSHOP'];

const emitUpdate = (profile) => {
  emit('update-profile', profile);
};

const emitDelete = (type, index) => {
  emit('delete-career', type, index);
};
const addListItem = (type, label) => {
  const newProfile = {
    id: null,
    type: type,
    label: label,
    year: '',
    title: '',
    description: '',
    location: ''
  };
  emit('update-career', newProfile); // 필요시 활성화
};
const removeItem = (type, index) => {
  emitDelete(type, index);
};

// 유효성 검사 함수
const profileErrors = ref([]);
const validateProfile = (profile) => {
  const index = profiles.value.indexOf(profile);
  if (index === -1) return;

  const errors = {};

  if (!profile.title) {
    errors.title = 'Title is required';
  }

  if (!profile.description) {
    errors.description = 'Description is required';
  }

  if (!profile.location) {
    errors.location = 'Location is required';
  }
  profileErrors.value[index] = errors;
};
</script>