<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div class="content-panel">
              <div class="my-artartist">
                <!--
                                <div v-if="mode === 'view'" class="profile">
                                  <h2 class="section-title">프로필</h2>
                                  <div class="view">
                                    <div class="picture-box" >
                                      <figure>
                                        <img :src="artist.profileImageUrl ? artist.profileImageUrl : 'https://article21-images.s3.ap-northeast-2.amazonaws.com/contents/logo_b.svg'" alt="" style="width: 163px;height: 228px;"/>
                                      </figure>
                                    </div>
                                    <div class="cont-box">
                                        <ul>
                                          <li>
                                            <div class="title">
                                              <span>작가명</span>
                                            </div>
                                            <div class="col">
                                              <div class="row">
                                                <div class="sub-title">
                                                  <span>국문</span>
                                                </div>
                                                <div class="field">{{ artist.artistNameKo }}</div>
                                              </div>
                                              <div class="row">
                                                <div class="sub-title">
                                                  <span>영문</span>
                                                </div>
                                                <div class="field">{{ artist.artistNameEn }}</div>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="title">
                                              <span>학력</span>
                                            </div>
                                            <div class="col">
                                              <div class="row" v-for="item in education">
                                                <template v-if="item.type === 'UNDERGRADUATE'">
                                                  <div class="sub-title">
                                                    <span>학부</span>
                                                  </div>
                                                  <div class="field">
                                                    <template v-if="item.title">
                                                      {{ item.year }} {{ item.title }} {{ item.description }} {{ item.statusLabel }}
                                                    </template>
                                                  </div>
                                                </template>
                                                <template v-else-if="item.type === 'GRADUATE'">
                                                  <div class="sub-title">
                                                    <span>대학원</span>
                                                  </div>
                                                  <div class="field">
                                                    <template v-if="item.title">
                                                      {{ item.year }} {{ item.title }} {{ item.description }} {{ item.statusLabel }}
                                                    </template>
                                                  </div>
                                                </template>
                                                <template v-else>
                                                  <div class="sub-title">
                                                    <span>기타</span>
                                                  </div>
                                                  <div class="field">
                                                      <template v-if="item.title">
                                                        {{ item.year }} {{ item.title }} {{ item.description }} {{ item.statusLabel }}
                                                      </template>
                                                  </div>
                                                </template>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="title">
                                              <span>경력</span>
                                            </div>
                                            <div class="col">
                                              <div class="row" v-for="type in typeOrder" :key="type">
                                                <div class="sub-title">
                                                  <span>{{ getTypeLabel(type) }}</span>
                                                </div>
                                                <div class="field">
                                                  <p v-for="item in groupedCareer[type]">
                                                    <template v-if="item.title">
                                                      {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                                                    </template>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="title">
                                              <span>활동 지역</span>
                                            </div>
                                            <div class="col">
                                              <div class="row">
                                                <div class="sub-title">
                                                  <span>국내</span>
                                                </div>
                                                <div class="field">
                                                  {{ artist.domesticArea }}
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="sub-title">
                                                  <span>해외</span>
                                                </div>
                                                <div class="field">
                                                  {{ artist.overseasArea }}
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                          <li class="colspan">
                                            <div class="title">
                                              <span>활동 장르</span>
                                            </div>
                                            <div class="col">
                                              <div class="row">
                                                <div class="field">{{ activityGenreName.join(", ") }}</div>
                                              </div>
                                            </div>
                                          </li>
                                          <li class="colspan">
                                            <div class="title">
                                              <span>수상 내역</span>
                                            </div>
                                            <div class="col">
                                              <div class="row">
                                                <div class="field">
                                                  <p v-for="item in others">
                                                    <template v-if="item.type === 'AWARD'">
                                                      {{ item.title }}
                                                    </template>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                          <li class="colspan">
                                            <div class="title">
                                              <span>작품 소장 내역</span>
                                            </div>
                                            <div class="col">
                                              <div class="row">
                                                <div class="field">
                                                  <p v-for="item in others">
                                                    <template v-if="item.type === 'COLLECTION'">
                                                      {{ item.title }}
                                                    </template>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                          <li class="colspan">
                                            <div class="title">
                                              <span>아티스트 한 줄 소개</span>
                                            </div>
                                            <div class="col">
                                              <div class="row">
                                                <div class="field">{{ artist.introduction }}</div>
                                              </div>
                                            </div>
                                          </li>
                                          <li class="colspan">
                                            <div class="title">
                                              <span>인터뷰 링크</span>
                                            </div>
                                            <div class="col">
                                              <div class="row">
                                                <div class="field">
                                                  <p v-for="item in others">
                                                    <template v-if="item.type === 'INTERVIEW'">
                                                      {{ item.title }}
                                                    </template>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                          <li class="colspan">
                                            <div class="title">
                                              <span>동영상 링크</span>
                                            </div>
                                            <div class="col">
                                              <div class="row">
                                                <div class="field">{{ artist.videoLink }}</div>
                                              </div>
                                            </div>
                                          </li>
                                          <li class="colspan">
                                            <div class="title">
                                              <span>동영상 파일</span>
                                            </div>
                                            <div class="col">
                                              <div class="row">
                                                <div class="field">{{ artist.videoUrl }}</div>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                        <div class="button-box">
                                          <button type="button" @click="mode='update'">수정하기</button>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                -->
                <div class="profile">
                  <h2 class="section-title">프로필</h2>
                  <form>
                    <div class="register">
                      <div class="picture-box">
                        <div class="img-wrap">
                          <div class="filebox">
                            <label for="upload-img">이미지 업로드</label>
                            <input ref="fileInput" type="file" id="upload-img" @change="(event) => previewFile(event, true)" accept="image/*" />
                            <img v-if="previewImageFile" id="output" :src="previewImageFile.url" :alt="previewImageFile.name"/>
                          </div>
                        </div>
                        <p class="txt">270x377px 이하 이미지를 등록해주세요.</p>
                      </div>
                      <div class="cont-box">
                        <ul>
                          <li>
                            <div class="title">
                              <span>작가명</span>
                            </div>
                            <div class="col">
                              <div class="row">
                                <div class="sub-title">
                                  <span>국문 *</span>
                                </div>
                                <div class="field">
                                  <input type="text" v-model="artist.artistNameKo" @input="validateArtistName" style="height: 41px;">
                                  <p v-if="artistNameError1" class="txt" style="color: red;">{{ artistNameError1 }}</p>
                                </div>
                              </div>
                              <div class="row">
                                <div class="sub-title">
                                  <span>영문 *</span>
                                </div>
                                <div class="field">
                                  <input type="text" v-model="artist.artistNameEn" @input="validateArtistName" style="height: 41px;">
                                  <p v-if="artistNameError2" class="txt" style="color: red;">{{ artistNameError2 }}</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <ProfileMultiInput title="학력"
                                             ref="educationRef"
                                             :profiles="education"
                                             :years="years"
                                             @update-education="updateEducation"/>

                          <ProfileCareer title="경력"
                                         ref="careerRef"
                                         :profiles="career"
                                         :years="years"
                                         @update-career="updateCareer"
                                         @delete-career="deleteCareer"/>
                          <li>
                            <div class="title">
                              <span>활동 지역</span>
                            </div>
                            <div class="col">
                              <div class="row">
                                <div class="sub-title">
                                  <span>국내</span>
                                </div>
                                <div class="field">
                                  <input type="text" v-model="artist.domesticArea">
                                </div>
                              </div>
                              <div class="row">
                                <div class="sub-title">
                                  <span>해외</span>
                                </div>
                                <div class="field">
                                  <input type="text" v-model="artist.overseasArea">
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="colspan">
                            <div class="title">
                              <span>활동 장르</span>
                            </div>
                            <div class="col">
                              <div class="row">
                                <div class="field">
                                  <div class="inner-list">
                                    <div class="li">
                                      <div class="chk-group">
                                        <span v-for="genre in genreOptions" :key="genre.id" class="checkbox-type1">
                                          <input type="checkbox" :id="'chk1-' + genre.id" :value="genre.id" v-model="selectedGenres">
                                          <label :for="'chk1-' + genre.id">{{ genre.label }}</label>
                                        </span>
                                      </div>
                                      <input type="text" class="etc" v-model="artist.description" v-show="selectedGenres.includes('ETC')">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <!-- 수상 -->
                          <li class="colspan">
                            <ProfileInput title="수상 내역"
                                          :profiles="profilesData('AWARD')"
                                          @update-others="updateOthers"
                                          @delete-profile="deleteOthers"/>
                          </li>
                          <!-- 작품소장 -->
                          <li class="colspan">
                            <ProfileInput title="작품 소장 내역"
                                          :profiles="profilesData('COLLECTION')"
                                          @update-others="updateOthers"
                                          @delete-profile="deleteOthers"/>
                          </li>
                          <li class="colspan">
                            <div class="title">
                              <span>아티스트 한 줄 소개</span>
                            </div>
                            <div class="col">
                              <div class="row">
                                <div class="field">
                                  <div class="inner-list">
                                    <div class="li">
                                      <input type="text" v-model="artist.introduction">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="colspan">
                            <ProfileInput title="인터뷰 링크"
                                          :profiles="profilesData('INTERVIEW')"
                                          @update-others="updateOthers"
                                          @delete-profile="deleteOthers"/>
                          </li>
                          <li class="colspan">
                            <div class="title">
                              <span>동영상 링크</span>
                            </div>
                            <div class="col">
                              <div class="row">
                                <div class="field">
                                  <div class="inner-list">
                                    <div class="li">
                                        <input type="text" v-model="artist.videoLink">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="colspan">
                            <div class="title">
                              <span>동영상 파일</span>
                            </div>
                            <div class="col">
                              <div class="row">
                                <div class="field">
                                  <div class="inner-list">
                                    <div class="li">
                                      <div class="input-file">
                                        <div class="filebox">
                                          <label for="upload-video">영상 업로드</label>
                                          <input type="file" id="upload-video" @change="(event) => previewFile(event, false)" accept="video/*">
                                          <video v-if="previewVideoFile.url" :src="previewVideoFile.url" controls width="400" height="220"></video>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div class="button-box">
                          <button type="button" @click="reload" style="margin-right: 10px;">취소하기</button>
                          <button type="button" @click="saveProfile">저장하기</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
    </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import {computed, onMounted, ref} from 'vue';
import 'swiper/swiper-bundle.css';
import {apiClient} from "@/services/auth-header";
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import ProfileMultiInput from "@/components/ProfileEducation.vue";
import ProfileInput from "@/components/ProfileInput.vue";
import ProfileCareer from "@/components/ProfileCareer.vue";

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const mode = ref("view");

// 토글 메뉴 함수
const navIconRef = ref(null);
const sideRef = ref(null);
const toggleMenu = () => {
  navIconRef.value.classList.toggle('open');
  sideRef.value.classList.toggle('active');
  document.body.classList.toggle('on');
};

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 45 }, (_, i) => currentYear - 44 + i);

const artist = ref({
});
const previewImageFile = ref(null);
const previewVideoFile = ref(null);
const selectedImageFile = ref(null);
const selectedVideoFile = ref(null);

const education = ref([] );
const career = ref([] );
const others = ref([] );

// 기본 프로필 데이터
const defaultProfiles = [
  { id: "", artistId: "", label: "학부", type: 'UNDERGRADUATE', year: '', title: '', description: '', location: '', educationStatus: "" },
  { id: "", artistId: "", label: "대학원", type: 'GRADUATE', year: '', title: '', description: '', location: '', educationStatus: "" },
  { id: "", artistId: "", label: "기타", type: 'OTHER', year: '', title: '', description: '', location: '', educationStatus: "" },
  { id: "", artistId: "", label: "개인전", type: 'EXHIBITION', year: '', title: '', description: '', location: '' },
  { id: "", artistId: "", label: "그룹전", type: 'GROUP_EXHIBITION', year: '', title: '', description: '', location: '' },
  { id: "", artistId: "", label: "레지던시", type: 'RESIDENCY', year: '', title: '', description: '', location: '' },
  { id: "", artistId: "", label: "워크숍", type: 'WORKSHOP', year: '', title: '', description: '', location: '' },
  { id: "", artistId: "", label: "수상", type: 'AWARD', year: '', title: '', description: '', location: '' },
  { id: "", artistId: "", label: "작품 소장", type: 'COLLECTION', year: '', title: '', description: '', location: '' },
  { id: "", artistId: "", label: "인터뷰", type: 'INTERVIEW', year: '', title: '', description: '', location: '' },
];

const selectedGenres = ref([]);
const genreOptions = [
  { id: 'WESTERN_PAINTING', label: '서양화' },
  { id: 'KOREAN_PAINTING', label: '한국화' },
  { id: 'SCULPTURE', label: '조소' },
  { id: 'INSTALLATION', label: '설치' },
  { id: 'MEDIA', label: '미디어' },
  { id: 'PHOTOGRAPHY', label: '사진' },
  { id: 'ILLUSTRATION', label: '일러스트' },
  { id: 'ETC', label: '기타' },
];

// 그룹화된 데이터를 computed로 정의
const groupedCareer = computed(() => groupByType(career));

// 타입에 따른 라벨 반환 함수
const getTypeLabel = (type) => {
  const labels = {
    EXHIBITION: '개인전',
    GROUP_EXHIBITION: '그룹전',
    WORKSHOP: '워크숍',
    RESIDENCY: '레지던시',
  };
  return labels[type] || type;
};
// 출력 순서 배열
const typeOrder = ['EXHIBITION', 'GROUP_EXHIBITION', 'RESIDENCY', 'WORKSHOP'];

const reload = () => {
  window.location.reload();
};
// 컴포넌트가 마운트되었을 때 데이터 로드
onMounted(() => {
  loadProfile();
});

const activityGenreMapping = {
  WESTERN_PAINTING: '서양화',
  KOREAN_PAINTING: '한국화',
  SCULPTURE: '조각',
  INSTALLATION: '설치',
  MEDIA: '미디어',
  PHOTOGRAPHY: '사진',
  ILLUSTRATION: '일러스트',
  ETC: '기타'
};

const mapActivityGenreToName = (genres) => {
  return genres.map(genre => activityGenreMapping[genre]);
}
const activityGenreName = ref([]);

// 데이터 로드 및 초기화
const loadProfile = async () => {
  try {
    const response = await apiClient.post('/user/profile');
    artist.value = response.data;
    if(response.data.profile.length === 0){
      artist.value.profile = defaultProfiles;
    }
    artist.value.profile.forEach(item => {
      let { type } = item;
      if (type === "UNDERGRADUATE" || type === "GRADUATE" || type === "OTHER") {
        education.value.push(item);
      } else if (type === "EXHIBITION" || type === "GROUP_EXHIBITION" || type === "RESIDENCY" || type === "WORKSHOP") {
        career.value.push(item);
      } else {
        others.value.push(item);
      }
    });
    selectedGenres.value = artist.value.activityGenre;
    activityGenreName.value = mapActivityGenreToName(artist.value.activityGenre);
    previewImageFile.value = { url: artist.value.profileImageUrl };
    previewVideoFile.value = { url: artist.value.videoUrl };
    career.value.sort((a, b) => {
      return b.year - a.year;
    });
    loading.value = false;
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
  }
};
const educationRef = ref(null);
const careerRef = ref(null);
const saveProfile = async () => {
  const isValid = await validateArtistName();
  if (!isValid) {
    loading.value = false;
    return;
  }
  if (confirm("저장 하시겠습니까?")) {
    loading.value = true; // 작업이 시작되면 로딩 상태를 시작합니다.
    const formData = new FormData();
    artist.value.profile = [
      ...education.value,
      ...career.value,
      ...others.value
    ];
    if (selectedImageFile.value) formData.append('image', selectedImageFile.value);
    if (selectedVideoFile.value) formData.append('video', selectedVideoFile.value);
    // 장르
    artist.value.activityGenre = selectedGenres.value;
    formData.append('profileData', new Blob([JSON.stringify(artist.value)], { type: 'application/json' }));
    try {
      const response = await apiClient.put('/user/profile/save', formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if(response.status === 200){
        alert("저장 되었습니다.");
        location.reload();
      }
    } catch (error) {
      error.value = error; // 에러를 저장합니다.
    } finally {
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }
};

const artistNameError1 = ref('');
const artistNameError2 = ref('');
const validateArtistName = async () => {
  if (!artist.value.artistNameKo) {
    artistNameError1.value = '작가명 국문을 입력해 주세요.';
    return false;
  } else {
    artistNameError1.value = '';
  }
  if (!artist.value.artistNameEn) {
    artistNameError2.value = '작가명 영문을 입력해 주세요.';
    return false;
  } else {
    artistNameError2.value = '';
  }
  return true;
}

const validateEducation = () => {
  let result = false;
  education.value.forEach(item => {
    if(!item.year){
      result = true;
    }else if(!item.title){
      result = true;
    }else if(!item.description){
      result = true;
    }else if(!item.educationStatus){
      result = true;
    }
  });
  return result;
}

const fileInput = ref(null);
const maxWidth = 270;
const maxHeight = 377;
const previewFile = async (event, isImage) => {
  const file = event.target.files[0];
  if (!file) return;

  const url = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(new Error('파일 읽기 오류'));
    reader.readAsDataURL(file);
  });
  if (isImage) {
    const img = new Image();
    const maxFileSize = 10 * 1024 * 1024; // 10MB
    img.onload = () => {
      if (img.width > maxWidth || img.height > maxHeight) {
        alert(`이미지 크기는 ${maxWidth}x${maxHeight} 픽셀을 초과할 수 없습니다.`);
        fileInput.value.value = ''; // 파일 입력 초기화
      } else if (file.size > maxFileSize) {
        alert('파일 크기는 10MB를 초과할 수 없습니다.');
        fileInput.value.value = ''; // 파일 입력 초기화
      } else {
        previewImageFile.value = {name: file.name, url, type: file.type};
        selectedImageFile.value = file;
      }
      URL.revokeObjectURL(url); // 메모리 해제를 위해 URL 객체 해제
    };
    img.src = url;
  } else {
    previewVideoFile.value = {name: file.name, url, type: file.type};
    selectedVideoFile.value = file;
  }
};

const profilesData = computed(() => {
  const profiles = others.value.length > 0 ? others.value : defaultProfiles;
  return (type) => profiles.filter(profile => profile.type === type);
});

// 프로필 업데이트 함수
const updateEducation = (profile) => {
  education.value.push(profile);
};

const updateCareer = (profile) => {
  career.value.push(profile);
};

const updateOthers = (profile) => {
  others.value.push(profile);
};

const deleteCareer = (type, index) => {
  let foundIndex = -1;
  for (let i = 0; i < career.value.length; i++) {
    if (career.value[i].type === type) {
      foundIndex++;
      if (foundIndex === index) {
        career.value.splice(i, 1);
        break;
      }
    }
  }
};

const deleteOthers = (type, index) => {
  let foundIndex = -1;
  for (let i = 0; i < others.value.length; i++) {
    if (others.value[i].type === type) {
      foundIndex++;
      if (foundIndex === index) {
        others.value.splice(i, 1);
        break;
      }
    }
  }
};

// 데이터 그룹화 헬퍼 함수
function groupByType(career) {
  return career.value.reduce((groups, item) => {
    const group = groups[item.type] || [];
    group.push(item);
    groups[item.type] = group;
    return groups;
  }, {});
}
</script>