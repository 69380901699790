<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div class="content-panel" style="max-width:100%;">
              <div class="my-scrap">
                <section>
                  <h2>ART & ARTISTS</h2>
                  <div class="art-artist">
                    <div class="list">
                      <ul>
                        <li v-for="item in fetchedData" :key="item.productId">
                          <a href="#">
                            <div class="txt">
                              <h3>BINNA CHOI</h3>
                              <p>최빛나</p>
                            </div>
                            <div class="img-box" style="background:#323c65 url(@/assets/images/main/artists_bg.png) top center no-repeat;background-size: cover;">
                              <figure>
                                <img src="@/assets/images/main/artists1.png" alt="">
                              </figure>
                            </div>
                          </a>
                          <button type="button" class="btn-like on">좋아요</button>
                        </li>
                        <!-- 스크랩 없을 경우 -->
                        <li class="nodata">
                          스크랩 된 ART & ARTISTS이 없습니다.
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
                <section>
                  <h2>ARTICLER</h2>
                  <div class="articler-list">
                    <ul>
                      <li>
                        <div class="img">
                          <img src="@/assets/images/main/articler01.svg" alt="">
                        </div>
                        <div class="txt-box">
                          <em>ARTICLER</em>
                          <p>TANSAN</p>
                          <h3>언어를 잃은 <br>어른이를 위한 놀이터</h3>
                          <a href="#">See More</a>
                        </div>
                        <button type="button" class="btn-like on">좋아요</button>
                      </li>
                    </ul>
                  </div>
                </section>
                <section>
                  <h2>ARTICLE</h2>
                  <div class="article">
                    <h3>SPECIAL</h3>
                    <div class="list-type3">
                      <ul>
                        <li>
                          <a href="#">
                            <div class="img">
                              <img src="@/assets/images/@draft/@special1.png" alt="">
                            </div>
                            <div class="txt-box">
                              <h3>삶의 아이러니에서 시작된 작업들</h3>
                              <p>아티스트 이올</p>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="article">
                    <h3>EXHIBITION</h3>
                    <div class="list-type1">
                      <ul>
                        <li>
                          <a href="#">
                            <div class="img">
                              <img src="@/assets/images/@draft/@exhibition01.png" alt="">
                            </div>
                            <div class="txt-box">
                              <h3>한국의 기하학적 추상미술</h3>
                              <p class="date">
                                기간: 2023. 11. 16. ~ 2024. 5. 19.
                              </p>
                              <p class="location">
                                장소: 국립현대미술관 과천 1층,<br>
                                1, 2 전시실 및 중앙홀
                              </p>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="article">
                    <h3>EVENT</h3>
                    <div class="list-type1">
                      <ul>
                        <li>
                          <a href="#">
                            <div class="img">
                              <img src="@/assets/images/@draft/@event01.png" alt="">
                            </div>
                            <div class="txt-box">
                              <h3>서울아트쇼 2023</h3>
                              <p class="date">
                                기간: 2023. 12. 22 ~ 202. 12. 26
                              </p>
                              <p class="location">
                                장소: 코엑스
                              </p>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="article">
                    <h3>NEWS</h3>
                    <div class="list-type2">
                      <ul>
                        <li>
                          <a href="#">
                            <div class="title-box">
                              <h3>2024 브리즈 아트페어 작가공모</h3>
                            </div>
                            <div class="txt-box">
                              <p class="date">
                                지원기간: 2023.12.1. ~ 1.10 자정까지
                              </p>
                              <p class="support">
                                지원방법: 구글폼 온라인 신청서 작성 및 포트폴리오 제출, 접수비 입금(5만 원)
                              </p>
                              <p class="writers">
                                참여작가: 총 100여 명 규모
                              </p>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
                <section>
                  <h2>ARTSHOP</h2>
                  <div class="artshop">
                    <div class="goods">
                      <div class="list">
                        <ul>
                          <li>
                            <a href="#">
                              <div class="img">
                                <img src="@/assets/images/@draft/@goods01.png" alt="">
                              </div>
                              <div class="txt-box">
                                <h3>Calendar</h3>
                                <p class="price">16,000원</p>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
    </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import {onMounted, ref, watch} from 'vue';
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import {apiClient} from "@/services/auth-header";

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const API_URL = "/shop";

const wishlist = ref([]);
const selectedProducts = ref([]);

const deleteWishlist = async () => {
  if (confirm("관심상품에서 제외 하시겠습니까?")) {
    try {
      const response = await apiClient.put(API_URL + '/wishlist/delete', selectedProducts.value);
      if (response.status === 200) {
        alert("관심상품에서 삭제 되었습니다.");
        await loadData();
      }
    } catch (error) {
      error.value = error; // 에러를 저장합니다.
    } finally {
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }
};

const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};

const loadData = async () => {
  try {
    const response = await apiClient.post(API_URL + '/wishlist/list');
    wishlist.value = response.data;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }finally {
    loading.value = false;
  }
};

onMounted(() => {
  document.body.classList.remove('bg-white');
  loadData();
});
</script>