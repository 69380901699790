<template>
  <footer>
    <div class="layout">
      <div class="col1">
        <a href="#" class="f-logo">
          <img src="@/assets/images/common/logo.svg" alt="" style="width: 75px;height: 21px;"/>
        </a>
        <address>
          <p>
            <em>CEO</em>
            AHRUM OH
          </p>
          <p>
            <em>OFFICE</em>
            405ho, Daehak-ro 600, Gunsan-si, Jeonbuk, Republic of Korea<br>
            5th and 6th floor, Ttukseom-ro 1-gil, Seongdong-gu, Seoul
          </p>
        </address>
      </div>
<!--      <img src='https://image.inicis.com/mkt/certmark/inipay/inipay_60x60_gray.png'-->
<!--           alt='클릭하시면 이니시스 결제시스템의 유효성을 확인하실 수 있습니다.'-->
<!--           style='cursor: pointer;'-->
<!--           @click="openInisisPopup">-->
<!--      <img src='https://image.inicis.com/mkt/certmark/escrow/escrow_60x60_gray.png'-->
<!--           alt='클릭하시면 이니시스 결제시스템의 유효성을 확인하실 수 있습니다.'-->
<!--           style='cursor: pointer;'-->
<!--           @click="openEscrowPopup">-->
      <div class="col2">
        <div class="box">
          <h4>BUSINESS <br> LICENSE</h4>
          <p class="txt1">509-86-02834</p>
          <p class="txt1">2023-전북군산-0606</p>
          <p @click="openPopup" class="txt2">Check business information</p>
        </div>
        <div class="box">
          <h4>CONTACT US <br> AND WORK TOGETHRE</h4>
          <dl class="dl-type1">
            <dt>PHONE</dt>
            <dd>+82 - (0)10 - 2132 - 5634</dd>
            <dt>EMAIL</dt>
            <dd>hello@article21.co. kr</dd>
          </dl>
        </div>
        <div class="box">
          <h4><br><br></h4>
          <nav>
            <router-link to="/support/inquiry">1:1 Inquiry</router-link>
            <a href="/terms">Terms of use</a>
            <a href="/privacy">Privacy Policy</a>
          </nav>
          <dl class="dl-type1">
            <dt>CSR</dt>
            <dd>+ 82 - (0)507 - 1395 - 5634</dd>
          </dl>
          <p class="txt3">WORK HOURS 10:00 - 18:00 Weekdays  (Excluding 12:00 - 13:00)</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>

const openPopup = () => {
  window.open("https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5098602834", "bizCommPop", "width=750, height=700;");
}
const openInisisPopup = () => {
  window.open('https://mark.inicis.com/mark/popup_v3.php?mid=article266','mark','scrollbars=no,resizable=no,width=565,height=683');
}
const openEscrowPopup = () => {
  window.open('https://mark.inicis.com/mark/escrow_popup_v3.php?mid=article266','mark','scrollbars=no,resizable=no,width=565,height=683');
}

</script>

<style scoped>
</style>
