<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div class="content-panel">
              <div class="my-wish">
                <h3>상품 <strong>{{ wishlist.length }}</strong>건</h3>
                <div class="list">
                  <div class="list-top">
                    <span class="checkbox-type1">
                      <input type="checkbox" id="allCheck" @change="toggleSelectAll"/>
                      <label for="allCheck">전체선택</label>
                    </span>
                    <div>
                      <button @click="deleteWishlist" type="button">선택삭제</button>
                    </div>
                  </div>
                  <ul v-if="wishlist.length > 0">
                    <li v-for="(product, index) in wishlist" :key="index">
                      <span class="checkbox-type1">
                        <input type="checkbox" :id="'chk' + index" :value="product.productId" @change="updateChecked"/>
                        <label :for="'chk' + index">선택</label>
                      </span>
                      <div class="item-img">
                        <img :src="product.productDetail.images[0].imageUrl" alt=""/>
                      </div>
                      <div class="item-info">
                        <h4>{{ product.productDetail.name }}</h4>
                        <p class="p-txt">{{ product.productDetail.options[0].price.toLocaleString() }}원</p>
                      </div>
                    </li>
                  </ul>
                  <div v-else class="nodata">
                    관심상품이 없습니다.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
    </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import {onMounted, ref, watch} from 'vue';
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import {apiClient} from "@/services/auth-header";

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const API_URL = "/shop";

const wishlist = ref([]);
const selectedProducts = ref([]);

const toggleSelectAll = (event) => {
  selectedProducts.value = [];
  const chk = event.target.checked;
  $("input[type='checkbox']").prop('checked', chk);
  $("input[type='checkbox']").each((index, el) => {
    const data = {
      productId: $(el).val()
    }
    if(index > 0){
      if(chk){
        selectedProducts.value.push(data);
      }else{
        selectedProducts.value = selectedProducts.value.filter(product => product.productId !== data.productId);
      }
    }
  });
};

const updateChecked = (event) => {
  const data = {
    productId: event.target.value
  }
  if(event.target.checked){
    selectedProducts.value.push(data);
  }else{
    selectedProducts.value = selectedProducts.value.filter(product => product.productId !== data.productId);
  }
};

const deleteWishlist = async () => {
  if (confirm("관심상품에서 제외 하시겠습니까?")) {
    try {
      const response = await apiClient.put(API_URL + '/wishlist/delete', selectedProducts.value);
      if (response.status === 200) {
        alert("관심상품에서 삭제 되었습니다.");
        await loadData();
      }
    } catch (error) {
      error.value = error; // 에러를 저장합니다.
    } finally {
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }
};

const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};

const loadData = async () => {
  try {
    const response = await apiClient.post(API_URL + '/wishlist/list');
    wishlist.value = response.data;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }finally {
    loading.value = false;
  }
};

onMounted(() => {
  document.body.classList.remove('bg-white');
  loadData();
});
</script>