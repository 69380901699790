import Special from '@/views/article/Special.vue';

export const articlerRoutes = [

    {
        path: '/articler',
        name: 'Article',
        redirect: '/articler/articler',
        children: [
            {
                path: '/article/special',
                name: 'Special',
                component: Special,
                props: true
            },
        ]
    },

];