<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div class="content-panel">
              <div class="my-order">
                <div class="list">
                  <div class="search-period">
                    <div class="label">조회기간</div>
                    <div class="search-wrap">
                      <div class="button-box">
                        <button type="button" class="on" @click="handleClick(1, $event)">오늘</button>
                        <button type="button" @click="handleClick(7, $event)">7일</button>
                        <button type="button" @click="handleClick(15, $event)">15일</button>
                        <button type="button" @click="handleClick(30, $event)">1개월</button>
                        <button type="button" @click="handleClick(90, $event)">3개월</button>
                      </div>
                      <div class="date-wrap">
                        <input v-model="selectedStartDate" type="date">
                        <span>~</span>
                        <input v-model="selectedEndDate" type="date">
                      </div>
                    </div>
                    <button @click="loadData" type="button" class="submit">조회</button>
                  </div>

                  <div class="item-list">
                    <h2 class="section-title">주문 목록 및 배송 조회</h2>
                    <div v-for="order in orderList" :key="order.id" class="list-box">
                      <div class="top">
                        <h3>{{ String(order.orderDate).replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') }}</h3>
                        <router-link :to="{ name: 'OrderDetail', params: { orderId: order.id }}">
                          상세보기
                        </router-link>
                      </div>
                      <ul>
                        <li v-for="detail in order.detail" :key="detail.id">
                          <div class="item-img">
                            <img :src="detail.imageUrl" alt=""/>
                          </div>
                          <div class="item-info">
                            <h4>{{ detail.name }}</h4>
                            <div class="quantity-wrap">
                              {{ detail.optionName }} / {{ detail.quantity }}개
                            </div>
                            <p class="p-txt">{{ detail.subtotal.toLocaleString() }}</p>
                          </div>
                          <h3></h3>
                          <div class="status-box">
<!--                            <router-link :to="{ name: 'OrderDetail', params: { orderId: order.id }}">-->
<!--                              <span>{{ parseStatus(order.paymentStatus) }}</span>-->
<!--                            </router-link>-->
                            <span v-if="order.cancelStatus === null">{{ parseStatus(order.paymentStatus) }}</span>
                            <span v-if="order.cancelStatus !== null">{{ parseCancelStatus(order.cancelStatus) }}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div v-if="orderList.length === 0" class="nodata">
                      조회 내역이 없습니다.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
    </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import {onMounted, ref} from 'vue';
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import {apiClient} from "@/services/auth-header";
import {useRouter} from "vue-router";

const router = useRouter();
// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const selectedPeriod = ref(1);
const selectedStartDate = ref('');
const selectedEndDate = ref('');
const orderList = ref([]);

const API_URL = "/user";
const isMobile = ref(false);
const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};
// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};
const handleClick = (period, event) => {
  const buttons = event.target.parentElement.querySelectorAll('button');
  buttons.forEach(button => button.classList.remove('on'));
  event.target.classList.add('on');

  const startDate = new Date();
  const endDate = new Date();

  startDate.setDate(endDate.getDate() - period);

  selectedStartDate.value = startDate.toISOString().split('T')[0];
  selectedEndDate.value = endDate.toISOString().split('T')[0];
  selectedPeriod.value = period;
  loadData();
};

const loadData = async () => {
  try {
    const response = await apiClient.post(API_URL + '/my/order', {
      searchPeriod: selectedPeriod.value,
      startDate: selectedStartDate.value,
      endDate: selectedEndDate.value
    });
    orderList.value = response.data;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }finally {
    loading.value = false;
  }
};

const parseStatus = (val) => {
  const statusMap = {
    0: "입금 대기",
    1: "결제 대기",
    2: "결제 완료",
    3: "상품 준비",
    4: "배송 준비",
    5: "배송 대기",
    6: "배송 중",
    7: "배송 완료"
  };

  return statusMap[val] || "";
};
const parseCancelStatus = (val) => {
  const statusMap = {
    0: "취소 신청",
    1: "취소 완료",
  };

  return statusMap[val] || "";
};


onMounted(() => {
  document.body.classList.remove('bg-white');
  checkMobile();
  loadData();
});
</script>