<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div class="content-panel">
              <div class="customer">
                <div class="bbs">
                  <section v-if="inquiry.id">
                    <h2 class="section-title">1:1 문의</h2>
                    <div class="bbs-view">
                      <em>{{ inquiry.categoryId }}</em>
                      <h3>{{ inquiry.title }}</h3>
                      <div class="util">
                        <span>{{ inquiry.createdBy }}</span>
                        <span>{{ inquiry.createdAt }}</span>
                        <em v-if="inquiry.isAnswer" class="status case2">답변완료</em>
                        <em v-else class="status case1">답변대기</em>
                      </div>
                      <div class="view-content">
                        {{ inquiry.content }}
                        <div v-if="inquiry.isAnswer" class="reply-cont">
                          <h3>답변</h3>
                          <div class="txt-cont">
                            {{ inquiry.answer }}
                          </div>
                        </div>
                      </div>
                      <div class="button-box">
                        <a @click="goList" href="#">목록으로</a>
                      </div>
                    </div>
                  </section>
                  <section v-else>
                    <h2 class="section-title">1:1 문의</h2>
                    <div class="bbs-register">
                      <div class="form-box">
                        <div class="field-row">
                          <div class="label">
                            <span>분류</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <select v-model="inquiry.categoryId" @change="selectedCategory" style="width: 300px;">
                                <option value="" disabled>카테고리 선택</option>
                                <option v-for="item in category" :value="item.id">{{ item.label }}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>제목</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <input v-model="inquiry.title" type="text">
                            </div>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>내용</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <textarea v-model="inquiry.content" style="padding: 5px 5px 5px 5px;" placeholder="내용을 입력해주세요."></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>이메일</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <input v-model="inquiry.email" type="text">
                            </div>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>휴대폰 번호</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <input v-model="inquiry.phone" @input="formatPhoneNumber" type="tel" placeholder="-없이 숫자만 입력 해주세요.">
                            </div>
                            <p class="checkbox-type2">
                              <input @change="agreeCheck" type="checkbox" id="chk-sms-agree2">
                              <label for="chk-sms-agree2">
                                (선택) 정보/이벤트 SMS 수신에 동의합니다.
                              </label>
                            </p>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>첨부파일</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box" style="display: flex;">
                              <div class="file-group">
                                <div class="filebox2">
                                  <label for="upload1">파일업로드</label>
                                  <input @change="loadFile" type="file" id="upload1" accept="image/*"/>
                                </div>
                              </div>
                              <p class="input-txt" style="color:#a1a1a1;">{{ selectedFileName }}</p>
                              <button v-if="selectedFile" @click="deleteFile" type="button">
                                <img src="@/assets/images/icon/ico_close_b.svg" style="width: 18px;padding-left: 5px;" alt=""/>
                              </button>
                            </div>
                            <p class="txt">
                              10MB 이하 파일 업로드 가능
                            </p>
                          </div>
                        </div>
<!--                        <div class="field-row">
                          <div class="label">
                            <span>자동등록 방지</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              캡차 자리
                            </div>
                          </div>
                        </div>-->
                        <div class="button-box">
                          <button @click="saveInquiry" type="button">등록하기</button>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
    </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import {onMounted, ref} from 'vue';
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import {apiClient} from "@/services/auth-header";
import axios from "axios";
import {useRoute} from "vue-router";

// 상태 변수 선언
const route = useRoute();
const loading = ref(true);
const error = ref(null);
const inquiryId = ref(route.params.id);
const API_URL = "/support"
const inquiry = ref({
  categoryId: "",
  title: "",
  content: "",
  email: "",
  phone: null,
  agreeMarketingSms: false,
});
const category = ref(null);
const selectedFile = ref(null);
const selectedFileName = ref("");

const isMobile = ref(false);
const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};
// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

const agreeCheck = (e) => {
  inquiry.value.agreeMarketingSms = e.target.checked;
};

const goList = () => {
  history.back();
};

const selectedCategory = (e) => {
  inquiry.value.categoryId = e.target.value;
};

const formatPhoneNumber = (event) => {
  let cleaned = inquiry.value.phone.replace(/\D/g, '')
  let formatted = ''

  if (cleaned.length > 3) {
    formatted = cleaned.slice(0, 3) + '-'
    if (cleaned.length > 7) {
      formatted += cleaned.slice(3, 7) + '-'
      formatted += cleaned.slice(7, 11)
    } else {
      formatted += cleaned.slice(3, 7)
    }
  } else {
    formatted = cleaned
  }

  inquiry.value.phone = formatted
};

const loadFile = (event) => {
  const file = event.target.files[0];
  if (file) {
    const fileSizeInMB = file.size / (1024 * 1024);
    if(fileSizeInMB > 10){
      alert("10MB 이하의 파일만 업로드 할수 있습니다.");
      return;
    }
    selectedFile.value = file;
    selectedFileName.value = file.name;
  }
};

const deleteFile = () => {
  if (confirm("삭제 하시겠습니까?")) {
    selectedFile.value = null;
    selectedFileName.value = "";
  }
};

const loadData = async () => {
  if(inquiryId.value){
    try {
      const response = await apiClient.post(API_URL + '/inquiry/detail', {
        id: inquiryId.value,
      });
      inquiry.value = response.data;
    } catch (error) {
      console.error("Error fetching artist data:", error);
    }finally {
      loading.value = false;
    }
  }else{
    loading.value = false;
  }
  try {
    const response = await apiClient.post('/common/code', {
      name: "SUPPORT_CATEGORY",
    });
    category.value = response.data;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }finally {
    loading.value = false;
  }
};
const saveInquiry = async () => {
  if(confirm("등록 하시겠습니까?")){
    const formData = new FormData();
    if (selectedFile.value) formData.append('file', selectedFile.value);
    formData.append('data', new Blob([JSON.stringify(inquiry.value)], { type: 'application/json' }));
    try {
      const response = await apiClient.put(API_URL + '/inquiry/save', formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (error) {
      console.error("Error fetching artist data:", error);
    }finally {
      loading.value = false;
      alert("등록 되었습니다.");
      goList();
    }

  }
};

onMounted(() => {
  document.body.classList.remove('bg-white');
  checkMobile();
  loadData();
});
</script>