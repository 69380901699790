<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div class="content-panel">
              <div class="my-artartist">
                <div class="my-info">
                  <h2 class="section-title">비밀번호 변경</h2>
                  <form>
                    <fieldset>
                      <div class="form-box">
                        <div class="field-row">
                          <div class="label">
                            <span>현재 비밀번호</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <div class="pwd-wrap">
                                <input v-model="currentPassword" :type="passwordFieldType">
                                <button @click="togglePasswordVisibility" :class="{ on: passwordFieldType === 'text'}" type="button" class="btn-pwd"></button>
                              </div>
                            </div>
                            <p class="txt">회원 가입시 등록된 비밀번호를 입력하세요 </p>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>신규 비밀번호</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <div class="pwd-wrap">
                                <input v-model="password1" :type="password1FieldType" @input="validateForm" placeholder="8~16자의 영문 대/소문자+숫자+특수문자">
                                <button @click="togglePassword1Visibility" :class="{ on: password1FieldType === 'text'}" type="button" class="btn-pwd"></button>
                              </div>
                            </div>
                            <p v-if="passwordError1" class="txt" style="color: red;">{{ passwordError1 }}</p>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>비밀번호 확인</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <div class="pwd-wrap">
                                <input v-model="password2" :type="password2FieldType" @input="validateForm" placeholder="8~16자의 영문 대/소문자+숫자+특수문자">
                                <button @click="togglePassword2Visibility" :class="{ on: password2FieldType === 'text'}" type="button" class="btn-pwd"></button>
                              </div>
                            </div>
                            <p v-if="passwordError2" class="txt" style="color: red;">{{ passwordError2 }}</p>
                          </div>
                        </div>

                        <div class="button-box">
                          <button @click="updatePassword" type="button">비밀번호 변경</button>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
    </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import {onMounted, ref} from 'vue';
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import {apiClient} from "@/services/auth-header";

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);

// 토글 메뉴 함수
const navIconRef = ref(null);
const sideRef = ref(null);
const toggleMenu = () => {
  navIconRef.value.classList.toggle('open');
  sideRef.value.classList.toggle('active');
  document.body.classList.toggle('on');
};

const currentPassword = ref('');
const password1 = ref('');
const password2 = ref('');
const passwordError1 = ref('');
const passwordError2 = ref('');
const passwordFieldType = ref('password');
const password1FieldType = ref('password');
const password2FieldType = ref('password');
const togglePasswordVisibility = () => {
  passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
};
const togglePassword1Visibility = () => {
  password1FieldType.value = password1FieldType.value === 'password' ? 'text' : 'password';
};
const togglePassword2Visibility = () => {
  password2FieldType.value = password2FieldType.value === 'password' ? 'text' : 'password';
};

onMounted(() => {
  loading.value = false;
});

const validateForm = async () => {
  let isValid = true;
  // Password validation
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?`~]).{8,16}$/;
  if (!password1.value) {
    passwordError1.value = '비밀번호를 입력해 주세요.';
    isValid = false;
  } else if (!passwordRegex.test(password1.value)) {
    passwordError1.value = '8~16자의 영문 대/소문자+숫자+특수문자를 사용해 주세요.';
    isValid = false;
  } else {
    passwordError1.value = '';
  }

  if (!password2.value) {
    passwordError2.value = '비밀번호를 입력해 주세요.';
    isValid = false;
  } else if (!passwordRegex.test(password2.value)) {
    passwordError2.value = '8~16자의 영문 대/소문자+숫자+특수문자를 사용해 주세요.';
    isValid = false;
  } else if (password1.value !== password2.value) {
    passwordError2.value = '비밀번호가 일치하지 않습니다.';
    isValid = false;
  } else {
    passwordError2.value = '';
  }
  return isValid;
}
const updatePassword = async () => {
  const isValid1 = await validateForm();
  if (!isValid1) {
    loading.value = false;
    return;
  }
  if (confirm("수정 하시겠습니까?")) {
    try {
      const response = await apiClient.put('/user/password/update', {
        currentPassword: currentPassword.value,
        password: password1.value,
      });
      if (response.status === 200) {
        alert("수정 되었습니다.")
        window.location.reload();
      }
    } catch (error) {
      error.value = error; // 에러를 저장합니다.
    } finally {
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }
};
</script>