<template>
  <div class="title">
    <span>{{ title }}</span>
  </div>
  <div class="col">
    <div class="row">
      <div class="field">
        <div class="inner-list">
          <template v-if="title === '활동 장르'">
            <div class="li">
              <div class="chk-group">
              <span v-for="genre in genreOptions" :key="genre.id" class="checkbox-type1">
                <input type="checkbox" :id="'chk1-' + genre.id" :value="genre.id" v-model="selectedGenres">
                <label :for="'chk1-' + genre.id">{{ genre.label }}</label>
              </span>
              </div>
              <input type="text" class="etc">
            </div>
          </template>
          <template v-else-if="title.includes('수상') || title.includes('소장') || title.includes('인터뷰')">
            <div class="li" v-for="(profile, index) in profiles" :key="profile.id">
              <input type="text" v-model="profile.title">
              <button v-if="index === 0" type="button" class="add-list" @click="addListItem(profile.type, profile.typeLabel)"><img src="@/assets/images/icon/ico_add2.svg"></button>
              <button v-else type="button" class="add-list" @click="removeItem(profile.type, index)"><img src="@/assets/images/icon/ico_remove.svg"></button>
            </div>
          </template>
          <template v-else>
            <div class="li" v-for="(profile) in profiles" :key="profile.id">
              <input type="text" v-model="profile.title">
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, toRefs, watch} from 'vue'

const props = defineProps({
  title: String,
  profiles: Array,
  years: Array
});
const { title, profiles } = toRefs(props);
const emit = defineEmits(['update-profile']);
const selectedGenres = ref([]);

const addListItem = (type, typeLabel) => {
  const newProfile = {
    type: type,
    typeLabel: typeLabel,
    title: '',
    description: '',
    location: ''
  };
  emit('update-others', newProfile);
};
const removeItem = (type, index) => {
  emit('delete-profile', type, index);
};

onMounted(() => {
  if (props.profiles != null && props.profiles.length > 0 && props.profiles[0].activityGenre != null) {
    selectedGenres.value = props.profiles[0].activityGenre;
  }
});

// selectedGenres가 변경될 때마다 부모 컴포넌트로 업데이트를 전달
watch(selectedGenres, (newVal) => {
  emit('update-profile', newVal);
});
</script>

<style scoped>

</style>