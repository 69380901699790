import {apiClient} from "@/services/auth-header";

const API_URL = '/oauth';

class OAuthService {
    login(accessToken) {
        localStorage.setItem('user', JSON.stringify(accessToken));
        return apiClient
            .get(API_URL + '/login')
            .then(response => {
                localStorage.setItem('user', JSON.stringify(response.data));
                return response.data;
            });
    }
}

export default new OAuthService();
