<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: ARTICLE -->
      <div class="article">
        <div class="layout">
          <div class="box">
            <ArticleSnb/>
            <div class="content-panel">
              <div class="view-type1">
                <div class="img" v-if="news.articleImageUrl">
                  <img :src="news.articleImageUrl" alt="">
                </div>
                <div class="info-box">
                  <h3>[{{ news.category }}] {{ news.title }}</h3>
                  <dl>
                    <dt></dt>
                    <dd>
                      <p class="txt" v-html="newsContents"></p>
                    </dd>
                    <dt></dt>
                    <dd>
                      <a :href="news.link" target="_blank" class="link">{{ news.link }}</a>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: ARTICLE -->
</div>
  <!-- e::PAGE -->
</template>

<script setup>
import {apiClient} from "@/services/auth-header";
import ArticleSnb from "@/components/snb/ArticleSnb.vue";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";

const route = useRoute();
const articleId = ref(route.params.id);
const loading = ref(true);
const error = ref(null);
const news = ref(null);
const newsContents = ref(null);

const API_URL = "/article";

onMounted(async () => {
  try {
    const response = await apiClient.post(API_URL + '/detail', {id: articleId.value});
    news.value = response.data;
    const parts = response.data.content.split('.').filter(part => part.trim() !== '');
    newsContents.value = parts.map(part => `${part.trim()}.`).join('<br><br>');
  } catch (error) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후에는 로딩 상태를 끝냅니다.
  }
});
</script>