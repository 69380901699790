import MyProfile from "@/views/user/Profile.vue";
import ArtWork from "@/views/user/ProfileArtWork.vue";
import Portfolio from "@/views/user/Portfolio.vue";
import Signup from "@/views/Signup.vue";
import MyPage from "@/views/user/MyPage.vue";
import PasswordUpdate from "@/views/user/PasswordUpdate.vue";
import MyOrder from "@/views/user/MyOrder.vue";
import MyOrderDetail from "@/views/user/MyOrderDetail.vue";
import MyWish from "@/views/user/MyWish.vue";
import MyReview from "@/views/user/MyReview.vue";
import MyCoupon from "@/views/user/MyCoupon.vue";
import VerifyResult from "@/components/auth/VerifyResult.vue";
import MyScrap from "@/views/user/MyScrap.vue";

export const userRoutes = [
    {
        path: '/auth/signup',
        component: Signup,
    },
    {
        path: '/my/scrap',
        name: 'Scrap',
        component: MyScrap,
    },
    {
        path: '/auth/verifyResult',
        component: VerifyResult,
    },
    {
        path: '/my/info',
        name: 'MyPage',
        component: MyPage,
    },
    {
        path: '/my/password',
        name: 'PasswordUpdate',
        component: PasswordUpdate,
    },
    {
        path: '/my',
        name: 'ART & ARTISTS',
        children: [
            {
                path: '/my/artist/profile',
                name: 'MyProfile',
                component: MyProfile,
            },
            {
                path: '/my/artist/artwork',
                name: 'ProfileArtwork',
                component: ArtWork,
            },
            {
                path: '/my/artist/portfolio',
                name: 'Portfolio',
                component: Portfolio,
            },
            {
                path: '/my/artist/favorite',
                name: 'FavoriteArtist',
                component: MyProfile,
            },
        ],
    },
    {
        path: '/my/favorite/articler',
        name: 'ARTICLER',
        component: MyProfile,
    },
    {
        path: '/my/scrap/article',
        name: 'ARTICLE',
        component: MyProfile,
    },
    {
        path: '/my/shop',
        name: 'ARTSHOP',
        children: [
            {
                path: '/my/order',
                name: 'OrderInfo',
                component: MyOrder,
            },
            {
                path: '/my/order/detail/:orderId?',
                name: 'OrderDetail',
                component: MyOrderDetail,
            },
            {
                path: '/my/wishlist',
                name: 'MyWish',
                component: MyWish,
            },
            {
                path: '/my/review',
                name: 'MyReview',
                component: MyReview,
            },
            {
                path: '/my/coupon',
                name: 'MyCoupon',
                component: MyCoupon,
            },
        ],
    },
];
