<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: ARTICLE -->
      <div class="article">
        <div class="layout">
          <div class="box">
            <ArticleSnb/>
            <div class="content-panel">
              <div class="list-type3">
                <ul>
                  <li v-for="(item, index) in special" :key="index">
                    <router-link :to="{ name: 'SpecialDetail', params: { id: item.id } }">
                      <div class="img">
                        <img :src="item.articleImageUrl" :alt="item.title">
                      </div>
                      <div class="txt-box">
                        <h3>{{ item.title }}</h3>
                        <p>{{ item.subTitle }}</p>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- e:: ARTICLE -->
  </div>
  <!-- e::PAGE -->
</template>

<script setup>
import ArticleSnb from "@/components/snb/ArticleSnb.vue";
import {useMainStore} from "@/store/pinia";
import {computed, onMounted, ref} from "vue";
import {apiClient} from "@/services/auth-header";

const mainStore = useMainStore();
const loading = ref(true);
const error = ref(null);
const special = ref(null);
const API_URL = "/article";

computed(() => mainStore.currentPath);

onMounted(async () => {
  try {
    const response = await apiClient.post(API_URL + '/list', {
      name: "special"
    });
    special.value = response.data;
  } catch (error) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후에는 로딩 상태를 끝냅니다.
  }
});
</script>