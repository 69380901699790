<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: MYPAGE -->
    <div v-if="paymentInfo" class="mypage">
      <div class="layout">
        <div class="box">
          <div ref="sideRef" class="side-box">
            <div class="snb2">
              <ProfileSnb/>
            </div>
          </div>
          <div class="content-panel">
            <div class="my-order">
              <div class="view">
                <section>
                  <h3>주문 번호 : {{ paymentInfo.tid }}</h3>
                  <div class="item-list">
                    <ul>
                      <li v-for="item in order.detail" :key="item.id">
                        <div class="item-img">
                          <img :src="item.imageUrl" alt=""/>
                        </div>
                        <div class="item-info">
                          <h4>{{ item.name }}</h4>
                          <div class="quantity-wrap">
                            {{ item.optionName }} / {{ item.quantity }}개
                          </div>
                          <p class="p-txt">{{ item.subtotal.toLocaleString() }}원</p>
                        </div>
                      </li>
                    </ul>
                    <div v-if="order.cancelStatus === null" class="btn-box">
                      <button @click="requestOrder('cancel')" type="button" class="open-cancel">취소 신청</button>
<!--                      <button @click="requestOrder('change')" type="button" class="open-exchange">교환 신청</button>-->
                    </div>
                  </div>
                </section>

                <section>
                  <h3>주문자 정보</h3>
                  <div class="form-box">
                    <div class="field-row colspan">
                      <div class="cmm-form">
                        <div class="input-txt">
                          {{ user.name }} / {{ user.phone }}
                        </div>
                      </div>
                    </div>
                    <div class="field-row colspan">
                      <div class="cmm-form">
                        <div class="input-txt">
                          {{ user.email }}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <h3>배송지 정보</h3>
                  <div class="form-box">
                    <div class="field-row colspan">
                      <div class="cmm-form">
                        <div class="input-txt">
                          {{ user.address }}<br>
                          {{ user.name }} / {{ user.phone }}
                        </div>
                      </div>
                    </div>
<!--                      <div class="field-row">
                      <div class="label">
                        <span>배송요청사항</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-txt">
                          작성된 배송요청 사항 작성 내용이 없으면 field-row 를 비노출 해주세요.
                        </div>
                      </div>
                    </div>-->
                  </div>
                </section>

                <section>
                  <h3>결제 정보</h3>
                  <fieldset>
                    <div class="form-box">
                      <div class="field-row">
                        <div class="label">
                          <span>주문일</span>
                        </div>
                        <div class="cmm-form">
                          <div class="input-txt">
                            {{ order.createdAt }}
                          </div>
                        </div>
                      </div>
                      <div v-if="paymentInfo.payMethod.toLowerCase() === 'card'" class="field-row">
                        <div class="label">
                          <span>결제 수단</span>
                        </div>
                        <div class="cmm-form">
                          <div class="input-txt">
                            카드 결제
                          </div>
                        </div>
                      </div>
                      <div v-else class="field-row">
                        <div class="label">
                          <span>결제 수단</span>
                        </div>
                        <div class="cmm-form">
                          <div class="input-txt">
                            무통장 입금 / 계좌정보 (홍깅동)
                          </div>
                        </div>
                      </div>
                      <div v-if="!(totPrice > 50000)" class="field-row">
                        <div class="label">
                          <span>배송비</span>
                        </div>
                        <div class="cmm-form">
                          <div class="input-txt">4,000</div>
                        </div>
                      </div>
                      <div class="field-row">
                        <div class="label">
                          <span>총 상품 금액</span>
                        </div>
                        <div class="cmm-form">
                          <div class="input-txt">
                            {{ totPrice > 50000 ? totPrice.toLocaleString() : (totPrice-4000).toLocaleString() }}원
                          </div>
                        </div>
                      </div>
                      <div class="field-row colspan">
                        <div class="cmm-form">
                          <div class="input-txt txt-right">
                            총 결제 금액 : <strong> {{ totPrice.toLocaleString() }}원</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </section>
<!--
                <section>
                  <h3>세금계산서/현금영수증 발행</h3>
                  <fieldset>
                    <div class="form-box">
                      <div class="field-row colspan">
                        <div class="cmm-form">
                          <div class="input-txt">
                            현금영수증 / 010-1234-1234
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </section>
-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!-- e:: MYPAGE -->
  </div>
  <!-- e::PAGE -->


  <!-- s::취소 모달-->
  <div v-if="modalVisible" class="modal-popup modal-cancel">
    <button @click="closeModal" type="button" class="close-modal">
      <img src="@/assets/images/icon/ico_close_w.svg" alt=""></button>
    <div class="form-box">
      <div class="field-box">
        <ul>
          <li class="colspan">
            <div class="title">
              <span>주문번호</span>
            </div>
            <div class="col">
              <div class="row">
                <div class="field">
                  <div class="input-txt">{{ paymentInfo.tid }}</div>
                </div>
              </div>
            </div>
          </li>
          <li class="colspan">
            <div class="title">
              <span>제목</span>
            </div>
            <div class="col">
              <div class="row">
                <div class="field">
                  <input v-model="cancelTitle" type="text" maxlength="20"/>
                </div>
              </div>
            </div>
          </li>
          <li class="colspan">
            <div class="title">
              <span>{{ cancelDescription }}</span>
            </div>
            <div class="col">
              <div class="row">
                <div class="field">
                  <textarea v-model="cancelReason" maxlength="200"></textarea>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="btn-box">
      <button @click="cancelSubmit" type="button">확인</button>
    </div>
  </div>
  <!-- e::취소 모달-->

  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import {onMounted, ref} from 'vue';
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import {apiClient} from "@/services/auth-header";
import {useRoute} from "vue-router";

const route = useRoute();
// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const orderId = ref(route.params.orderId);
const modalVisible = ref(false);
const user = ref(null);
const order = ref(null);
const paymentInfo = ref(null);
const totPrice = ref(null);
const cancelTitle = ref(null);
const cancelReason = ref(null);
const cancelDescription = ref("");

const API_URL = "/user";
const isMobile = ref(false);

const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};

const loadData = async () => {
  try {
    const response = await apiClient.post(API_URL + '/my/order/detail', orderId.value);
    user.value = response.data.user;
    order.value = response.data.order;
    paymentInfo.value = response.data.order.paymentInfo[0];
    totPrice.value = Number(paymentInfo.value.totPrice);
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }finally {
    loading.value = false;
  }
};

const emit = defineEmits(['mode-change']);
const closeModal = () => {
  modalVisible.value = false;
  emit('mode-change', 'none');
};

const requestOrder = (val) => {
  modalVisible.value = true;
  emit('mode-change', val);
  cancelDescription.value = "취소 사유";
};

const cancelSubmit = async () => {
  const data = {
    orderId: orderId.value,
    cancellationTitle: cancelTitle.value,
    cancellationReason: cancelReason.value,
    refundAmount: totPrice.value,
  }
  if(confirm("주문을 취소 하시겠습니까?")){
    try {
      const response = await apiClient.post('/shop/order/cancel', data);
      if(response.status === 200){
        alert("취소 요청이 완료 되었습니다.")
      }
    } catch (error) {
      console.error("Error fetching artist data:", error);
      alert(error.response.data.message);
    } finally {
      loading.value = false;
    }
  }
};

onMounted(() => {
  loading.value = false;
  document.body.classList.remove('bg-white');
  loadData();
});
</script>