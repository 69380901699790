import { createApp } from 'vue';
import App from './App.vue';
import pinia from './store';
import router from './router';
import '@/assets/css/style.css';
import $ from 'jquery';

window.$ = $;
window.env = process.env;

Kakao.init('b67c338cacd96615faeb889703dd5234');
const app = createApp(App);
app.use(router);
app.use(pinia);
app.mount('#app');
// Kakao 객체를 전역으로 사용할 수 있도록 설정
app.config.globalProperties.$Kakao = Kakao;

if (process.env.NODE_ENV === 'production') {
    app.config.devtools = false; // 개발자 도구 비활성화
    app.config.warnHandler = () => {}; // 경고 메시지 무시
}