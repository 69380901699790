<template>
  <!-- s::PAGE -->
  <div class="contents">
      <!-- s:: ART & ARTIST -->
      <div v-if="artist" class="art-artist">
        <div class="view">
          <div class="layout">
            <div class="box">
              <ArtistSnb/>
              <div class="view-panel">
                <div class="artist-profile">
                  <div class="profile-box">
                    <div class="profile">
                      <figure>
                        <img :src="artist.profileImageUrl" alt="">
                      </figure>
                      <h2>{{ artist.artistNameEn }}</h2>
                      <h3>{{ artist.artistNameKo }}</h3>
                      <div class="button-box">
                        <a href="#">ARTICLER</a>
                        <a href="#">ARTSHOP</a>
                      </div>
                    </div>
                    <div class="career">
                      <div class="col1">
                        <h2>{{ artist.introduction }}
                        </h2>
                        <div class="education">
                          <h3>Education</h3>
                          <ul>
                            <li v-for="item in educations">
                              {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.statusLabel }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col2">
                        <dl>
                          <dt>Exhibitions</dt>
                          <dd>
                            <ul>
                              <li v-for="item in topExhibitions">
                                <template v-if="item.type === 'EXHIBITION'">
                                  {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                                </template>
                              </li>
                            </ul>
                          </dd>
                        </dl>
                        <dl>
                          <dt>Group Exhibitions</dt>
                          <dd>
                            <ul>
                              <li v-for="item in topGroupExhibitions">
                                <template v-if="item.type === 'GROUP_EXHIBITION'">
                                  {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                                </template>
                              </li>
                            </ul>
                          </dd>
                        </dl>
                        <button type="button" class="open-career" @click="openModal">See More</button>
                      </div>
                    </div>
                  </div>
                  <div v-if="article.title" class="banner-box">
                    <p>{{ article.title }}</p>
                    <div class="button-box">
                      <router-link :to="{ name: 'SpecialDetail', params: { id: article.id } }">ARTICLE</router-link>
                    </div>
                  </div>
                  <div v-if="article.articleYoutubeUrl" class="interview-box">
                    <h3>Interview</h3>
                    <div class="video-box">
                      <iframe :src="article.articleYoutubeUrl">
                      </iframe>
                    </div>
                  </div>
                  <div v-if="isModalOpen" class="modal-career">
                    <button type="button" class="close-career" @click="closeModal"><img src="@/assets/images/icon/ico_close_w.svg" alt=""></button>
                    <dl>
                      <dt>Exhibitions</dt>
                      <dd>
                        <ul>
                          <li v-for="item in careers">
                            <template v-if="item.type === 'EXHIBITION'">
                              {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                            </template>
                          </li>
                        </ul>
                      </dd>
                      <dt>Group Exhibitions</dt>
                      <dd>
                        <ul>
                          <li v-for="item in careers">
                            <template v-if="item.type === 'GROUP_EXHIBITION'">
                              {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                            </template>
                          </li>
                        </ul>
                      </dd>
                      <dt>Residency</dt>
                      <dd>
                        <ul>
                          <li v-for="item in careers">
                            <template v-if="item.type === 'RESIDENCY'">
                              {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                            </template>
                          </li>
                        </ul>
                      </dd>
                      <dt>Workshop</dt>
                      <dd>
                        <ul>
                          <li v-for="item in careers">
                            <template v-if="item.type === 'WORKSHOP'">
                              {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                            </template>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: ART & ARTIST -->
      <div v-else>
        <p>Loading...</p>
      </div>
  </div>
  <!-- e::PAGE -->
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {apiClient} from '@/services/auth-header';
import ArtistSnb from "@/components/snb/ArtistSnb.vue";
import {useRoute} from 'vue-router';

const route = useRoute();
const artistId = ref(route.params.id);
const artist = ref(null);
const educations = ref([] );
const careers = ref([] );
const others = ref([] );
const article = ref(null);

const topExhibitions = ref([]);
const topGroupExhibitions = ref([]);

const isModalOpen = ref(false);

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const updateCareers = () => {
  topExhibitions.value = careers.value
      .filter(item => item.type === 'EXHIBITION')
      .slice(0, 5);

  topGroupExhibitions.value = careers.value
      .filter(item => item.type === 'GROUP_EXHIBITION')
      .slice(0, 10);
};

const loadProfile = async () => {
  if (artistId.value) {
    try {
      const [artistResponse, articleResponse] = await Promise.all([
        apiClient.post(`/artist/detail`, { id: Number(artistId.value) }),
        apiClient.post(`/article/artistDetail`, { id: Number(artistId.value) })
      ]);
      artist.value = artistResponse.data;
      article.value = articleResponse.data;
      artistResponse.data.profile.forEach(item => {
        const { type } = item;
        if (type === "UNDERGRADUATE" || type === "GRADUATE" || type === "OTHER") {
          educations.value.push(item);
        } else if (type === "EXHIBITION" || type === "GROUP_EXHIBITION" || type === "RESIDENCY" || type === "WORKSHOP") {
          careers.value.push(item);
        } else {
          others.value.push(item);
        }
      });
      updateCareers()
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
};

onMounted(() => {
  loadProfile();
});

</script>
