import Notice from "@/views/support/Notice.vue";
import Inquiry from "@/views/support/Inquiry.vue";
import NoticeDetail from "@/views/support/NoticeDetail.vue";
import InquiryDetail from "@/views/support/InquiryDetail.vue";

export const supportRoutes = [
    {
        path: '/support/notice',
        name: 'Notice',
        component: Notice,
    },
    {
        path: '/support/notice/detail/:id',
        name: 'NoticeDetail',
        component: NoticeDetail,
        props: true
    },
    {
        path: '/support/inquiry',
        name: 'Inquiry',
        component: Inquiry,
    },
    {
        path: '/support/inquiry/detail/:id?',
        name: 'InquiryDetail',
        component: InquiryDetail,
        props: true
    },

];
