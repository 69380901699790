<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div class="content-panel">

              <div class="customer">
                <div class="bbs">
                  <section>
                    <h2 class="section-title">1:1문의</h2>
                    <div class="bbs-list">
                      <div class="list-top">
                        <p class="total">전체 <em>{{ inquiries.length }}</em>건</p>
                        <div class="search-box">
                          <input v-model="searchWord" @keyup.enter="loadData" type="text">
                          <button @click="loadData" type="button">
                            <img src="@/assets/images/icon/ico_search_b.svg" alt=""/>
                          </button>
                        </div>
                      </div>
                      <ul class="thead">
                        <li>
                          <div class="col col1">No</div>
                          <div class="col col2">제목</div>
                          <div class="col col3">문의날짜</div>
                          <div class="col col4">답변여부</div>
                        </li>
                      </ul>
                      <ul class="tbody">
                        <li v-for="(item, index) in inquiries" :key="item.id">
                          <div class="col col1">{{ index+1 }}</div>
                          <div class="col col2">
                            <router-link :to="{ name: 'InquiryDetail', params: { id: item.id } }">
                              {{ item.title }}
                            </router-link>
                          </div>
                          <div class="col col3">{{ item.createdAt }}</div>
                          <div class="col col4">
                            <span v-if="item.isAnswer" class="status case2">답변완료</span>
                            <span v-else class="status case1">답변대기</span>
                          </div>
                        </li>
                      </ul>
                      <div class="button-box">
                        <router-link :to="{ name: 'InquiryDetail' }">
                          문의 하기
                        </router-link>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
    </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import {onMounted, ref} from 'vue';
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import {apiClient} from "@/services/auth-header";

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const API_URL = "/support"
const searchWord = ref("");
const inquiries = ref([]);

const isMobile = ref(false);
const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};
// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

const loadData = async () => {
  try {
    const response = await apiClient.post(API_URL + '/inquiry', {
      name: searchWord.value,
    });
    inquiries.value = response.data;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }finally {
    loading.value = false;
  }
};

onMounted(() => {
  document.body.classList.remove('bg-white');
  checkMobile();
  loadData();
});
</script>