<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div v-if="!isChecked" class="content-panel">
              <div class="my-artartist">
                <div class="my-info">
                  <h2 class="section-title">나의 정보변경</h2>
                  <form @submit.prevent="handleSubmit">
                    <fieldset>
                      <div class="form-box">
                        <div class="field-row">
                          <div class="label">
                            <span>현재 비밀번호</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <div class="pwd-wrap">
                                <input v-model="password" :type="passwordFieldType" @keyup.enter="handleSubmit">
                                <button @click="togglePasswordVisibility" :class="{ on: passwordFieldType === 'text'}" type="button" class="btn-pwd"></button>
                              </div>
                            </div>
                            <p class="txt">회원님의 정보를 안전하게 보호하기 위해 비밀번호를 다시 한번 확인 합니다.</p>
                          </div>
                        </div>
                        <div class="button-box">
                          <button @click="checkedPassword" type="button">확인</button>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>

            <div v-if="isChecked && user" class="content-panel">
              <div class="my-artartist">
                <div class="my-info">
                  <h2 class="section-title">나의 정보변경</h2>
                  <form>
                    <fieldset>
                      <div class="form-box">
                        <div class="field-row">
                          <div class="label">
                            <span>아이디</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-txt">{{ user.username }}</div>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>이름</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-txt">{{ user.name }}</div>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>성별</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <select v-model="user.gender">
                                <option value="MAN">남성</option>
                                <option value="WOMAN">여성</option>
                                <option value="NONE">선택안함</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>이메일</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <input v-model="user.email" type="text">
                            </div>
                            <p v-if="emailError" class="txt" style="color: red;">{{ emailError }}</p>
                            <p v-if="isArtist" class="checkbox-type2">
                              <input type="checkbox" id="chk-email-agree1" :checked="user.agreeBusinessEmail">
                              <label for="chk-email-agree1">
                                (필수) 협업 제안 등 비즈니스 메일 수신에 동의합니다.
                              </label>
                            </p>
                            <p class="checkbox-type2">
                              <input type="checkbox" id="chk-email-agree2" :checked="user.agreeMarketingEmail">
                              <label for="chk-email-agree2">
                                (선택) 정보/이벤트 메일 수신에 동의합니다.
                              </label>
                            </p>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>휴대폰 번호</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <div class="tel-box">
                                <button type="button" class="btn">본인인증</button>
                                <span class="txt">인증 완료</span>
                              </div>
                            </div>
                            <p v-if="isArtist" class="checkbox-type2">
                              <input type="checkbox" id="chk-sms-agree1" :checked="user.agreeBusinessSms">
                              <label for="chk-sms-agree1">
                                (필수) 협업 제안 등 비즈니스 전화/SMS 수신에 동의합니다.
                              </label>
                            </p>
                            <p class="checkbox-type2">
                              <input type="checkbox" id="chk-sms-agree2" :checked="user.agreeMarketingSms">
                              <label for="chk-sms-agree2">
                                (선택) 정보/이벤트 SMS 수신에 동의합니다.
                              </label>
                            </p>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>주소</span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <button type="button" class="btn" @click="handleSearchAddress">도로명 주소</button>
                              <div v-if="user.zonecode" class="input-txt">({{ user.zonecode }}) {{ user.address }} {{ user.buildingName }}</div>
                              <input v-if="user.zonecode" v-model="user.addressDetail" type="text" placeholder="상세주소 입력">
                            </div>
                            <p v-if="addressError" class="txt" style="color: red;">{{ addressError }}</p>
                          </div>
                        </div>
                        <div class="field-row">
                          <div class="label">
                            <span>
                                관심 분야<br>
                                (중복 선택 가능)
                            </span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <div v-if="isArtist" class="chk-group item3">
                                <span v-for="interest in interestsOptions1" :key="interest.id" class="checkbox-type2">
                                  <input type="checkbox" :id="'chk-ct' + interest.id" :value="interest.value" v-model="user.interests" @change="changeInterests">
                                  <label :for="'chk-ct' + interest.id">{{ interest.label }}</label>
                                  <input v-if="interest.value === 'ETC' && user.interests.includes('ETC')"
                                         v-model="user.interestEtc"
                                         type="text" class="etc" placeholder="직접 입력">
                                </span>
                              </div>
                              <div v-else class="chk-group item3">
                                <span v-for="interest in interestsOptions2" :key="interest.id" class="checkbox-type2">
                                  <input type="checkbox" :id="'chk-ct' + interest.id" :value="interest.value" v-model="user.interests" @change="changeInterests">
                                  <label :for="'chk-ct' + interest.id">{{ interest.label }}</label>
                                  <input v-if="interest.value === 'ETC' && user.interests.includes('ETC')"
                                         v-model="user.interestEtc"
                                         type="text" class="etc" placeholder="직접 입력">
                                </span>
                              </div>
                            </div>
                            <p v-if="interestError" class="txt" style="color: red;">{{ interestError }}</p>
                          </div>
                        </div>
                        <div v-if="isArtist" class="field-row">
                          <div class="label">
                            <span>
                                아티스트<br>활동 자료
                            </span>
                          </div>
                          <div class="cmm-form">
                            <div class="input-box">
                              <div class="file-group">
                                <div class="filebox2">
                                  <label for="certificate">파일업로드</label>
                                  <input type="file" id="certificate" @change="handleFileChange" accept=".png, .jpg, .jpeg, .pdf" multiple>
                                </div>
                              </div>
                              <template v-for="(file, index) in authFiles">
                                <div :class="'file-item-' + index">
                                  <p class="input-txt">{{ file.name }}</p>
                                  <button @click="deleteFile(index)" type="button">삭제</button>
                                </div>
                              </template>
                            </div>
                            <p class="txt">
                              아티스트 활동을 확인할 수 있는 자료를 업로드해 주세요.<br>
                              (관련 학교 재학/졸업증명서, 예술인활동증명, 개인전/그룹전 홍보물 등)
                            </p>
                          </div>
                        </div>
                        <div class="button-box">
                          <button @click="saveProfile" type="button">정보 수정</button>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
  </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import {onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import common from "@/assets/js/common";

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);

// 토글 메뉴 함수
const navIconRef = ref(null);
const sideRef = ref(null);
const toggleMenu = () => {
  navIconRef.value.classList.toggle('open');
  sideRef.value.classList.toggle('active');
  document.body.classList.toggle('on');
};

const password = ref("");
const user = ref(null);
const isArtist = ref(false);
const emit = defineEmits(['mode-change']);
const isChecked = ref(false);
const isSubmitting = ref(false);

onMounted(() => {
  loading.value = false;
});

const passwordFieldType = ref('password');
const togglePasswordVisibility = () => {
  passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
};
const handleSubmit = (event) => {
  event.preventDefault();
  checkedPassword();
};
const checkedPassword = async () => {
  if (isSubmitting.value) return;

  isSubmitting.value = true;
  setTimeout(async () => {
    // 서버 응답이나 추가 처리 후 isSubmitting 상태를 다시 false로 변경
    try {
      const response = await apiClient.post('/user/checkPassword', {
        password: password.value
      });
      isChecked.value = response.data;
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
      if (!response.data) {
        alert("비밀번호가 일치하지 않습니다");
        return;
      }
      await loadProfile();
    } catch (error) {
      error.value = error; // 에러를 저장합니다.
    } finally {
      isSubmitting.value = false;
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }, 500); // 예시로 1초 후에 버튼이 다시 활성화되도록 설정
}

const authFiles = ref([]);
const interestsOptions1 = [
  { id: 1, value: 'PROMOTION', label: '아티스트 및 작품 홍보' },
  { id: 2, value: 'ARCHIVING', label: '아카이빙 및 포트폴리오 기능 이용' },
  { id: 3, value: 'SELLING_ART', label: '직접 제작한 아트상품 판매' },
  { id: 4, value: 'INFORMATION', label: '예술 관련 정보 확인' },
  { id: 5, value: 'COMMUNITY', label: '커뮤니티 활동' },
  { id: 6, value: 'COMMERCIAL_OPPORTUNITIES', label: '상업적 기회(협업 등)' },
  { id: 7, value: 'ETC', label: '기타(직접 입력)' },
];
const interestsOptions2 = [
  { id: 1, value: 'PROMOTION', label: '아티스트 및 작품 정보' },
  { id: 2, value: 'COMMUNITY', label: '커뮤니티 활동' },
  { id: 3, value: 'INFORMATION', label: '예술 관련 정보' },
  { id: 4, value: 'BUYING_ART', label: '아티스트 상품 구매' },
  { id: 5, value: 'ETC', label: '기타(직접 입력)' },
];

const verifyError = ref('');
const password1 = ref('');
const password2 = ref('');
const passwordError1 = ref('');
const passwordError2 = ref('');
const telError = ref('');
const nameError = ref('');
const genderError = ref('');
const emailError = ref('');
const addressError = ref('');
const interestError = ref('');

const validateForm = async () => {

  let isValid = true;

  // if (!isIdVerify.value) {
  //   verifyError.value = '본인인증을 완료해 주세요.';
  //   isValid = false;
  // } else {
  //   verifyError.value = '';
  // }

  // Password validation
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?`~]).{8,16}$/;
  if (!password1.value) {
    passwordError1.value = '비밀번호를 입력해 주세요.';
    isValid = false;
  } else if (!passwordRegex.test(password1.value)) {
    passwordError1.value = '8~16자의 영문 대/소문자+숫자+특수문자를 사용해 주세요.';
    isValid = false;
  } else {
    passwordError1.value = '';
  }

  if (!password2.value) {
    passwordError2.value = '비밀번호를 입력해 주세요.';
    isValid = false;
  } else if (!passwordRegex.test(password2.value)) {
    passwordError2.value = '8~16자의 영문 대/소문자+숫자+특수문자를 사용해 주세요.';
    isValid = false;
  } else if (password1.value !== password2.value) {
    passwordError2.value = '비밀번호가 일치하지 않습니다.';
    isValid = false;
  } else {
    passwordError2.value = '';
  }

  if (!user.value.name) {
    nameError.value = '이름을 입력해 주세요.';
    isValid = false;
  } else {
    nameError.value = '';
  }

  if (!user.value.gender) {
    genderError.value = '성별을 선택해 주세요.';
    isValid = false;
  } else {
    genderError.value = '';
  }

  // Email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!user.value.email) {
    emailError.value = '이메일을 입력해 주세요.';
    isValid = false;
  } else if (!emailRegex.test(user.value.email)) {
    emailError.value = '유효한 이메일 주소를 입력해 주세요.';
    isValid = false;
  } else {
    emailError.value = '';
  }

  if (!user.value.address || !user.value.zonecode || !user.value.addressDetail) {
    addressError.value = '주소를 입력해 주세요.';
    isValid = false;
  } else {
    addressError.value = '';
  }

  if (user.value.interests.length === 0) {
    interestError.value = '관심분야를 1개 이상 선택해 주세요.';
    isValid = false;
  } else {
    interestError.value = '';
  }

  return isValid;
};

// 데이터 로드 및 초기화
const loadProfile = async () => {
  try {
    const response = await apiClient.post('/user/userInfo');
    user.value = response.data;
    isArtist.value = response.data.roles[0].name === "ROLE_ARTIST";
  } catch (error) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
  }
};
const handleSearchAddress = async () => {
  try {
    const data = await common.searchAddress(); // 검색된 주소 데이터를 address에 저장
    user.value.address = data.address;
    user.value.zonecode = data.zonecode;
    user.value.buildingName = data.buildingName;
  } catch (error) {
    console.error('주소 검색 중 에러가 발생했습니다.', error);
  }
};
const saveProfile = async () => {
  if (confirm("수정 하시겠습니까?")) {
    try {
      const response = await apiClient.put('/user/account/update', user.value);
      if(response.status === 200){
        alert("수정 되었습니다.")
        await loadProfile();
      }
    } catch (error) {
      error.value = error; // 에러를 저장합니다.
    } finally {
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }
};

</script>