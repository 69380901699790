<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: ORDER -->
    <div class="order">
      <h2 style="
      display: flex;
      justify-content: center;
      font-size: 40px;
      margin-bottom: 20px;
      color: #ffffff">
        주문이 완료 되었습니다.</h2>
      <div class="layout">
        <div class="l-cont">
          <section>
            <h3>주문 번호 : {{ tid }}</h3>
            <div class="item-list">
              <ul>
                <li v-for="detail in order.detail">
                  <div class="item-img">
                    <img :src="detail.imageUrl" alt="">
                  </div>
                  <div class="item-info">
                    <h4>{{ detail.name }}</h4>
                    <div class="quantity-wrap">
                      {{ detail.optionName }} / {{ detail.quantity }}개
                    </div>
                    <p class="p-txt">{{ detail.subtotal.toLocaleString() }}원</p>
                  </div>
                </li>
              </ul>
            </div>
          </section>

          <section>
            <h3>주문자 정보</h3>
            <div class="form-box">
              <div class="field-row colspan">
                <div class="cmm-form">
                  <div class="input-txt">
                    {{ user.name }} / {{ user.phone }}
                  </div>
                </div>
              </div>
              <div class="field-row colspan">
                <div class="cmm-form">
                  <div class="input-txt">
                    {{ user.email }}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <h3>배송지 정보</h3>
            <div class="form-box">
              <div class="field-row colspan">
                <div class="cmm-form">
                  <div class="input-txt">
                    {{ user.address }}
                  </div>
                </div>
              </div>
<!--
              <div class="field-row">
                <div class="label">
                  <span>배송요청사항</span>
                </div>
                <div class="cmm-form">
                  <div class="input-txt">
                    작성된 배송요청 사항 작성 내용이 없으면 field-row 를 비노출 해주세요.
                  </div>
                </div>
              </div>
-->
            </div>
          </section>
<!--
          <section>
            <h3>적립금 사용</h3>
            <fieldset>
              <div class="form-box">
                <div class="field-row">
                  <div class="label">
                    <span>사용적립금</span>
                  </div>
                  <div class="cmm-form">
                    <div class="input-txt">1,000P</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </section>

          <section>
            <h3>결제방식</h3>
            <fieldset>
              <div class="form-box">
                <div class="field-row colspan">
                  <div class="cmm-form">
                    <div class="input-txt">
                      카드결제
                    </div>
                  </div>
                </div>
                <div class="field-row">
                  <div class="label">
                    <span>입금은행</span>
                  </div>
                  <div class="cmm-form">
                    <div class="input-txt">
                      선택된 입금은행
                    </div>
                  </div>
                </div>
                <div class="field-row">
                  <div class="label">
                    <span>입금자명</span>
                  </div>
                  <div class="cmm-form">
                    <div class="input-txt">
                      홍길동
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </section>

          <section>
            <h3>세금계산서/현금영수증 발행</h3>
            <fieldset>
              <div class="form-box">
                <div class="field-row colspan">
                  <div class="cmm-form">
                    <div class="input-txt">
                      현금영수증 / 010-1234-1234
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </section>
-->
        </div>
        <div class="r-cont">
          <div class="inbox">
            <div class="price-panel">
              <div class="top-box">
                <h3>총 상품 <strong>{{ order.detail.length }}</strong>개</h3>
                <dl class="dl-type1">
                  <dt>상품 금액</dt>
                  <dd v-for="item in order.detail">
                    <strong id="sum_consumer_option_price">
                    {{ item.subtotal.toLocaleString() }}<em>원</em>
                    </strong>
                  </dd>
                  <!-- 할인금액 추가
                  <dt>할인금액</dt>
                  <dd><span>5,000원</span></dd>
                  -->
                  <template v-if="totalAmount < 50000">
                    <dt>배송비</dt>
                    <dd id="final_delivery_price">4,000<em>원</em></dd>
                  </template>
                </dl>
                <div class="total-price">
                  <span>결제 금액</span>
                  <p class="total-payment">{{ totalAmount.toLocaleString() }}<em>원</em></p>
                </div>
              </div>
            </div>
            <div class="button-box">
              <router-link :to="{ name: 'ProductList', params: { tag: 'ALL' } }">ARTSHOP</router-link>
              <router-link :to="{ name: 'OrderInfo', params: { tag: 'ALL' } }">주문 내역 조회</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- e:: ORDER -->
    </div>
  <!-- e::PAGE -->
</template>

<script setup>
import {onMounted, ref} from "vue";
import {apiClient} from "@/services/auth-header";
import {useRoute} from 'vue-router';
import router from "@/router";

const route = useRoute();
const loading = ref(true);
const error = ref(null);
const orderId = route.query.orderId;
const API_URL = "/shop";
const tid = ref(null);
const order = ref(null);
const user = ref(null);
const totalAmount = ref(0);

const loadData = async () => {
  try {
    const response = await apiClient.post(API_URL + '/order?orderId=' + orderId);
    order.value = response.data.order;
    user.value = response.data.user;
    tid.value = response.data.order.paymentInfo[0].tid.substring(20);
    totalAmount.value = response.data.order.paymentInfo[0].totPrice;
  } catch (error) {
    await router.push("/");
    console.error("Error fetching artist data:", error);
  }finally {
    loading.value = false;
  }
};

onMounted(() => {
  document.body.classList.remove('bg-white');
  loadData();
});
</script>