<template>
  <div v-if="isOverlay" class="overlay"></div>
  <div v-if="isLoading" class="spinner-wrap">
    <div class="loading-box">
      <i><img src="@/assets/images/common/logo.svg" alt=""/></i>
      <span class="loader">Loading</span>
    </div>
  </div>
  <header ref="headerRef">
    <div class="layout">
      <router-link to="/" class="logo"></router-link>
      <div class="h-cont" ref="hContRef">
        <div class="gnb">
          <!-- 사용자 메뉴 -->
          <ul v-if="menuList">
            <template v-for="menu in menuList">
              <li>
                <template v-if="menu.name === 'ARTICLER'">
                  <a @click="handleService" href="#" title="COMMUNITY">ARTICLER</a>
                </template>
                <template v-else>
                  <router-link :to="menu.url" :class="{ on: $route.path.includes('artist') }" @click="toggleMenu">
                    {{ menu.name }}
                  </router-link>
                </template>
              </li>
            </template>
          </ul>
        </div>
        <div class="util-box">
          <button type="button" class="btn-search" title="검색"/>
          <div class="util-link">
            <router-link v-if="currentUser" to="/my/scrap" class="btn btn-pick" @click="toggleMenu" title="관심목록"></router-link>
            <router-link v-if="currentUser" to="/shop/cart" class="btn btn-cart" @click="toggleMenu" title="장바구니"></router-link>
            <router-link v-if="currentUser" to="/my/info" class="btn btn-mypage" @click="toggleMenu" title="마이페이지"></router-link>
            <a href="#" v-if="!currentUser" @click="changeMode('login')">Log in</a>
            <router-link v-if="!currentUser" to="/auth/signup" @click="toggleMenu">Sign up</router-link>
            <router-link v-if="currentUser" to="#" @click="logOut">Log out</router-link>
          </div>
        </div>
      </div>
      <button type="button" class="m-search"><img src="@/assets/images/icon/ico_search.svg" alt="Mobile Search"></button>
      <button type="button" class="nav-icon" ref="navIconRef" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  </header>
</template>

<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, onUnmounted, ref, watch} from 'vue';
import {useRouter} from 'vue-router';
import {useMainStore} from "@/store/pinia";
import {useAuthStore} from '@/store/auth.module';

const authStore = useAuthStore();

const props = defineProps({
  showOverlay: Boolean
});

// 상태 관리 및 참조 변수 초기화
const mainStore = useMainStore();
const router = useRouter();
const menuList = ref(null);
const isAdmin = ref(false);
const headerRef = ref(null);
const hContRef = ref(null);
const navIconRef = ref(null);
const isOverlay = ref(false);
const isMobile = ref(false);
const isLoading = ref(false);

watch(() => props.showOverlay, (newVal) => {
  isOverlay.value = newVal;
});

// 토글 메뉴 함수
const handleService = () => {
  alert('서비스 준비 중입니다.');
};

// 토글 메뉴 함수
const toggleMenu = () => {
  if (isMobile.value) {
    navIconRef.value.classList.toggle('open');
    hContRef.value.classList.toggle('active');
    if($(document.body).hasClass("on")){
      document.body.classList.remove('on');
    }else{
      document.body.classList.remove('on');
    }
  }
};

const emit = defineEmits(['mode-change']);
const changeMode = (newMode) => {
  emit('mode-change', newMode);
};

const logOut = () => {
  authStore.logout();
  router.push('/').then(() => {
    window.location.reload();
  });
};

// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

// 경로 업데이트 함수
const updatePath = (path) => {
  mainStore.setCurrentPath(path);
};

// 컴포넌트 마운트 시 동작 설정
onMounted(() => {
  const isAuthenticated = computed(() => authStore.status.loggedIn);
  if (isAuthenticated.value) {
    const user = authStore.status.user;
    if (user && user.roles && user.roles.length > 0) {
      isAdmin.value = user.roles.includes('ROLE_ADMIN');
    }
  }

  nextTick(() => {
    const header = headerRef.value;
    const headerHeight = header.offsetHeight;

    window.addEventListener('scroll', () => {
      const windowTop = window.scrollY;
      if (windowTop >= headerHeight) {
        header.classList.add('drop');
      } else {
        header.classList.remove('drop');
      }
    });
    menuList.value = JSON.parse(localStorage.getItem("menu"));
  });

  checkMobile();

  window.addEventListener('resize', checkMobile);

});

// setup 내부에 상태와 메소드 정의
const currentUser = computed(() => authStore.status.user);

</script>

<style scoped>
.tooltip-container {
  position: relative; /* 툴팁 위치를 기준으로 함 */
}
.tooltip {
  visibility: hidden; /* 기본적으로 툴팁을 숨김 */
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%; /* 툴팁을 텍스트 위에 위치시킴 */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1; /* 마우스 오버 시 툴팁을 표시 */
}
</style>
