<template>
  <aside class="snb">
    <router-link
        :to="{ name: 'Special'}"
        :class="{ on: $route.path.includes('special')}"
        @click="updatePath('special')"
    >SPECIAL</router-link>
    <router-link
        :to="{ name: 'Exhibition'}"
        :class="{ on: $route.path.includes('exhibition')}"
        @click="updatePath('exhibition')"
    >EXHIBITION</router-link>
    <router-link
        :to="{ name: 'Event'}"
        :class="{ on: $route.path.includes('event')}"
        @click="updatePath('event')"
    >EVENT</router-link>
    <router-link
        :to="{ name: 'News'}"
        :class="{ on: $route.path.includes('news')}"
        @click="updatePath('news')"
    >NEWS</router-link>
  </aside>
</template>

<script>
import { useMainStore } from '@/store/pinia'; // Pinia 스토어 경로

export default {
  setup() {
    const mainStore = useMainStore();

    const updatePath = (path) => {
      mainStore.setCurrentPath(path);
    };

    return { updatePath };
  }
};
</script>
