<template>
  <aside class="snb">
    <router-link
        :to="{ name: 'ArtistDetail', params: { id: route.params.id } }"
        :class="{ on: $route.path.includes('profile')}"
    >PROFILE</router-link>
    <router-link
        :to="{ name: 'Artwork', params: { id: route.params.id } }"
        :class="{ on: $route.path.includes('artwork')}"
    >ARTWORK</router-link>
  </aside>
</template>

<script setup>
import {useRoute} from 'vue-router';
const route = useRoute();
</script>
