<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <!-- s::PAGE -->
  <div v-else class="contents">
      <!-- s:: CART -->
      <div class="cart">
        <div class="layout">
          <div class="l-cont">
            <h3>상품 <strong></strong>건</h3>
            <div class="list">
              <div class="list-top">
								<span class="checkbox-type1">
									<input v-model="allSelected" type="checkbox" id="prdAllCheck">
									<label for="prdAllCheck">전체선택</label>
								</span>
                <div>
                  <button @click="removeCart" type="button">선택삭제</button>
                </div>
              </div>
              <ul v-if="cartItems.length > 0">
                <li v-for="(item, index) in cartItems" :key="item.productId" >
                  <span class="checkbox-type1">
                      <input type="checkbox" :id="'chk' + item.productId" :value="item.productId" v-model="selectedItems"/>
                      <label :for="'chk' + item.productId">선택</label>
                  </span>
                  <div class="item-img">
                    <img :src="item.images[0].imageUrl" :alt="item.name"/>
                  </div>
                  <div class="item-info">
                    <h4>{{ item.name }}</h4>
                    <div class="quantity-wrap">
                      <div class="item-quantity">
                        <button type="button" @click="decreaseQuantity(storageItems[index])">
                          <img src="@/assets/images/icon/minus-btn.svg" alt=""/>
                        </button>
                        <input type="number" v-model="storageItems[index].quantity" disabled/>
                        <button type="button" @click="increaseQuantity(storageItems[index])">
                          <img src="@/assets/images/icon/plus-btn.svg" alt=""/>
                        </button>
                      </div>
                    </div>
                    <p class="p-txt">사이즈: {{ storageItems[index].selectedOption.size.sizeName }}</p>
                    <p class="p-txt">가격: {{ (storageItems[index].selectedPrice*storageItems[index].quantity).toLocaleString() }}원</p>
                  </div>
                </li>
              </ul>
              <div v-else class="nodata">
                장바구니에 담긴 상품이 없습니다.
              </div>
            </div>

            <div class="noti-box" style="display: none;">
              <h4>안내사항</h4>
              <ul>
                <li>장바구니에 담긴 상품은 30일 동안 보관됩니다. 30일이 지난 상품은 자동 삭제됩니다.</li>
                <li>배송비 3,000원, 3만원 이상 구매 시 무료 배송됩니다. 도서산간 지역으로 배송을 원하실 경우 배송비가 추가될 수 있습니다.</li>
                <li>평일 14시 이전 결제 완료시 당일 발송을 원칙으로합니다/배송일은 발송일 기준 1~3일정도 소요됩니다</li>
              </ul>
            </div>
          </div>

          <div v-if="storageItems" class="r-cont">
            <div class="inbox">
              <div class="price-panel">
                <div class="top-box">
                  <h3>총 상품 <strong>{{ cartItems.length }}</strong>개</h3>
                  <dl class="dl-type1">
                    <dt>상품금액</dt>
                    <dd><strong id="sum_consumer_option_price">{{ priceQuantity.toLocaleString() }}<em>원</em></strong></dd>
                    <!-- 할인금액 추가
                    <dt>할인금액</dt>
                    <dd><span class="color-pink">5,000원</span></dd>
                    -->
                    <dt>배송비</dt>
                    <dd v-if="priceQuantity < 50000" id="final_delivery_price">3,000<em>원</em></dd>
                    <dd v-else id="final_delivery_price">0<em>원</em></dd>
                  </dl>
                  <div class="total-price">
                    <span>결제 예상금액</span>
                    <p v-if="priceQuantity < 50000" class="total-payment">{{ (priceQuantity+3000).toLocaleString() }}<em>원</em></p>
                    <p v-else class="total-payment">{{ priceQuantity.toLocaleString() }}<em>원</em></p>
                  </div>
                </div>
                <div class="bottom-box">
                  <h4>유의사항</h4>
                  <ul>
                    <li>배송비는 금액에 따라 변경될 수 있습니다.</li>
                    <li>적립금은 다음 주문/결제에서 확인가능합니다.</li>
                  </ul>
                </div>
              </div>
              <div class="button-box">
                <button @click="selectedOrder" type="button" style="color: #000000">선택 상품주문</button>
                <button @click="allOrder" type="button">전체 상품주문</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: CART -->
</div>
  <!-- e::PAGE -->

</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import {apiClient} from "@/services/auth-header";
import {useRouter} from "vue-router";

const router = useRouter();
const loading = ref(true);
const error = ref(null);
const API_URL = "/shop";
const storageItems = ref(JSON.parse(localStorage.getItem("cart")));
const cartItems = ref([]);
const priceQuantity = ref(0);
const selectedItems = ref([]);
const allSelected = ref(false);

watch(allSelected, (newValue) => {
  if (newValue) {
    selectedItems.value = cartItems.value.map(item => item.productId);
  } else {
    selectedItems.value = [];
  }
});

watch(selectedItems, (newValue) => {
  allSelected.value = newValue.length === cartItems.value.length;
});
const loadData = async () => {

  if(storageItems.value){
    try {
      const response = await apiClient.post(API_URL + '/cart/list', {
        items: storageItems.value.map(item => item.id)
      });
      cartItems.value = response.data;
    } catch (error) {
      console.error("Error fetching artist data:", error);
    }finally {
      loading.value = false;
    }
  }else{
    loading.value = false;
  }
};
const decreaseQuantity = (item) => {
  if (item.quantity > 1) {
    item.quantity--;
  }
  calculatePrice();
};

const increaseQuantity = (item) => {
  item.quantity++;
  calculatePrice();
};

const removeCart = () => {
  if(selectedItems.value.length === 0){
    alert("상품을 선택하세요.");
    return;
  }
  if(confirm("선택한 상품을 삭제 하시겠습니까?")){
    const updateItems = storageItems.value.filter(item => !selectedItems.value.includes(item.id))
    localStorage.setItem('cart', JSON.stringify(updateItems))
    location.reload();
  }
};

const selectedOrder = () => {
  if(selectedItems.value.length === 0){
    alert("상품을 선택하세요.");
    return;
  }
  if(confirm("선택한 상품을 주문 하시겠습니까?")){
    const updateItems = storageItems.value.filter(item => selectedItems.value.includes(item.id))
    localStorage.setItem("cart", JSON.stringify(updateItems))
    router.push({ path: '/shop/order', query: { type: 'fromCart' } });

  }
};
const allOrder = () => {
  if(storageItems.value){
    if(confirm("전체 상품을 주문 하시겠습니까?")){
      router.push({ path: '/shop/order', query: { type: 'fromCart' } });
    }
  }else{
    if(selectedItems.value.length === 0){
      alert("장바구니에 상품이 없습니다.");
    }
  }
};
const calculatePrice = () => {
  priceQuantity.value = 0;
  if(storageItems.value){
    storageItems.value.forEach(item => {
      priceQuantity.value += item.quantity * item.selectedPrice;
    });
  }
};

onMounted(() => {
  document.body.classList.remove('bg-white');
  loadData();
  calculatePrice();
});
</script>