<template>
  <!-- s::PAGE -->
  <div v-if="mode === 'step1'" class="contents">
    <div class="signup">
      <div class="step">
        <span>회원 가입</span>
        <span>유형 선택</span>
      </div>
      <div class="step1">
        <h2>회원 유형을 선택해 주세요.</h2>
        <p>ARTICLE21 회원은 아티스트와 아티클러로 구분되며, 선택 유형에 따라 가입 절차가 달라집니다.</p>
        <div class="select-box">
          <div class="box">
            <ul>
              <li>나만의 웹페이지와 포트폴리오 제작 툴이 필요하다면</li>
              <li>사람들과 소통하며 새로운 영감을 얻고 싶다면</li>
              <li>아트상품 개발과 유통, 협업 제안을 원한다면</li>
              <li>전시, 행사, 공모 소식을 빠르고 정확하게 얻고 싶다면</li>
            </ul>
            <a href="#" @click.prevent="setModeAndType('step2', 'artist')">
              ARTIST<br>
              아티스트 회원
            </a>
          </div>
          <div class="box">
            <ul>
              <li>다양한 아티스트의 온라인 갤러리를 즐기고 싶다면</li>
              <li>관심 있는 아티스트와 직접 소통하고 싶다면</li>
              <li>특별한 아트상품을 구매하고 싶다면</li>
              <li>일상을 풍요롭게 하는 예술 정보를 원한다면</li>
            </ul>
            <a href="#" @click.prevent="setModeAndType('step2', 'normal')">
              ARTICLER<br>
              일반 회원
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- e::PAGE -->

  <!-- s::PAGE -->
  <div v-if="mode === 'step2'" class="contents">
    <div class="signup">
      <div class="step">
        <span>회원 가입</span>
        <span>약관 동의</span>
      </div>
      <div class="step2">
        <div class="chk-box">
            <span class="checkbox-type2">
              <input type="checkbox" id="allchk" v-model="allChecked" @change="toggleAll">
              <label for="allchk">
                ARTICLE21의 모든 약관을 확인하고 전체 동의합니다. (선택 항목 포함)
              </label>
            </span>
        </div>
        <div class="box">
            <span class="checkbox-type2">
              <input type="checkbox" id="terms" v-model="formData.terms" @change="updateAllChecked">
              <label for="terms">
                (필수) 서비스 이용약관
              </label>
            </span>
          <Terms/>
        </div>
        <div class="box">
            <span class="checkbox-type2">
              <input type="checkbox" id="privacyRequired" v-model="formData.privacyRequired" @change="updateAllChecked">
              <label for="privacyRequired">
                (필수) 개인정보 처리방침
              </label>
            </span>
          <Privacy/>
        </div>
        <div class="box">
            <span class="checkbox-type2">
              <input type="checkbox" id="privacyOptional" v-model="formData.privacyOptional" @change="updateAllChecked">
              <label for="privacyOptional">
                (선택) 개인정보 수집 및 이용
              </label>
            </span>
          <div class="policy-txt">
            선택적 개인정보의 수집 및 이용 목적<br>
            - 수집/이용 목적 : 주문/결제 시 상품 배송<br>
            - 수집 항목 : 구매자 정보, 상품 구매/취소/반품/교환/환불 정보, 수령인 정보<br>
            - 보유/이용 기간 : 회원 탈퇴 후 5일까지
          </div>
        </div>
        <div class="button-box">
          <a href="#" @click.prevent="setModeAndType('step3', type)">다음 단계</a>
        </div>
      </div>
    </div>
  </div>
  <!-- e::PAGE -->

  <!-- s::PAGE -->
  <div v-if="mode === 'step3'" class="contents">
    <div class="signup">
      <div class="step">
        <span>회원 가입</span>
        <span>본인 인증</span>
      </div>
      <div class="certification">
        <form ref="niceForm" name="form_chk" method="post">
          <input type="hidden" name="m" value="service"/>
          <input v-model="tokenVersionId" type="hidden" name="token_version_id"/>
          <input v-model="encData" type="hidden" name="enc_data"/>
          <input v-model="integrityValue" type="hidden" name="integrity_value"/>
        </form>
        <h2>본인인증을 해주세요.</h2>
        <p>휴대폰 본인인증을 통해<br>아이디(이메일)을 확인합니다.</p>
        <button @click.prevent="openPop" type="button">휴대폰 본인인증</button>
        <p v-if="isArtist" class="checkbox-type2">
          <input type="checkbox" id="chk-sms-agree1" v-model="formData.agreeBusinessSms">
          <label for="chk-sms-agree1">
            (필수) 협업 제안 등 비즈니스 전화/SMS 수신에 동의합니다.
          </label>
        </p>
        <p class="checkbox-type2">
          <input type="checkbox" id="chk-sms-agree2" v-model="formData.agreeMarketingSms">
          <label for="chk-sms-agree2">
            (선택) 정보/이벤트 SMS 수신에 동의합니다.
          </label>
        </p>
      </div>
    </div>
  </div>
  <!-- e::PAGE -->

  <!-- s::PAGE -->
  <div v-if="mode === 'step4'" class="contents">
    <div class="signup">
      <div class="step">
        <span>회원 가입</span>
        <span v-if="isArtist">아티스트 회원</span>
        <span v-else>일반 회원</span>
      </div>
      <div class="step3">
        <form>
          <fieldset>
            <div class="form-box">
              <div class="field-row">
                <div class="label">
                  <span>아이디 *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <input ref="usernameRef" v-model="formData.username" @input="validateUsername" type="text" maxlength="20"
                           class="form-control" placeholder="8~20자의 영문, 숫자를 사용해 주세요.">
                  </div>
                  <p v-if="usernameError" class="txt" style="color: red;">{{ usernameError }}</p>
                  <p class="txt" v-if="isArtist">아이디는 아티스트 개별 페이지의 도메인으로 사용됩니다.</p>
                  <p class="txt" v-if="isArtist">예) https://article21.kr/alphago</p>
                </div>
              </div>
              <div class="field-row">
                <div class="label">
                  <span>비밀번호 *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <div class="pwd-wrap">
                      <input ref="password1Ref" :type="password1FieldType" v-model="password1" maxlength="16"
                             @input="validatePasswordForm"
                             placeholder="8~16자의 영문 대/소문자+숫자+특수문자">
                      <button @click="togglePassword1Visibility" :class="{ on: password1FieldType === 'text'}"
                              type="button" class="btn-pwd"></button>
                    </div>
                  </div>
                  <p v-if="passwordError1" class="txt" style="color: red;">{{ passwordError1 }}</p>
                </div>
              </div>
              <div class="field-row">
                <div class="label">
                  <span>비밀번호 확인 *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <div class="pwd-wrap">
                      <input ref="password2Ref" :type="password2FieldType" v-model="password2" maxlength="16"
                             @input="validatePasswordForm"
                             placeholder="8~16자의 영문 대/소문자+숫자+특수문자">
                      <button @click="togglePassword2Visibility" :class="{ on: password2FieldType === 'text'}"
                              type="button" class="btn-pwd"></button>
                    </div>
                  </div>
                  <p v-if="passwordError2" class="txt" style="color: red;">{{ passwordError2 }}</p>
                  <p class="txt">비밀번호를 한번 더 입력해 주세요.</p>
                </div>
              </div>
<!--
              <div class="field-row">
                <div class="label">
                  <span>휴대폰 번호 *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <div class="tel-box">
                      <form ref="niceForm" name="form_chk" method="post">
                        <input type="hidden" name="m" value="service"/>
                        <input v-model="tokenVersionId" type="hidden" name="token_version_id"/>
                        <input v-model="encData" type="hidden" name="enc_data"/>
                        <input v-model="integrityValue" type="hidden" name="integrity_value"/>
                      </form>
                      <button @click="openPop" type="button" class="btn">본인인증</button>
                    </div>
                  </div>
                  <p v-if="isArtist" :required="isArtist" class="checkbox-type2">
                    <input type="checkbox" id="chk-sms-agree1" v-model="formData.agreeBusinessSms">
                    <label for="chk-sms-agree1">
                      (필수) 협업 제안 등 비즈니스 전화/SMS 수신에 동의합니다.
                    </label>
                  </p>
                  <p class="checkbox-type2">
                    <input type="checkbox" id="chk-sms-agree2" v-model="formData.agreeMarketingSms">
                    <label for="chk-sms-agree2">
                      (선택) 정보/이벤트 SMS 수신에 동의합니다.
                    </label>
                  </p>
                  <p v-if="telError" class="txt" style="color: red;">{{ telError }}</p>
                </div>
              </div>
              <div class="field-row">
                <div class="label">
                  <span>이름 *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <input ref="nameRef" v-model="formData.name" type="text">
                  </div>
                  <p v-if="nameError" class="txt" style="color: red;">{{ nameError }}</p>
                </div>
              </div>
              <div class="field-row">
                <div class="label">
                  <span>성별 *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <select ref="genderRef" v-model="formData.gender">
                      <option value="">선택</option>
                      <option value="MAN">남성</option>
                      <option value="WOMEN">여성</option>
                      <option value="NONE">선택안함</option>
                    </select>
                  </div>
                  <p v-if="genderError" class="txt" style="color: red;">{{ genderError }}</p>
                </div>
              </div>
-->

              <div class="field-row">
                <div class="label">
                  <span>이메일 *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <input ref="emailRef" v-model="email1" type="text" style="width: 100px;">
                    @
                    <input v-if="isDirectInput" v-model="email2" @input="directEmailInput" type="text" style="width: 150px;">
                    <select v-model="email3" style="width: 150px;margin-left: 5px;">
                      <option value="">직접입력</option>
                      <option value="naver.com">naver.com</option>
                      <option value="kakao.com">kakao.com</option>
                      <option value="google.com">google.com</option>
                    </select>
                  </div>
                  <p v-if="emailError" class="txt" style="color: red;">{{ emailError }}</p>
                  <p v-if="isArtist" :required="isArtist" class="checkbox-type2" style="padding-top: 5px;">
                    <input type="checkbox" id="chk-email-agree1" v-model="formData.agreeBusinessEmail">
                    <label for="chk-email-agree1">
                      (필수) 협업 제안 등 비즈니스 메일 수신에 동의합니다.
                    </label>
                  </p>
                  <p class="checkbox-type2" style="padding-top: 5px;">
                    <input type="checkbox" id="chk-email-agree2" v-model="formData.agreeMarketingEmail">
                    <label for="chk-email-agree2">
                      (선택) 정보/이벤트 메일 수신에 동의합니다.
                    </label>
                  </p>
                </div>
              </div>
              <div class="field-row">
                <div class="label">
                  <span>주소 *</span>
                </div>
                <div class="cmm-form">
                  <div class="input-box" style="display: flex;gap: 10px;">
                    <button type="button" class="btn" @click="handleSearchAddress">도로명 주소</button>
                    <div v-if="formData.zonecode" class="input-txt">({{ formData.zonecode }}) {{ formData.address }}
                      {{ formData.buildingName }}
                    </div>
                  </div>
                  <div class="input-box" style="padding-top: 5px;">
                    <input v-if="formData.zonecode" ref="addressRef" v-model="formData.addressDetail" type="text" placeholder="상세주소 입력">
                  </div>
                  <p v-if="addressError" class="txt" style="color: red;">{{ addressError }}</p>
                </div>
              </div>
              <div class="field-row">
                <div class="label">
                    <span>
                      관심 분야 *<br>
                      (중복 선택 가능)
                    </span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <div v-if="isArtist" class="chk-group item3">
                        <span v-for="interest in interestsOptions1" :key="interest.id" class="checkbox-type2">
                          <input type="checkbox" :id="'chk-ct' + interest.id" :value="interest.value"
                                 v-model="formData.interests" @change="changeInterests">
                          <label :for="'chk-ct' + interest.id">{{ interest.label }}</label>
                          <input v-if="interest.value === 'ETC' && formData.interests.includes('ETC')"
                                 v-model="formData.interestEtc"
                                 type="text" class="etc" placeholder="직접 입력">
                        </span>
                    </div>
                    <div v-else class="chk-group item3">
                        <span v-for="interest in interestsOptions2" :key="interest.id" class="checkbox-type2">
                          <input type="checkbox" :id="'chk-ct' + interest.id" :value="interest.value"
                                 v-model="formData.interests" @change="changeInterests">
                          <label :for="'chk-ct' + interest.id">{{ interest.label }}</label>
                          <input v-if="interest.value === 'ETC' && formData.interests.includes('ETC')"
                                 v-model="formData.interestEtc"
                                 type="text" class="etc" placeholder="직접 입력">
                        </span>
                    </div>
                  </div>
                  <p v-if="interestError" class="txt" style="color: red;">{{ interestError }}</p>
                </div>
              </div>
              <div v-if="isArtist" class="field-row">
                <div class="label">
                    <span>
                        아티스트 *<br>활동 자료
                    </span>
                </div>
                <div class="cmm-form">
                  <div class="input-box">
                    <div class="file-group">
                      <div class="filebox2">
                        <label for="certificate">파일업로드</label>
                        <input type="file" id="certificate" @change="handleFileChange" accept=".png, .jpg, .jpeg, .pdf"
                               multiple>
                      </div>
                    </div>
                    <template v-for="(file, index) in authFiles">
                      <div :class="'file-item-' + index" style="display: flex;">
                        <p class="input-txt">{{ file.name }}</p>
                        <button @click="deleteFile(index)" type="button">
                          <img src="@/assets/images/icon/ico_close_b.svg" style="width: 18px;padding-left: 5px;" alt=""/>
                        </button>
                      </div>
                    </template>
                  </div>
                  <p class="txt">
                    아티스트 활동을 확인할 수 있는 자료를 업로드해 주세요.<br>
                    (관련 학교 재학/졸업증명서, 예술인활동증명, 개인전/그룹전 홍보물 등)
                  </p>
                </div>
              </div>
              <div class="button-box">
                <button type="button" v-if="isArtist" @click="saveUser">
                  아티스트 회원 가입
                  <span>활동 자료 검토 후 3일 이내 승인 예정</span>
                </button>
                <button type="button" v-else @click="saveUser">
                  일반 회원 가입
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
  <!-- e::PAGE -->
</template>


<script setup>
import {computed, onMounted, ref, watch} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useRouter} from "vue-router";
import Terms from "@/components/policy/Terms.vue";
import Privacy from "@/components/policy/Privacy.vue";
import common from "@/assets/js/common";

const router = useRouter();
// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const mode = ref("step1");
const type = ref("");
const usernameRef = ref(null);
const password1Ref = ref(null);
const password2Ref = ref(null);
const emailRef = ref(null);
const email1 = ref("");
const email2 = ref("");
const email3 = ref("");
const addressRef = ref(null);

const formData = ref({
  username: '',
  password: '',
  email: '',
  name: '',
  userType: '',
  gender: '',
  birthDate: '',
  phone: '',
  address: '',
  buildingName: '',
  zonecode: '',
  addressDetail: '',
  interests: [],
  interestEtc: '',
  verifyId: '',
  terms: false,
  privacyRequired: false,
  privacyOptional: false,
  agreeBusinessEmail: false,
  agreeMarketingEmail: false,
  agreeBusinessSms: false,
  agreeMarketingSms: false,
});
const authFiles = ref([]);
const interestsOptions1 = [
  {id: 1, value: 'PROMOTION', label: '아티스트 및 작품 홍보'},
  {id: 2, value: 'ARCHIVING', label: '아카이빙 및 포트폴리오 기능 이용'},
  {id: 3, value: 'SELLING_ART', label: '직접 제작한 아트상품 판매'},
  {id: 4, value: 'INFORMATION', label: '예술 관련 정보 확인'},
  {id: 5, value: 'COMMUNITY', label: '커뮤니티 활동'},
  {id: 6, value: 'COMMERCIAL_OPPORTUNITIES', label: '상업적 기회(협업 등)'},
  {id: 7, value: 'ETC', label: '기타(직접 입력)'},
];
const interestsOptions2 = [
  {id: 1, value: 'PROMOTION', label: '아티스트 및 작품 정보'},
  {id: 2, value: 'COMMUNITY', label: '커뮤니티 활동'},
  {id: 3, value: 'INFORMATION', label: '예술 관련 정보'},
  {id: 4, value: 'BUYING_ART', label: '아티스트 상품 구매'},
  {id: 5, value: 'ETC', label: '기타(직접 입력)'},
];

const allChecked = ref(false);
const toggleAll = () => {
  const newValue = allChecked.value;
  formData.value.terms = newValue;
  formData.value.privacyRequired = newValue;
  formData.value.privacyOptional = newValue;
};
const updateAllChecked = () => {
  // 모든 체크박스가 선택되면 allChecked도 true로 설정
  allChecked.value = Object.values({
    terms: formData.value.terms,
    privacyRequired: formData.value.privacyRequired,
    privacyOptional: formData.value.privacyOptional
  }).every(value => value);
};

// watch로 checks 객체의 변화를 감지하여 updateAllChecked 호출
watch({
  terms: formData.value.terms,
  privacyRequired: formData.value.privacyRequired,
  privacyOptional: formData.value.privacyOptional
}, updateAllChecked, {deep: true});

const isDirectInput = computed(() => email3.value === '')
const directEmailInput = () =>  {
  formData.value.email = email1.value + "@" + email2.value;
}
watch(email3, (newVal) => {
  if (newVal !== '') {
    email2.value = newVal;
  } else {
    email2.value = "";
  }
  formData.value.email = email1.value + "@" + email2.value;
});
const setModeAndType = (newMode, newType) => {
  if (newMode !== "step2") {
    if (!formData.value.terms || !formData.value.privacyRequired) {
      alert('필수 항목을 선택해주세요.');
      return;
    }
  }
  mode.value = newMode;
  if (newType !== "") {
    formData.value.userType = newType;
  }
  if(newMode === "step3"){
    authentication();
  }
  window.scrollTo(0, 0);
};

const isArtist = computed(() => formData.value.userType === 'artist');

onMounted(() => {
  loading.value = false;
});

const handleSearchAddress = async () => {
  try {
    const data = await common.searchAddress(); // 검색된 주소 데이터를 address에 저장
    formData.value.address = data.address;
    formData.value.zonecode = data.zonecode;
    formData.value.buildingName = data.buildingName;
  } catch (error) {
    console.error('주소 검색 중 에러가 발생했습니다.', error);
  }
};
const handleFileChange = (event) => {
  const validExtensions = ['png', 'jpg', 'jpeg', 'pdf'];
  const files = Array.from(event.target.files);
  if (files.length > 7) {
    alert('8개 이상 등록할수 없습니다.');
    return;
  }
  // 유효하지 않은 파일이 있는지 검사
  const invalidFiles = files.filter(file => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return !validExtensions.includes(fileExtension);
  });

  if (invalidFiles.length > 0) {
    alert('Only PNG, JPG, JPEG, and PDF files are allowed.');
    event.target.value = ''; // 입력 값 초기화
    authFiles.value = []; // 파일 목록 초기화
  } else {
    // 유효한 파일들만 authFiles에 저장
    // authFiles.value = files;
    files.forEach(file => authFiles.value.push(file));
  }
};

const deleteFile = (index) => {
  if (confirm("삭제 하시겠습니까?")) {
    authFiles.value.splice(index, 1);
  }
};

const password1FieldType = ref('password');
const password2FieldType = ref('password');
const togglePassword1Visibility = () => {
  password1FieldType.value = password1FieldType.value === 'password' ? 'text' : 'password';
};
const togglePassword2Visibility = () => {
  password2FieldType.value = password2FieldType.value === 'password' ? 'text' : 'password';
};

const password1 = ref('');
const password2 = ref('');
const usernameError = ref('');
const passwordError1 = ref('');
const passwordError2 = ref('');
const emailError = ref('');
const addressError = ref('');
const interestError = ref('');

const validateUsername = async () => {
  const usernameRegex = /^(?=.*[a-z])[a-z0-9]{5,20}$/;
  if (!formData.value.username) {
    usernameError.value = '아이디를 입력해 주세요.';
    usernameRef.value.focus();
    return false;
  } else if (!usernameRegex.test(formData.value.username)) {
    usernameError.value = '5~20자의 영문 소문자, 숫자만 사용 가능합니다.';
    usernameRef.value.focus();
    return false;
  } else {
    try {
      const response = await apiClient.post('/user/checkUser', {username: formData.value.username});
      if (response.data) {
        usernameError.value = '이미 사용 중인 아이디입니다.';
        usernameRef.value.focus();
        return false;
      } else {
        usernameError.value = '';
      }
    } catch {
      usernameError.value = '아이디 중복 확인 중 오류가 발생했습니다.';
      usernameRef.value.focus();
      return false;
    }
  }
  return true;
}

const validatePasswordForm = async () => {
  // Password validation
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?`~]).{8,16}$/;
  if (!passwordRegex.test(password1.value)) {
    passwordError1.value = '8~16자의 영문 대/소문자+숫자+특수문자를 사용해 주세요.';
    password1Ref.value.focus();
    return false;
  } else {
    passwordError1.value = '';
  }

  if (!passwordRegex.test(password2.value)) {
    passwordError2.value = '8~16자의 영문 대/소문자+숫자+특수문자를 사용해 주세요.';
    password2Ref.value.focus();
    return false;
  } else if (password1.value !== password2.value) {
    passwordError2.value = '비밀번호가 일치하지 않습니다.';
    password2Ref.value.focus();
    return false;
  } else {
    passwordError2.value = '';
  }
  return true;
}

const validateForm = async () => {
  // if (!formData.value.name) {
  //   nameError.value = '이름을 입력해 주세요.';
  //   nameRef.value.focus();
  //   return false;
  // } else {
  //   nameError.value = '';
  // }

  // if (!formData.value.gender) {
  //   genderError.value = '성별을 선택해 주세요.';
  //   genderRef.value.focus();
  //   return false;
  // } else {
  //   genderError.value = '';
  // }

  // Email validation
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!formData.value.email) {
    emailError.value = '이메일을 입력해 주세요.';
    emailRef.value.focus();
    return false;
  } else if (!emailRegex.test(formData.value.email)) {
    emailError.value = '유효한 이메일 주소를 입력해 주세요.';
    emailRef.value.focus();
    return false;
  } else {
    emailError.value = '';
  }

  if (!formData.value.address || !formData.value.zonecode) {
    addressError.value = '주소를 입력해 주세요.';
    return false;
  } else if (!formData.value.addressDetail) {
    addressError.value = '상세 주소를 입력해 주세요.';
    addressRef.value.focus();
    return false;
  } else {
    addressError.value = '';
  }

  if (formData.value.interests.length === 0) {
    alert("관심분야를 1개 이상 선택해 주세요.");
    interestError.value = '관심분야를 1개 이상 선택해 주세요.';
    return false;
  } else {
    interestError.value = '';
  }

  return true;
};

const niceForm = ref(null);
const tokenVersionId = ref(null);
const encData = ref(null);
const integrityValue = ref(null);
const verify = ref({
  token_version_id: "",
  req_no: "",
  key: "",
  iv: "",
  hmac_key: "",
});

const openPop = async () => {
  if(isArtist.value){
    if(!formData.value.agreeBusinessSms){
      alert('비즈니스 SMS 수신에 동의해주세요.');
      return;
    }
  }
  // 팝업 창 열기
  const popup = window.open('', 'popupChk', 'width=480,height=812,top=100,left=100,menubar=no,status=no,toolbar=no,titlebar=yes,location=no,scrollbars=no');
  if (popup) {
    niceForm.value.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
    niceForm.value.target = "popupChk";
    niceForm.value.submit();
    // 팝업 창 닫힘 감지
    const popupCheckInterval = setInterval(() => {
      if (popup.closed) {
        clearInterval(popupCheckInterval);
        isVerify();
        console.log('팝업 창이 닫혔습니다.');
      }
    }, 500); // 500ms 간격으로 체크
  } else {
    console.error('팝업 창이 차단되었거나 열리지 않았습니다.');
  }
};

const isVerifyComplete = ref(false);
const isVerify = async () => {
  const verify_data = JSON.parse(localStorage.getItem('verify_data'));
  if(verify_data){
    console.log(verify_data)
    formData.value.birthDate = verify_data.birthDate
    formData.value.gender = verify_data.gender
    formData.value.name = verify_data.name
    formData.value.phone = verify_data.phone
    formData.value.verifyId = verify_data.verify_id
    isVerifyComplete.value = true;
    mode.value= 'step4';
    alert("인증이 완료 되었습니다.");
    localStorage.removeItem('verify_data')
  }
};

const authentication = async () => {
  localStorage.setItem('verify', "");
  try {
    const response = await apiClient.post('/auth/identityVerify', {
      returnURL: "https://article21.kr/auth/verifyResult?type=signup"
      // returnURL: "http://localhost:8080/auth/verifyResult?type=signup"
    });
    tokenVersionId.value = response.data.token_version_id;
    encData.value = response.data.enc_data;
    integrityValue.value = response.data.integrity;
    verify.value.token_version_id = response.data.token_version_id;
    verify.value.req_no = response.data.req_no;
    verify.value.key = response.data.key;
    verify.value.iv = response.data.iv;
    verify.value.hmac_key = response.data.hmac_key;
    localStorage.setItem('verify', JSON.stringify(verify.value));
  } catch (error) {
    console.error(error);
  }
}

const saveUser = async () => {
  console.log(formData.value.email)
  loading.value = true;

  const isValid1 = await validateUsername();
  if (!isValid1) {
    loading.value = false;
    return;
  }
  const isValid2 = await validatePasswordForm();
  if (!isValid2) {
    loading.value = false;
    return;
  }
  const isValid3 = await validateForm();
  if (!isValid3) {
    loading.value = false;
    return;
  }
  // File validation for artist
  if (isArtist.value && authFiles.value.length === 0) {
    alert('아티스트 활동을 확인할 수 있는 자료를 업로드해 주세요.');
    loading.value = false;
    return;
  }
  if (isArtist.value && (!formData.value.agreeBusinessSms || !formData.value.agreeBusinessEmail)) {
    alert('비즈니스 SMS 및 이메일 수신에 동의해주세요.');
    loading.value = false;
    return;
  }

  formData.value.password = password1.value;
  const form = new FormData();
  form.append('data', new Blob([JSON.stringify(formData.value)], {type: 'application/json'}));

  if (isArtist.value) {
    form.append('files', authFiles.value);
  }

  try {
    await apiClient.post('/auth/signup', form, {
      headers: {'Content-Type': 'multipart/form-data'}
    });
    alert(isArtist.value ? "아티스트 회원으로 가입 신청되었습니다.\n 3일 이내 승인 메일을 받으실 수 있습니다." : "회원가입이 완료 되었습니다.");
    await router.push('/'); // 회원가입 성공 시에만 이동
  } catch (error) {
    console.error(error);
    alert(error.response.data);
  } finally {
    loading.value = false;
  }
};
</script>