import ProductList from "@/views/shop/ProductList.vue";
import ProductDetail from "@/views/shop/ProductDetail.vue";
import Cart from "@/views/shop/Cart.vue";
import ProductOrder from "@/views/shop/ProductOrder.vue";
import ProductOrderComplete from "@/views/shop/ProductOrderComplete.vue";

export const shopRoutes = [
    {
        path: '/shop',
        name: 'Artshop',
        redirect: '/shop/list?tag=ALL',
        children: [
            {
                path: '/shop/list',
                name: 'ProductList',
                component: ProductList,
                props: true
            },
            {
                path: '/shop/detail/:tag/:id',
                name: 'ProductDetail',
                component: ProductDetail,
                props: true
            },
            {
                path: '/shop/cart',
                name: 'Cart',
                component: Cart,
                props: true
            },
            {
                path: '/shop/order:type?/:productId?/:quantity?/:optionId?',
                name: 'Order',
                component: ProductOrder,
                props: true
            },
            {
                path: '/shop/order/complete:orderId?',
                name: 'OrderComplete',
                component: ProductOrderComplete,
                props: true
            },
        ]
    },
];