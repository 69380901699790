<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: ARTICLE -->
      <div class="article">
        <div class="layout">
          <div class="box">
            <ArticleSnb/>
            <div class="content-panel">
              <div class="view-type3">
                <template v-for="(item, index) in details" :key="item.id">
                  <figure v-if="index === 0">
                    <img :src="item.imageUrl" alt=""/>
                  </figure>
                  <div v-if="index === 0" class="txt-box" v-html="item.content"></div>
                  <div v-else :class="['box2', { reverse: index % 2 === 0 }]">
                    <div class="img">
                      <img :src="item.imageUrl" :alt="item.title"/>
                    </div>
                    <div class="txt">
                      <h3>{{ item.title }}</h3>
                      <q v-html="item.content"></q>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- e:: ARTICLE -->
    </div>
  <!-- e::PAGE -->
</template>

<script setup>
import ArticleSnb from "@/components/snb/ArticleSnb.vue";
import {onMounted, ref} from "vue";
import {apiClient} from "@/services/auth-header";
import {useRoute} from "vue-router";

const route = useRoute();
const articleId = ref(route.params.id);
const loading = ref(true);
const error = ref(null);
const special = ref(null);
const details = ref(null);
const API_URL = "/article";

onMounted(async () => {
  try {
    const response = await apiClient.post(API_URL + '/list', {
      name: "special"
    });
    const articleDetails = response.data.filter(({ id }) => id === Number(articleId.value) )[0].articleDetails;
    if(articleDetails.length > 0){
      details.value = articleDetails;
    }
  } catch (error) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후에는 로딩 상태를 끝냅니다.
  }
});
</script>