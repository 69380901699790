<template>
  <aside class="snb">
    <div class="swiper tab-swiper">
      <div class="swiper-wrapper">
        <div v-for="item in category" :class="{ active: tag === item.description}" class="swiper-slide">
          <router-link :to="{ name: 'ProductList', query: { tag: item.description } }">{{ item.name }}</router-link>
        </div>
      </div>
    </div>
    <div class="search-box">
      <input type="text" v-model="searchQuery" placeholder="검색" @keyup.enter="search">
      <button type="button"><img src="@/assets/images/icon/ico_search_b.svg" alt=""></button>
    </div>
  </aside>
</template>
<script setup>
import {onMounted, ref} from "vue";
import Swiper from "swiper";
import {useRoute} from "vue-router";

const route = useRoute();
const tag = ref(route.query.tag);
const searchQuery = ref('');
const category = ref(null);
const emit = defineEmits(['search-list']);
const search = () => {
  emit('search-list', searchQuery.value);
}
const searchTag = (tag) => {
  emit('search-tag', tag);
}

const mainSwiper = ref();
const setSwiper = () => {
  mainSwiper.value = new Swiper('.tab-swiper', {
    slidesPerView: 'auto',
    spaceBetween: 15,
    loop: false,
    grabCursor: true, // 드래그할 때 손 모양의 커서 표시
  }).init();
};

onMounted(async () => {
  const shop = JSON.parse(localStorage.getItem("menu")).filter(item => item.name === 'ARTSHOP');
  category.value = shop[0].children.sort((a, b) => a.sort - b.sort);
  setSwiper();
});
</script>