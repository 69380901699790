<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div class="content-panel">
              <div class="customer">
                <div class="bbs">
                  <section v-if="notice">
                    <h2 class="section-title">공지사항</h2>
                    <div class="bbs-view">
                      <h3>{{ notice.title }}</h3>
                      <div class="util">
                        <span>{{ notice.createdBy }}</span>
                        <span>{{ notice.createdAt }}</span>
                      </div>
                      <div class="view-content">
                        {{ notice.content }}
                      </div>
                      <div class="button-box">
                        <a @click="goList" href="#">목록으로</a>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
    </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import {onMounted, ref} from 'vue';
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import {apiClient} from "@/services/auth-header";
import {useRoute} from "vue-router";

const route = useRoute();
// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const noticeId = ref(route.params.id);
const API_URL = "/support"
const notice = ref(null);

const isMobile = ref(false);
const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};
// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

const goList = () => {
  history.back();
};

const loadData = async () => {
  try {
    const response = await apiClient.post(API_URL + '/notice/detail', {
      id: noticeId.value,
    });
    notice.value = response.data;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }finally {
    loading.value = false;
  }
};

onMounted(() => {
  document.body.classList.remove('bg-white');
  checkMobile();
  loadData();
});
</script>