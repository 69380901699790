<template>
  <h3>간편 로그인</h3>
  <div class="sns-wrap">
    <button type="button" @click="redirectToOAuth('kakao')"><img src="@/assets/images/icon/ico_kakao.svg" alt=""></button>
    <button type="button" @click="redirectToOAuth('naver')"><img src="@/assets/images/icon/ico_naver.svg" alt=""></button>
    <button type="button" @click="redirectToOAuth('google')"><img src="@/assets/images/icon/ico_google.svg" alt=""></button>
  </div>
</template>

<script setup>
const VUE_APP_API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const VUE_APP_REDIRECT_URI = process.env.VUE_APP_REDIRECT_URI;

const redirectToOAuth = (provider) => {
  window.location.href = `${VUE_APP_API_BASE_URL}/oauth2/authorization/${provider}?redirect_uri=${encodeURIComponent(VUE_APP_REDIRECT_URI)}&mode=login`;
}
</script>