<template>
  <!-- s::PAGE -->
  <div class="contents">
      <!-- s:: ART & ARTIST -->
      <div class="art-artist">
        <div class="list">
          <div class="layout">
            <div class="visual-slide">
              <div class="swiper visualSwiper">
                <div class="swiper-wrapper">
<!--                  <div class="swiper-slide" v-for="(artwork, index) in artworks" :key="index" :data-id="artwork.id">-->
                  <div v-for="item in banners" class="swiper-slide">
                    <picture>
                      <!-- 모바일용 이미지 추후에 교체 해야 됩니다. -->
                      <source media="(max-width:768px)" :srcset="item">
                      <img :src="item" alt=""/>
                    </picture>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
                <h1>ART & ARTISTS</h1>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
              </div>
            </div>
            <div class="filter-box">
              <div class="search-box">
                <input type="text" v-model="searchQuery" placeholder="작가명 또는 작품명 검색" @keyup.enter="init">
                <button type="button" id="search-button" @click="init">
                  <img src="@/assets/images/icon/ico_search_b.svg" alt="">
                </button>
              </div>
<!--

              <div class="sort-box">
                <a href="#" class="on">ALL</a>
                <template v-for="char in 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')">
                  <a href="#">{{ char }}</a>
                </template>
              </div>
-->
            </div>

            <ul v-if="artists && artists.length > 0">
              <li v-for="data in artists" :key="data.artistId">
                <router-link :to="{ name: 'ArtistDetail', params: { id: data.artistId } }">
                  <div class="txt">
                    <h3>{{ data.artistNameEn }}</h3>
                    <p>{{ data.artistNameKo }}</p>
                  </div>
                  <div class="img-box"
                       :style="{ background: `url(${require('@/assets/images/main/artists_bg.png')}) top center no-repeat`, backgroundSize: 'cover' }">
                    <figure>
                      <img :src="data.mainImageUrl" alt=""/>
<!--                      <img src="https://integer0916.cafe24.com/article21/images/main/artists1.png" alt=""/>-->
                    </figure>
                  </div>
                </router-link>
                <button type="button" class="btn-like" :class="{ on: data.userId }" @click="toggleLike($event, data.artistId)">좋아요</button>
              </li>
            </ul>
            <ul v-else>
              <!-- 검색된 데이터가 없을 경우 -->
              <li class="nodata">
                검색된 작가 또는 작품명이 없습니다.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- e:: ART & ARTIST -->
    </div>
  <!-- e::PAGE -->
</template>
<script setup>
import { ref, onMounted } from 'vue';
import {apiClient} from "@/services/auth-header";
import Swiper from "swiper";
import {useAuthStore} from "@/store/auth.module";

const authStore = useAuthStore();
const artists = ref(null);
const banners = ref([]);
const searchQuery = ref('');
const mainSwiper = ref();
const API_URL = "/artist";

const toggleLike = async (event, artistId) => {
  if(!authStore.status.loggedIn){
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  const isLiked = $(event.target).hasClass("on");
  const msg = !isLiked ? "관심 아티스트 등록" : "관심 아티스트 삭제";
  if(confirm(msg + " 하시겠습니까?")){
    try {
      const response = await apiClient.post(API_URL + '/wishlist/update', {
        id: artistId,
      });
      console.log(response)
    } catch (error) {
      console.error("Error fetching artist data:", error);
    }finally {
      await searchArtist();
    }
  }
}

const searchArtist = async () => {
  try {
    const response = await apiClient.post(API_URL + '/list', {
      name: searchQuery.value
    });
    artists.value = response.data.artists;
    banners.value = response.data.banners;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }
};

const setSwiper = () => {
  mainSwiper.value = new Swiper('.visualSwiper', {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: false,
    grabCursor: true, // 드래그할 때 손 모양의 커서 표시
    pagination: {
      el : ".swiper-pagination"
    },
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
  }).init();
};

const init = () => {
  setSwiper();
  searchArtist();
};

onMounted(() => {
  init();
});
</script>