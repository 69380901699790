<template>
  verify
</template>
<script setup>
import {onMounted, ref} from "vue";
import {apiClient} from "@/services/auth-header";
import {useRoute} from 'vue-router';

const route = useRoute();
const type = route.query.type;
const enc_data = route.query.enc_data;
const token_version_id = route.query.token_version_id;
const integrity_value = route.query.integrity_value;

const authentication = async () => {
  const verify = JSON.parse(localStorage.getItem('verify'));
  try {
    const response = await apiClient.post('/auth/identityVerify/result',{
      type: type,
      reqNo: verify.req_no,
      key: verify.key,
      iv: verify.iv,
      hmacKey: verify.hmac_key,
      reqTokenVersionId: verify.token_version_id,
      tokenVersionId: token_version_id,
      integrityValue: integrity_value,
      encData: enc_data,
    });
    const data = {
      'verify_id': response.data.id,
      'name': response.data.name,
      'birthDate': response.data.birthDate,
      'gender': response.data.gender,
      'phone': response.data.phone,
    }
    localStorage.setItem('verify_data', JSON.stringify(data));
    window.close();
  } catch (error) {
    console.error(error);
    window.close();
  }
}

const findAuthentication = async () => {
  const username = localStorage.getItem('username');
  const verify = JSON.parse(localStorage.getItem('verify'));
  try {
    const response = await apiClient.post('/auth/find/identityVerify/result', {
      type: type,
      username: username,
      reqNo: verify.req_no,
      key: verify.key,
      iv: verify.iv,
      hmacKey: verify.hmac_key,
      reqTokenVersionId: verify.token_version_id,
      tokenVersionId: token_version_id,
      integrityValue: integrity_value,
      encData: enc_data,
    });
    if(type === "id"){
      localStorage.setItem('username', response.data.username);
    }else{
      localStorage.setItem('jwtToken', response.data.jwtToken);
    }
    localStorage.removeItem('verify');
    window.close();
  } catch (error) {
    console.error(error);
    window.close();
  }
}


onMounted(() => {
  if(type === "signup"){
    authentication();
  }else{
    findAuthentication();
  }
});
</script>