<template>
  <div class="not-found">
    <h1>404 - 페이지를 찾을 수 없습니다</h1>
    <p>요청하신 페이지가 존재하지 않습니다.</p>
    <router-link to="/">홈으로 돌아가기</router-link>
  </div>
</template>

<script setup>
</script>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 50px;
}
.not-found h1 {
  font-size: 1.5rem;
  color: #ff6b6b;
}
.not-found p {
  font-size: 1rem;
  color: #ffffff;
}
.not-found a {
  font-size: 0.5rem;
  color: #ffffff;
}
</style>
