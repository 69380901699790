// searchAddress 함수를 Promise로 변경
class commonJs {
    searchAddress() {
        return new Promise((resolve, reject) => {
            new daum.Postcode({
                oncomplete: function(data) {
                    resolve(data); // 주소 데이터를 resolve로 전달
                },
                onerror: function(err) {
                    reject(err); // 에러가 발생한 경우 reject로 에러 전달
                }
            }).open();
        });
    }
}

export default new commonJs();