import Special from '@/views/article/Special.vue';
import Exhibition from '@/views/article/Exhibition.vue';
import ExhibitionDetail from '@/views/article/ExhibitionDetail.vue';
import Event from '@/views/article/Event.vue';
import News from '@/views/article/News.vue';
import EventDetail from "@/views/article/EventDetail.vue";
import NewsDetail from "@/views/article/NewsDetail.vue";
import SpecialDetail from "@/views/article/SpecialDetail.vue";

export const articleRoutes = [

    {
        path: '/article',
        name: 'Article',
        redirect: '/article/special',
        children: [
            {
                path: '/article/special',
                name: 'Special',
                component: Special,
                props: true
            },
            {
                path: '/article/special/detail/:id',
                name: 'SpecialDetail',
                component: SpecialDetail,
                props: true
            },
            {
                path: '/article/exhibition',
                name: 'Exhibition',
                component: Exhibition,
                props: true
            },
            {
                path: '/article/exhibition/detail/:id',
                name: 'ExhibitionDetail',
                component: ExhibitionDetail,
                props: true
            },
            {
                path: '/article/event',
                name: 'Event',
                component: Event,
                props: true
            },
            {
                path: '/article/event/detail/:id',
                name: 'EventDetail',
                component: EventDetail,
                props: true
            },
            {
                path: '/article/news',
                name: 'News',
                component: News,
                props: true
            },
            {
                path: '/article/news/detail/:id',
                name: 'NewsDetail',
                component: NewsDetail,
                props: true
            },
        ]
    },

];