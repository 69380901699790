// src/store/auth.module.js
import { defineStore } from 'pinia';
import AuthService from '@/services/auth.service';
import OAuthService from '@/services/oauth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : { loggedIn: false, user: null };

export const useAuthStore = defineStore('auth', {
    state: () => ({
        status: initialState,
    }),
    actions: {
        async login(user) {
            try {
                const loggedInUser = await AuthService.login(user);
                this.loginSuccess(loggedInUser);
                return Promise.resolve(loggedInUser);
            } catch (error) {
                this.loginFailure();
                return Promise.reject(error);
            }
        },
        async snsLogin(accessToken) {
            try {
                const loggedInUser = await OAuthService.login(accessToken);
                this.loginSuccess(loggedInUser);
                return Promise.resolve(loggedInUser);
            } catch (error) {
                this.loginFailure();
                return Promise.reject(error);
            }
        },
        logout() {
            AuthService.logout();
            this.logoutSuccess();
        },
        loginSuccess(user) {
            this.status.loggedIn = true;
            this.status.user = user;
        },
        loginFailure() {
            this.status.loggedIn = false;
            this.status.user = null;
        },
        logoutSuccess() {
            this.status.loggedIn = false;
            this.status.user = null;
        },
    },
    getters: {
        isAuthenticated: (state) => state.status.loggedIn,
    },
});
