<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="loadingError" class="error">{{ loadingError.message }}</div>

  <div v-else class="contents">
      <!-- s:: ARTSHOP -->
      <div class="artshop">
        <div class="layout">
          <div class="box">
            <ShopSnb/>
            <div class="content-panel">
              <div class="goods">
                <div class="view">
                  <div class="item-top">
                    <div class="item-img">
                      <div class="img-wrap">
                        <div class="swiper visualSwiper">
                          <div class="swiper-wrapper">
                            <div v-for="image in fetchedData.images.filter(image => image.imageType === 'PRODUCT')" class="swiper-slide">
                              <img :src="image.imageUrl" alt=""/>
                            </div>
                          </div>
                          <div class="swiper-pagination"></div>
                          <div class="swiper-button-next"></div>
                          <div class="swiper-button-prev"></div>
                        </div>
                      </div>
                    </div>
                    <div class="item-info">
                      <div class="item-name">
                        <h2>{{ fetchedData.name }}</h2>
                        <p class="price">{{ selectedPrice.toLocaleString() }}원</p>
                        <div class="btn-group" style="top: 100px;">
                          <button @mouseover="shareContent" type="button" class="btn-share"></button>
                          <button @click="toggleFavorite" type="button"
                                  :class="{ 'on': isFavorite, 'btn-zzim': true }"></button>
                        </div>
                        <div v-if="shareVisible" @mouseleave="closeShare" class="share-box" style="background: #364065;top: 100px;left: 85px">
                          <div class="ico-list">
                            <button @click="copyToClipboard" type="button" class="btn-copy"><img src="@/assets/images/icon/ico_clip.svg" alt=""></button>
                            <button @click="shareToKakao" type="button" class="btn-kakao"><img src="@/assets/images/icon/ico_kakao2.svg" alt=""></button>
                            <button @click="shareToInstagram" type="button" class="btn-insta"><img src="@/assets/images/icon/ico_insta.svg" alt=""></button>
                            <button @click="shareToFacebook" type="button" class="btn-facebook"><img src="@/assets/images/icon/ico_facebook.svg" alt=""></button>
                          </div>
                        </div>
                      </div>
                      <p class="txt">{{ fetchedData.description }}</p>
                      <dl class="dl-type1">
                        <dt>배송 방법</dt>
                        <dd>택배</dd>
                        <dt>배송비</dt>
                        <dd>무료(60,000원 이상 무료배송)<span>도서 산간 배송비 추가</span></dd>
                      </dl>
                      <div class="option-box">
                        <select v-model="selectedOption" @change="handleSelect">
                          <option value="">옵션 선택</option>
                          <option v-for="option in fetchedData.options" :key="option.id" :value="option">
                            {{ option.quantity === 0 ? option.size.sizeName+"(품절)" : option.size.sizeName }}
                          </option>
                        </select>
                      </div>
                      <div class="quantity-box">
                        <h3>수량</h3>
                        <div class="quantity-wrap">
                          <div class="item-quantity">
                            <button type="button" @click="decreaseQuantity" :disabled="isDisabled">
                              <img src="@/assets/images/icon/minus-btn.svg" alt=""/>
                            </button>
                            <input type="number" v-model="quantity" :disabled="isDisabled"/>
                            <button type="button" @click="increaseQuantity" :disabled="isDisabled">
                              <img src="@/assets/images/icon/plus-btn.svg" alt=""/>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="total-box">
                        <span>총 상품 금액</span>
                        <p>{{ priceQuantity.toLocaleString() }}원</p>
                      </div>
                      <div class="button-box">
                        <button @click="addCart" type="button">장바구니</button>
                        <button @click="buyProduct" type="button">구매하기</button>
                      </div>
                    </div>
                  </div>
                  <div class="item-detail">
                    <div class="item-tab">
                      <button
                          type="button"
                          :class="{ on: activeTab === 'item-cont1' }"
                          @click="handleTabClick('item-cont1')"
                          rel="item-cont1">상세 정보
                      </button>
                      <button
                          type="button"
                          :class="{ on: activeTab === 'item-cont2' }"
                          @click="handleTabClick('item-cont2')"
                          rel="item-cont2">구매 후기({{ reviews.length }})
                      </button>
                      <button
                          type="button"
                          :class="{ on: activeTab === 'item-cont3' }"
                          @click="handleTabClick('item-cont3')"
                          rel="item-cont3">Q&A({{ qna.length }})
                      </button>
                    </div>
                    <div v-show="activeTab === 'item-cont1'" id="item-cont1" class="item-cont">
                      <div :class="{on: isDetail, 'img-box': true}">
                        <template v-for="image in fetchedData.images.filter(image => image.imageType === 'PRODUCT_DETAIL')">
                          <img :src="image.imageUrl" alt=""/>
                        </template>
                      </div>
                      <div class="more">
                        <button @click="detailLoadMore" type="button" class="more-img">펼쳐보기</button>
                      </div>
                    </div>
                    <div v-show="activeTab === 'item-cont2'" id="item-cont2" class="item-cont">
                      <div class="review-box">
                        <h4>REVIEW ({{ reviews.length }})</h4>
                        <p class="txt1">상품을 구매하신 분들이 작성한 리뷰입니다.</p>
                        <div class="write-review">
                          <button @click="writeReview" type="button" class="open-review">리뷰 작성</button>
                        </div>
                        <template v-if="reviews.length > 0">
                          <ul>
                            <li v-for="item in visibleReviewItems" :key="item.id">
                              <div class="col1">{{ item.userId }}<span>{{ item.createdAt }}</span></div>
                              <div class="col2">
                                <div class="item-wrap">
                                  <div class="item-name">
                                    <p>{{ fetchedData.name }}</p>
                                  </div>
                                  <div class="txt">{{ item.content }}</div>
                                </div>
                                <div class="item-img"><img :src="item.reviewImageUrl" alt=""/></div>
                              </div>
                            </li>
                          </ul>
                          <div class="more">
                            <button @click="reviewLoadMore" type="button">더보기</button>
                          </div>
                        </template>
                        <template v-else>
                          <div class="nodata">등록된 리뷰가 없습니다.</div>
                        </template>
                      </div>
                    </div>
                    <div v-show="activeTab === 'item-cont3'" id="item-cont3" class="item-cont">
                      <div class="qna-box">
                        <h4>Q&A({{ qna.length }})</h4>
                        <p class="txt1">상품이 궁금하신 분들이 작성한 리뷰입니다.</p>
                        <div class="write-review">
                          <button @click="writeQna" type="button">상품 문의</button>
                        </div>
                        <template v-if="qna.length > 0">
                          <dl class="toggle-box">
                            <template v-for="(item, index) in visibleQnaItems" :key="item.id">
                              <dt @click="toggle(index, item.id)" :class="{ on: item.isOpen }" style="margin-bottom: 10px;">
                                <div class="col1">
                                  <span class="name">{{ item.userId }}</span>
                                  <span>{{ item.createdAt }}</span>
                                </div>
                                <h4>{{ item.title }}</h4>
                                <span v-if="!item.children.length > 0" class="status case1">답변 대기</span>
                                <span v-else class="status case2">답변 완료</span>
                              </dt>
                              <dd v-if="item.isOpen">
                                {{ item.content }}
                                <div v-for="children in item.children" class="reply-panel">
                                  <div class="reply-top">
                                    <h5>{{ children.title }}</h5>
                                    <em>{{ children.createdBy }}</em>
                                    <span>{{ children.createdAt }}</span>
                                  </div>
                                  <div class="reply-txt">{{ children.content }}</div>
                                </div>
                              </dd>
                            </template>
                          </dl>
                          <div class="more">
                            <button @click="qnaLoadMore" type="button">더보기</button>
                          </div>
                        </template>
                        <template v-else>
                          <div class="nodata">등록된 Q&A가 없습니다.</div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- e:: ARTSHOP -->
    </div>
  <!-- e::PAGE -->

  <!-- s::리뷰 모달-->
  <div v-if="reviewVisible" class="modal-popup modal-review">
    <button @click="closeWriteReview" type="button" class="close-modal">
      <img src="@/assets/images/icon/ico_close_w.svg" alt=""/>
    </button>
    <div class="form-box">
      <div class="field-box">
        <ul>
          <li class="colspan">
            <div class="title">
              <span>상품명</span>
            </div>
            <div class="col">
              <div class="row">
                <div class="field">
                  <div class="input-txt">{{ fetchedData.name }}</div>
                </div>
              </div>
            </div>
          </li>
          <li class="colspan">
            <div class="title">
              <span>내용</span>
            </div>
            <div class="col">
              <div class="row">
                <div class="field">
                  <textarea v-model="reviewContent"></textarea>
                </div>
              </div>
            </div>
          </li>
          <li class="colspan">
            <div class="title">
              <span>이미지 등록</span>
            </div>
            <div class="col">
              <div class="row">
                <div class="field">
                  <div class="filebox">
                    <label for="upload-img">이미지 업로드</label>
                    <input type="file" id="upload-img" accept="image/*" @change="loadFile">
                    <img v-if="uploadImage" :src="uploadImage" ref="output" id="output"/>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="btn-box">
      <button @click="addReview" type="button">등록</button>
    </div>
  </div>
  <!-- s::리뷰 모달-->

  <!-- s::Q&A 모달-->
  <div v-if="qnaVisible" class="modal-popup modal-qna">
    <button @click="closeWriteQna" type="button" class="close-modal">
      <img src="@/assets/images/icon/ico_close_w.svg" alt=""/>
    </button>
    <div class="form-box">
      <div class="field-box">
        <ul>
          <li class="colspan">
            <div class="title">
              <span>제목</span>
            </div>
            <div class="col">
              <div class="row">
                <div class="field">
                  <input v-model="title" type="text">
                </div>
              </div>
            </div>
          </li>
          <li class="colspan">
            <div class="title">
              <span>문의내용</span>
            </div>
            <div class="col">
              <div class="row">
                <div class="field">
                  <textarea v-model="content"></textarea>
                </div>
              </div>
            </div>
          </li>
          <!-- 비회원일떄?
          <li class="colspan">
              <div class="title">
                  <span>작성자</span>
              </div>
              <div class="col">
                  <div class="row">
                      <div class="field">
                          <input type="text">
                      </div>
                  </div>
              </div>
          </li>
          -->
          <li class="colspan">
              <div class="title">
                  <span>비밀번호</span>
              </div>
              <div class="col">
                  <div class="row">
                      <div class="field">
                          <input v-model="password" type="password" maxlength="4"/>
                      </div>
                  </div>
              </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="btn-box">
      <button @click="addQna" type="button">등록</button>
    </div>
  </div>
  <!-- e::Q&A 모달-->

  <!-- s::비밀번호 확인 모달-->
  <div v-if="passwordVisible" class="modal-popup modal-password">
    <button @click="closeCheckPassword" type="button" class="close-modal">
      <img src="@/assets/images/icon/ico_close_w.svg" alt=""/>
    </button>
    <div class="form-box">
      <div class="field-box">
        <ul>
          <li class="colspan">
            <div class="title">
              <span>비밀번호</span>
            </div>
            <div class="col">
              <div class="row">
                <div class="field">
                  <input v-model="password" type="password" maxlength="4"/>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="btn-box">
      <button @click="checkPassword" type="button">확인</button>
    </div>
  </div>
  <!-- e::비밀번호 확인 모달-->

</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref, watch} from 'vue';
import {apiClient} from "@/services/auth-header";
import ShopSnb from "@/components/snb/ShopSnb.vue";
import {useRoute, useRouter} from "vue-router";
import {useAuthStore} from '@/store/auth.module';
import Swiper from "swiper";

const { proxy } = getCurrentInstance();
const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();
const loading = ref(true);
const loadingError = ref(null);
const emit = defineEmits(['mode-change']);
const API_URL = "/shop";
const productId = ref(route.params.id);
const fetchedData = ref(null);
const isFavorite = ref(false);
const activeTab = ref('item-cont1');
const reviewVisible = ref(false);
const qnaVisible = ref(false);
const passwordVisible = ref(false);
const shareVisible = ref(false);
const isDisabled = ref(false);
const isDetail = ref(false);
const selectedOption = ref('');
const selectedPrice = ref(0);
const priceQuantity = ref(0);
const quantity = ref(1);
const reviews = ref(null);
const qna = ref(null);
const selectedImageFile = ref(null);
const reviewContent = ref(null);
const uploadImage = ref(null);
const title = ref(null);
const content = ref(null);
const password = ref(null);

const loadData = async () => {
  try {
    const response = await apiClient.post(API_URL + '/detail', {
      id: productId.value
    });
    const data = response.data;
    const productDetail = data.productDetail;
    fetchedData.value = productDetail;
    reviews.value = productDetail.reviews;
    qna.value = productDetail.qna;
    isFavorite.value = data.isFavorite;
    if(productDetail.options.length > 0){
      setPrice(productDetail.options[0].price);
    }
    loading.value = false;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }
};

const mainSwiper = ref(null);
const setSwiper = () => {
  mainSwiper.value = new Swiper('.visualSwiper', {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: false,
    grabCursor: true, // 드래그할 때 손 모양의 커서 표시
    pagination: {
      el : ".swiper-pagination"
    },
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
  });
};

const buyProduct = () => {
  if(!authStore.status.loggedIn){
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  if(selectedOption.value === ''){
    alert("옵션을 선택해 주세요.");
    return;
  }
  if(confirm("바로 주문 하시겠습니까?")){
    router.push({
      path: '/shop/order',
      query: {
        type: 'fromBuy',
        productId: productId.value,
        quantity: quantity.value,
        optionId: selectedOption.value.id,
      }
    });
  }
};
const addProduct = () => {
  let cart = JSON.parse(localStorage.getItem("cart")) || [];

  const data = {
    id: fetchedData.value.productId,
    quantity: quantity.value,
    selectedPrice: selectedPrice.value,
    selectedOption: selectedOption.value,
  };

// 동일한 id의 제품이 이미 있는지 확인
  const existingItemIndex = cart.findIndex(item => item.id === data.id);

  if (existingItemIndex !== -1) {
    // 이미 존재하는 제품의 수량과 가격을 업데이트
    cart[existingItemIndex].quantity = data.quantity;
    cart[existingItemIndex].selectedPrice = data.selectedPrice;
    cart[existingItemIndex].selectedOption = data.selectedOption;
  } else {
    // 새 제품 추가
    cart.push(data);
  }
  localStorage.setItem("cart", JSON.stringify(cart));
};
const addCart = () => {
  if(!authStore.status.loggedIn){
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  if(selectedOption.value === ''){
    alert("옵션을 선택해 주세요.");
    return;
  }
  addProduct();
  if(confirm("장바구니 추가 되었습니다. 바로 주문 하시겠습니까?")){
    router.push({ path: '/shop/order', query: { type: 'fromCart' } });
  }
};
const handleSelect = () => {
  if(selectedOption.value.quantity === 0){
    alert("품절인 옵션은 구매하실 수 없습니다.");
    selectedOption.value = '';
    return;
  }
  isDisabled.value = !selectedOption.value;
};

const handleTabClick = (tab) => {
  activeTab.value = tab;
};

const decreaseQuantity = () => {
  if(selectedOption.value === ''){
    alert("옵션을 선택해 주세요.");
    return;
  }
  if (quantity.value > 1) {
    quantity.value--;
  }
};

const increaseQuantity = () => {
  if(selectedOption.value === ''){
    alert("옵션을 선택해 주세요.");
    return;
  }
  quantity.value++;
};

const detailLoadMore = () => {
  isDetail.value = !isDetail.value;
};
// 처음에 보이는 항목의 수
const initialReviewItemCount = 3;
const initialQnaItemCount = 3;
const visibleReviewItemCount = ref(initialReviewItemCount);
const visibleQnaItemCount = ref(initialQnaItemCount);

// 현재 보여지는 항목 목록
const visibleReviewItems = computed(() => reviews.value.slice(0, visibleReviewItemCount.value));
// "더보기" 버튼을 클릭했을 때, 항목을 더 보여줌
const reviewLoadMore = () => {
  visibleReviewItemCount.value += initialReviewItemCount;
};
// 더 볼 항목이 남아있는지 확인
// const reviewHasMoreItems = computed(() => visibleReviewItemCount.value < reviews.value.length);

// 현재 보여지는 항목 목록
const visibleQnaItems = computed(() => qna.value.slice(0, visibleQnaItemCount.value));
// "더보기" 버튼을 클릭했을 때, 항목을 더 보여줌
const qnaLoadMore = () => {
  visibleQnaItemCount.value += initialQnaItemCount;
};
// 더 볼 항목이 남아있는지 확인
// const qnaHasMoreItems = computed(() => visibleQnaItemCount.value < qna.value.length);

const qnaId = ref(null);
const qnaIndex = ref(null);
const checkPassword = async (event) => {
  event.preventDefault(); // 폼 제출로 인한 페이지 리로드 방지
  try {
    loading.value = true;
    const response = await apiClient.post(API_URL + '/qna/check/password', {
      id: qnaId.value,
      password: password.value
    });
    if (response.status === 200) {
      if(response.data.checkPassword){
        qna.value.forEach((item, i) => {
          item.isOpen = i === qnaIndex.value ? !item.isOpen : false;
        });
        closeCheckPassword();
      }
    }
  } catch (error) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
  }
}
// 항목의 열림/닫힘 상태를 관리
const toggle = (index, id) => {
  passwordVisible.value = true;
  emit('mode-change', "password");
  qnaIndex.value = index;
  qnaId.value = id;
};

const writeReview = () => {
  if(!authStore.status.loggedIn){
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  reviewVisible.value = true;
  emit('mode-change', "view");
};
const writeQna = () => {
  if(!authStore.status.loggedIn){
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  qnaVisible.value = true;
  emit('mode-change', "view");
};
const closeWriteReview = () => {
  reviewVisible.value = false;
  emit('mode-change', "none");
};
const closeWriteQna = () => {
  qnaVisible.value = false;
  emit('mode-change', "none");
};
const closeCheckPassword = () => {
  passwordVisible.value = false;
  emit('mode-change', "none");
};

const toggleFavorite = () => {
  if(!authStore.status.loggedIn){
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  if (!isFavorite.value) {
    addFavorite();
  } else {
    cancelFavorite();
  }
};

const updateFavorite = async () => {
  try {
    const response = await apiClient.post(API_URL + '/wishlist/save', {
      productId: fetchedData.value.productId,
    });
    if (response.status === 200) {
      closeWriteReview();
      alert("관심상품에 추가 되었습니다.");
      await loadData();
    }
  } catch (error) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
  }
};

const deleteFavorite = async () => {
  try {

    const response = await apiClient.post(API_URL + '/wishlist/delete', [{
      productId: fetchedData.value.productId,
    }]);
    if (response.status === 200) {
      closeWriteReview();
      alert("관심상품에서 삭제 되었습니다.");
      await loadData();
    }
  } catch (error) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
  }
};

const addFavorite = () => {
  if(confirm("관심상품에 추가 하시겠습니까?")){
    isFavorite.value = !isFavorite.value;
    updateFavorite();
  }
};

const cancelFavorite = () => {
  if(confirm("관심상품에서 제외 하시겠습니까?")){
    isFavorite.value = !isFavorite.value;
    deleteFavorite();
  }
};

const closeShare = () => {
  shareVisible.value = false;
};

const shareContent = () => {
  shareVisible.value = true;
}
const url = ref(window.location.href);
const copyToClipboard = () => {
  navigator.clipboard.writeText(url.value)
      .then(() => {
        alert('클립보드에 복사되었습니다.');
      })
      .catch(err => {
        alert('클립보드 복사 중 오류가 발생했습니다: ' + err);
      });
};

const shareToKakao = () => {
  const Kakao = proxy.$Kakao;
  if (Kakao.Share) {
    Kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title: '공유할 제목',
        description: '공유할 설명',
        imageUrl: '공유할 이미지 URL',
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href
        }
      }
    });
  } else {
    console.error('Kakao.Link가 정의되지 않았습니다.');
  }
};
const shareToInstagram = () => {
  const url = `https://www.instagram.com/?url=${encodeURIComponent(window.location.href)}`;
  window.open(url, '_blank');
};

const shareToFacebook = () => {
  const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
  window.open(url, '_blank');
};

const addReview = async () => {
  if(reviewContent.value === null){
    alert("내용을 입력해 주세요.");
    return;
  }
  if(!authStore.status.loggedIn){
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  const reviewData = {
    productId: fetchedData.value.productId,
    content: reviewContent.value
  }
  if (confirm("저장 하시겠습니까?")) {
    const formData = new FormData();
    if (selectedImageFile.value) formData.append('image', selectedImageFile.value);
    formData.append('reviewData', new Blob([JSON.stringify(reviewData)], { type: 'application/json' }));

    loading.value = true; // 작업이 시작되면 로딩 상태를 시작합니다.

    try {
      const response = await apiClient.post(API_URL + '/review/save', formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if(response.status === 200){
        alert("등록 되었습니다.");
        closeWriteReview();
        await loadData();
      }
    } catch (error) {
      error.value = error; // 에러를 저장합니다.
    } finally {
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }
};
const addQna = async () => {
  if(title.value === null){
    alert("제목을 입력해 주세요.");
    return;
  }
  if(content.value === null){
    alert("내용을 입력해 주세요.");
    return;
  }
  if(password.value === null){
    alert("비밀번호를 입력해 주세요.");
    return;
  }
  if(!authStore.status.loggedIn){
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  const qnaData = {
    productId: fetchedData.value.productId,
    title: title.value,
    content: content.value,
    password: password.value,
  }
  if (confirm("질문을 등록 하시겠습니까?")) {
    loading.value = true; // 작업이 시작되면 로딩 상태를 시작합니다.
    try {
      const response = await apiClient.post(API_URL + '/qna/save', qnaData );
      if(response.status === 200){
        alert("등록 되었습니다.");
        closeWriteQna();
        await loadData();
      }
    } catch (error) {
      error.value = error; // 에러를 저장합니다.
    } finally {
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }
};
const loadFile = (event) => {
  const file = event.target.files[0];
  if (file) {
    selectedImageFile.value = file;
    uploadImage.value = URL.createObjectURL(event.target.files[0]);
  }
};

const saveReview = async () => {
};

watch(selectedOption, (sel) => {
  if(sel !== ""){
    quantity.value = 1;
    selectedPrice.value = sel.price;
    priceQuantity.value = sel.price;
  }
});
watch(quantity, (val) => {
  if(selectedPrice.value !== 0){
    priceQuantity.value = selectedPrice.value*val;
  }
});

const setPrice = (price) => {
  selectedPrice.value = price;
  priceQuantity.value = price;
};

onMounted(() => {
  loadData().then(() => {
    setSwiper();
  });
});
</script>