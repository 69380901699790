<template>
  <li>
    <div class="title">
      <span>학력*</span>
    </div>
    <div class="col">
      <template v-if="profiles.length">
        <div class="row" v-for="profile in profiles.sort((a, b) => a.id - b.id)" :key="profile.id">
          <div class="sub-title">
            <span>{{ profile.label }}</span>
          </div>
          <div class="field">
            <div class="inner-list">
              <div class="input-wrap">
                <select v-model="profile.year">
                  <option value="" disabled>연도</option>
                  <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                </select>
                <input type="text" v-model="profile.title" maxlength="20" placeholder="학교명"/>
                <input type="text" v-model="profile.description" maxlength="20" placeholder="학과"/>
                <template v-if="profile.type === '기타'">
                  <input type="text" v-model="profile.educationStatus" placeholder="졸업 여부">
                </template>
                <template v-else>
                  <select v-model="profile.educationStatus">
                    <option value="" disabled>졸업 여부</option>
                    <option value="GRADUATED">졸업</option>
                    <option value="COMPLETED">수료</option>
                    <option value="ENROLLED">재학</option>
                  </select>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
      </template>
    </div>
  </li>

</template>

<script setup>
import {computed, onMounted, reactive, ref, toRefs} from 'vue';

const props = defineProps({
  title: String,
  profiles: Array,
  years: Array
});
const { profiles, years } = toRefs(props);
const emit = defineEmits(['update-profile']);

const groupedProfiles = computed(() => {
  if (!Array.isArray(profiles.value[1].items)) {
    return {};
  }
  return profiles.value[1].items.reduce((acc, profile) => {
    if (!acc[profile.type]) {
      acc[profile.type] = [];
    }
    acc[profile.type].push(profile);
    return acc;
  }, {});
});

const emitUpdate = (profile) => {
  emit('update-profile', profile);
};

const emitDelete = (type, index) => {
  emit('delete-profile', type, index);
};
const addListItem = (type) => {
  const newProfile = reactive({
    id: null,
    type: type,
    year: '',
    title: '',
    description: '',
    location: ''
  });
  profiles.value[1].items.push(newProfile);
  emit('update-profile', profiles.value[1]); // 필요시 활성화
};
const removeItem = (type, index) => {
  emitDelete(type, index);
};

// 유효성 검사 함수
const profileErrors = ref([]);
const validateProfile = (profile) => {
  const index = profiles.value.indexOf(profile);
  if (index === -1) return;

  const errors = {};

  if (!profile.title) {
    errors.title = 'Title is required';
  }

  if (!profile.description) {
    errors.description = 'Description is required';
  }

  if (!profile.location) {
    errors.location = 'Location is required';
  }
  profileErrors.value[index] = errors;
};

</script>