<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div class="content-panel">
              <div class="my-coupon">
                <div class="hgroup">
                  <h2 class="section-title">쿠폰</h2>
<!--                  <button type="button" class="open-coupon">쿠폰등록</button>-->
                </div>
                <div class="search-period">
                  <div class="label">조회기간</div>
                  <div class="search-wrap">
                    <div class="button-box">
                      <button type="button" class="on" @click="handleClick(1, $event)">오늘</button>
                      <button type="button" @click="handleClick(7, $event)">7일</button>
                      <button type="button" @click="handleClick(15, $event)">15일</button>
                      <button type="button" @click="handleClick(30, $event)">1개월</button>
                      <button type="button" @click="handleClick(90, $event)">3개월</button>
                      <button type="button" @click="handleClick(365, $event)">1년</button>
                    </div>
                    <div class="date-wrap">
                      <input v-model="selectedStartDate" type="date">
                      <span>~</span>
                      <input v-model="selectedEndDate" type="date">
                    </div>
                  </div>
                  <button @click="loadData" type="button" class="submit">조회</button>
                </div>
                <div class="coupon-tab">
                  <button
                      type="button"
                      :class="{ on: activeTab === 'coupon-cont1' }"
                      @click="handleTabClick('coupon-cont1')"
                      rel="item-cont2">사용 가능
                  </button>
                  <button
                      type="button"
                      :class="{ on: activeTab === 'coupon-cont2' }"
                      @click="handleTabClick('coupon-cont2')"
                      rel="item-cont3">사용 불가
                  </button>
                </div>
                <div v-show="activeTab === 'coupon-cont1'" class="coupon-cont">
                  <div class="list">
                    <ul class="thead">
                      <li>
                        <div class="col col1">쿠폰</div>
                        <div class="col col2">혜택</div>
                        <div class="col col3">유효기간</div>
                        <div class="col col4">발급일</div>
                      </li>
                    </ul>
                    <ul class="tbody">
                      <li v-for="item in availableCoupon" :key="item.id">
                        <div class="col col1">
                          <figure>
                            <img :src="item.coupon.couponImageUrl" alt=""/>
                            <figcaption>{{ item.coupon.couponName }}</figcaption>
                          </figure>
                        </div>
                        <div class="col col2">
                          <strong>{{ item.coupon.description }}</strong>
                        </div>
                        <div class="col col3">
                          {{ new Date(item.createdAt).toISOString().split('T')[0] }}<br>
                          ~ {{ item.coupon.expirationDate }}
                        </div>
                        <div class="col col4">{{ item.createdAt }}</div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div v-show="activeTab === 'coupon-cont2'" class="coupon-cont">
                  <div class="list">
                    <ul class="thead">
                      <li>
                        <div class="col col1">쿠폰</div>
                        <div class="col col2">혜택</div>
                        <div class="col col3">유효기간</div>
                        <div class="col col4">발급일</div>
                      </li>
                    </ul>
                    <ul class="tbody">
                      <li v-for="item in unavailableCoupon" :key="item.id">
                        <div class="col col1">
                          <figure>
                            <img :src="item.coupon.couponImageUrl" alt=""/>
                            <figcaption>{{ item.coupon.couponName }}</figcaption>
                          </figure>
                        </div>
                        <div class="col col2">
                          <strong>{{ item.coupon.description }}</strong>
                        </div>
                        <div class="col col3">
                          {{ new Date(item.createdAt).toISOString().split('T')[0] }}<br>
                          ~ {{ item.coupon.expirationDate }}
                        </div>
                        <div class="col col4">{{ item.createdAt }}</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
    </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import {onMounted, ref} from 'vue';
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import {apiClient} from "@/services/auth-header";

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const activeTab = ref('coupon-cont1');
const selectedPeriod = ref(1);
const selectedStartDate = ref('');
const selectedEndDate = ref('');
const API_URL = "/user"
const availableCoupon = ref([]);
const unavailableCoupon = ref([]);

const handleTabClick = (tab) => {
  activeTab.value = tab;
};

const isMobile = ref(false);
const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};
// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

const handleClick = (period, event) => {
  const buttons = event.target.parentElement.querySelectorAll('button');
  buttons.forEach(button => button.classList.remove('on'));
  event.target.classList.add('on');

  const startDate = new Date();
  const endDate = new Date();

  startDate.setDate(endDate.getDate() - period);

  selectedStartDate.value = startDate.toISOString().split('T')[0];
  selectedEndDate.value = endDate.toISOString().split('T')[0];
  selectedPeriod.value = period;
  loadData();
};

const loadData = async () => {
  try {
    const response = await apiClient.post(API_URL + '/my/coupon', {
      searchPeriod: selectedPeriod.value,
      startDate: selectedStartDate.value,
      endDate: selectedEndDate.value
    });
    availableCoupon.value = response.data.availableCoupon;
    unavailableCoupon.value = response.data.unavailableCoupon;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }finally {
    loading.value = false;
  }
};

onMounted(() => {
  document.body.classList.remove('bg-white');
  checkMobile();
  loadData();
});
</script>