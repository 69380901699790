import OAuthSignup from "@/components/auth/OAuthSignup.vue";

export const oauthRoutes = [

    {
        path: '/oauth/signup',
        name: 'OAuthSignup',
        component: OAuthSignup,
        children: [
        ]
    },

];