<template>
  <!-- s::PAGE -->
  <div v-if="artworks" class="contents">
    <!-- s:: ART & ARTIST -->
    <div class="art-artist">
      <div class="view">
        <div class="layout">
          <div class="box">
            <ArtistSnb :id="id"/>
            <div class="view-panel">
              <div class="artwork">
                <ul>
                  <li v-for="(artwork, index) in artworks" :key="index">
                    <div class="img">
                      <img :src="artwork.watermarkedImageUrl" alt="{{ artwork.title }}" style="width: 452px;height: 452px;"/>
                    </div>
                    <div class="mask" @click="openModal(artwork.id)">
                      <button type="button" class="open-artwork">See More</button>
                      <p>{{ artwork.title }}<br>
                        {{ artwork.material }}_{{ artwork.size }}<br>
                        {{ artwork.year }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- e:: ART & ARTIST -->
  </div>
  <!-- Artworks Modal -->
  <div v-if="isModalOpen" class="modal-popup modal-artwork">
      <div class="modal-con">
        <div class="swiper-container slider main-swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(artwork, index) in artworks" :key="index" :data-id="artwork.id">
                <div class="inner">
                  <div class="img zoom-area">
                    <img @click="toggleEnlarge" :class="{ enlarged: isEnlarged }" :src="artwork.watermarkedImageUrl" :alt="artwork.title" style="height: 624px;"/>
                  </div>
                  <div class="txt-box">
                    <p>{{ artwork.title }}<br>
                      {{ artwork.material }}_{{ artwork.size }}<br>
                      {{ artwork.year }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-control">
              <!-- Add Navigation if needed -->
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
        </div>
        <div class="swiper-container slider-thumbnail">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(artwork, index) in artworks" :key="index">
              <img :src="artwork.watermarkedImageUrl" alt="" style="max-height: 330px;"/>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="close-modal close-artwork" @click="closeModal">
        <img src="@/assets/images/icon/ico_close_b.svg" alt=""/>
      </button>
    </div>
  <!-- e::PAGE -->
</template>

<script setup>
import {onMounted, ref, watch} from 'vue';
import {apiClient} from "@/services/auth-header";
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import ArtistSnb from "@/components/snb/ArtistSnb.vue";
import {onBeforeRouteLeave, useRoute} from "vue-router"; // Assuming jQuery is installed and available

const route = useRoute();
const artistId = ref(route.params.id);
const artWorkId = ref(route.params.artWorkId);
const emit = defineEmits(['mode-change']);
const artworks = ref(null);
const isModalOpen = ref(false);
const API_URL = "/artwork";
const mainSwiper = ref(null);
const thumbnailSwiper = ref(null);

const openModal = (id) => {
  isModalOpen.value = true;
  emit('mode-change', 'artwork');
  setTimeout(() => {
    if (mainSwiper.value) {
      const swiperInstance = mainSwiper.value;
      const slideElements = swiperInstance.slides; // Swiper의 슬라이드 요소들
      let slideIndex = -1;
      for (let i = 0; i < slideElements.length; i++) {
        if (Number(slideElements[i].getAttribute('data-id')) === Number(id)) {
          slideIndex = i;
          break;
        }
      }

      if (slideIndex !== -1) {
        swiperInstance.slideTo(slideIndex);
      } else {
        console.warn(`Slide with id ${id} not found`);
      }
    }
  }, 100);
};
const isEnlarged = ref(false);

const toggleEnlarge = () => {
  isEnlarged.value = !isEnlarged.value;
};
const closeModal = () => {
  isModalOpen.value = false;
  emit('mode-change', 'none');
};

const initializeSwiper = () => {
  mainSwiper.value = new Swiper('.main-swiper', {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: false,
    grabCursor: true, // 드래그할 때 손 모양의 커서 표시
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
  });
  thumbnailSwiper.value = new Swiper('.slider-thumbnail', {
    slidesPerView: 6,
    spaceBetween: 20,
    loop: false,
    grabCursor: true, // 드래그할 때 손 모양의 커서 표시
    slideToClickedSlide: true,
    on: {
      click: function () {
        const clickedIndex = this.clickedIndex;
        if (mainSwiper.value) {
          mainSwiper.value.slideTo(clickedIndex); // 인덱스 이동
        }
      }
    },
    controller: {
      control: mainSwiper.value,
    },
  });
};

watch(isModalOpen, (newValue) => {
  if (newValue) {
    setTimeout(() => {
      initializeSwiper();
    }, 10);
  } else {
    if (mainSwiper.value) {
      mainSwiper.value.destroy(true, true);
      mainSwiper.value = null;
    }
    if (thumbnailSwiper.value) {
      thumbnailSwiper.value.destroy(true, true);
      thumbnailSwiper.value = null;
    }
  }
});

onMounted(async () => {
  try {
    const response = await apiClient.post(API_URL + '/list', { id: artistId.value } );
    artworks.value = response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  if (artWorkId.value) {
    openModal(artWorkId.value);
  }
});

onBeforeRouteLeave((to, from, next) => {
  isModalOpen.value = false;
  emit('mode-change', 'none');
  next();  // 다음으로 이동을 허용합니다.
});

</script>

<style>
.swiper-slide .inner .img {
  transition: transform 0.3s ease;
}

img.enlarged {
  transform: scale(1.5);
  cursor: zoom-out;
}
.zoom-area {
  cursor: zoom-in;
}
</style>
