<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div class="content-panel">
              <div class="my-review">
                <h2 class="section-title">구매 후기</h2>
                <div class="item-list">
                  <div class="review-tab">
                    <button
                        type="button"
                        :class="{ on: activeTab === 'review-cont1' }"
                        @click="handleTabClick('review-cont1')"
                        rel="item-cont2">작성 가능한 리뷰
                    </button>
                    <button
                        type="button"
                        :class="{ on: activeTab === 'review-cont2' }"
                        @click="handleTabClick('review-cont2')"
                        rel="item-cont3">작성한 리뷰
                    </button>
                  </div>
                  <div v-show="activeTab === 'review-cont1'" id="review-cont1" class="review-cont" style="display: block;">
                    <ul>
                      <li v-for="(item, index) in unreviewedProducts" :key="item.id">
                        <div class="item-img">
                          <img :src="item.imageUrl" alt=""/>
                        </div>
                        <div class="item-info">
                          <h4>{{ item.name }}</h4>
                          <div class="quantity-wrap">
                            {{ item.sizeName }} / {{ item.quantity }}개
                          </div>
                          <p class="p-txt">{{ item.subtotal.toLocaleString() }}원</p>
                        </div>
                        <div class="status-box">
                          <button @click="writeReview(index)" type="button" class="open-review">리뷰 작성</button>
                        </div>
                      </li>
                    </ul>
                    <div v-if="unreviewedProducts.length === 0" class="nodata">
                      작성 가능한 상품이 없습니다.
                    </div>
                  </div>

                  <div v-show="activeTab === 'review-cont2'" id="review-cont2" class="review-cont">
                    <ul class="type2">
                      <li v-for="(item, index) in reviewedProducts" :key="item.id">
                        <div class="item-img">
                          <img :src="item.imageUrl" alt=""/>
                        </div>
                        <div class="item-info">
                          <h4>{{ item.name }}</h4>
                          <div class="quantity-wrap">
                            {{ item.sizeName }} / {{ item.quantity }}개
                          </div>
                          <p class="p-txt">{{ item.subtotal.toLocaleString() }}원</p>
                        </div>

                        <div class="review-content">
                          <div class="col1">
                          </div>
                          <div class="col2">
                            <div class="txt">{{ item.reviewContent }}</div>
                          </div>
                          <div class="status-box">
                            <button @click="updateReview(index)" type="button" class="open-review">리뷰 수정</button>
<!--                            <button type="button" class="del">삭제</button>-->
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div v-if="reviewedProducts.length === 0" class="nodata">
                      작성 가능한 상품이 없습니다.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
    </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>

  <!-- s::리뷰 모달-->
  <div v-if="reviewVisible" class="modal-popup modal-review">
    <button @click="closeWriteReview" type="button" class="close-modal">
      <img src="@/assets/images/icon/ico_close_w.svg" alt=""/>
    </button>
    <div class="form-box">
      <div class="field-box">
        <ul>
          <li class="colspan">
            <div class="title">
              <span>상품명</span>
            </div>
            <div class="col">
              <div class="row">
                <div class="field">
                  <div class="input-txt">
                    {{ selectedProduct.name }}
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="colspan">
            <div class="title">
              <span>내용</span>
            </div>
            <div class="col">
              <div class="row">
                <div class="field">
                  <textarea v-model="reviewContent"></textarea>
                </div>
              </div>
            </div>
          </li>
          <li class="colspan">
            <div class="title">
              <span>이미지 등록</span>
            </div>
            <div class="col">
              <div class="row">
                <div class="field">
                  <div class="filebox">
                    <label for="upload-img">이미지 업로드</label>
                    <input type="file" id="upload-img" accept="image/*" @change="loadFile">
                    <img v-if="uploadImage" :src="uploadImage" ref="output" id="output"/>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="btn-box">
      <button @click="addReview" type="button">등록</button>
    </div>
  </div>
  <!-- s::리뷰 모달-->

</template>


<script setup>
import {onMounted, ref} from 'vue';
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import {apiClient} from "@/services/auth-header";
import {useRouter} from "vue-router";
import {useAuthStore} from "@/store/auth.module";

const authStore = useAuthStore();
// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const emit = defineEmits(['mode-change']);
const activeTab = ref('review-cont1');
const API_URL = "/user";
const reviewedProducts = ref([]);
const unreviewedProducts = ref([]);
const reviewVisible = ref(false);
const selectedProduct = ref(false);
const reviewContent = ref("");
const selectedImageFile = ref(null);
const uploadImage = ref(null);

const handleTabClick = (tab) => {
  activeTab.value = tab;
};

const isMobile = ref(false);
const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};
// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};
const loadData = async () => {
  try {
    const response = await apiClient.post(API_URL + '/my/review');
    reviewedProducts.value = response.data.reviewedProducts;
    unreviewedProducts.value = response.data.unreviewedProducts;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }finally {
    loading.value = false;
  }
};

const writeReview = (index) => {
  if(!authStore.status.loggedIn){
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  reviewVisible.value = true;
  emit('mode-change', "view");
  selectedProduct.value = unreviewedProducts.value[index];
};
const updateReview = (index) => {
  if(!authStore.status.loggedIn){
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  reviewVisible.value = true;
  emit('mode-change', "view");
  selectedProduct.value = reviewedProducts.value[index];
  reviewContent.value = selectedProduct.value.reviewContent;
};
const closeWriteReview = () => {
  reviewVisible.value = false;
  emit('mode-change', "none");
};
const loadFile = (event) => {
  const file = event.target.files[0];
  if (file) {
    selectedImageFile.value = file;
    uploadImage.value = URL.createObjectURL(event.target.files[0]);
  }
};

const addReview = async () => {
  if(!authStore.status.loggedIn){
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  const reviewData = {
    orderId: selectedProduct.value.orderId,
    orderDetailId: selectedProduct.value.orderDetailId,
    productId: selectedProduct.value.productId,
    content: reviewContent.value
  }
  if (confirm("저장 하시겠습니까?")) {
    const formData = new FormData();
    if (selectedImageFile.value) formData.append('image', selectedImageFile.value);
    formData.append('reviewData', new Blob([JSON.stringify(reviewData)], { type: 'application/json' }));

    loading.value = true; // 작업이 시작되면 로딩 상태를 시작합니다.

    try {
      const response = await apiClient.put('/shop/review/save', formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if(response.status === 200){
        alert("등록 되었습니다.");
        closeWriteReview();
        await loadData();
      }
    } catch (error) {
      error.value = error; // 에러를 저장합니다.
    } finally {
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }
};
onMounted(() => {
  document.body.classList.remove('bg-white');
  checkMobile();
  loadData();
});
</script>