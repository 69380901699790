<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: ARTICLE -->
      <div class="article">
        <div class="layout">
          <div class="box">
            <ArticleSnb/>
            <div class="content-panel">
              <div class="view-type1">
                <div class="img">
                  <img :src="exhibition.articleImageUrl" alt="">
                </div>
                <div class="info-box">
                  <h3 v-html="exhibition.title"/>
                  <dl>
                    <dt>기간</dt>
                    <dd>{{ exhibition.period }}</dd>
                    <dt>장소</dt>
                    <dd>{{ exhibition.location }}</dd>
                    <template v-if="exhibition.time">
                      <dt>시간</dt>
                      <dd>{{ exhibition.time }}</dd>
                    </template>
                    <dt>문의</dt>
                    <dd>{{ exhibition.phoneNumber }}</dd>
                    <dt>링크</dt>
                    <dd>
                      <a :href="exhibition.link" target="_blank" class="link">{{ exhibition.link }}</a>
                      <p class="txt">
                        {{ exhibition.content }}
                      </p>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: ARTICLE -->
  </div>
  <!-- e::PAGE -->
</template>

<script setup>
import {apiClient} from "@/services/auth-header";
import ArticleSnb from "@/components/snb/ArticleSnb.vue";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";

const route = useRoute();
const articleId = ref(route.params.id);
const loading = ref(true);
const error = ref(null);
const exhibition = ref(null);
const API_URL = "/article";

onMounted(async () => {
  try {
    const response = await apiClient.post(API_URL + '/detail', {id: articleId.value});
    exhibition.value = response.data;
  } catch (error) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후에는 로딩 상태를 끝냅니다.
  }
});
</script>