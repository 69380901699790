<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: ARTSHOP -->
      <div class="artshop">
        <div class="layout">
          <div class="box">
            <ShopSnb @search-list="handleSearch"/>
            <div class="content-panel">
              <div class="goods">
                <div v-if="tag === 'ALL'" class="banner visual-slide">
                  <div class="swiper visualSwiper">
                    <div class="swiper-wrapper">
                      <div v-for="item in banners" class="swiper-slide">
                        <picture>
                          <source media="(max-width:768px)" :srcset="item">
                          <img :src="item" alt=""/>
                        </picture>
                      </div>
                    </div>
                    <div class="swiper-pagination"></div>
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                  </div>
                </div>
                <div class="list">
                  <ul v-if="fetchedData && fetchedData.length > 0">
                    <li v-for="item in fetchedData" :key="item.productId">
                      <router-link :to="{ name: 'ProductDetail', params: { id: item.productId, tag: item.tag } }">
                        <div class="img">
                          <img :src="item.images[0].imageUrl" alt=""/>
                        </div>
                        <div class="txt-box">
                          <h3>{{ item.name }}</h3>
                          <p class="price">{{ item.options[0].price.toLocaleString() }}원</p>
                        </div>
                      </router-link>
                    </li>
                  </ul>
                  <ul v-else>
                    <li class="nodata">
                      등록된 제품이 없습니다.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- e:: ARTSHOP -->
    </div>
  <!-- e::PAGE -->
</template>


<script setup>
import {onMounted, ref, watch} from 'vue';
import {apiClient} from "@/services/auth-header";
import ShopSnb from "@/components/snb/ShopSnb.vue";
import {useRoute} from "vue-router";
import Swiper from "swiper";

const route = useRoute();
const loading = ref(true);
const error = ref(null);
const tag = ref(route.query.tag);

const mainSwiper = ref();
const fetchedData = ref(null);
const banners = ref([]);
const searchQuery = ref('');
const API_URL = "/shop";
const loadData = async () => {
  try {
    const response = await apiClient.post(API_URL + '/list', {
      name: searchQuery.value,
      tag: tag.value
    });
    fetchedData.value = response.data.list;
    banners.value = response.data.banners;
    loading.value = false;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }
};

const handleSearch = (val) => {
  searchQuery.value = val;
  loadData();
};

const searchByTag = async (tag) => {
  try {
    const response = await apiClient.post(API_URL + '/list', {
      tag: tag
    });
    loading.value = false;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }
};

const setSwiper = () => {
  mainSwiper.value = new Swiper('.visualSwiper', {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
    grabCursor: true, // 드래그할 때 손 모양의 커서 표시
    pagination: {
      el : ".swiper-pagination"
    },
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    autoplay: {
      delay: 2000,
    },
  }).init();
};

onMounted(async () => {
  await loadData();
  setSwiper();
});

// tag 값이 변경될 때마다 searchByTag 함수 호출
watch(tag, (newTag) => {
  loading.value = true; // 새로운 요청을 시작하기 전에 로딩 상태로 설정
  searchByTag(newTag);
});
</script>