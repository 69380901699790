<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="loadingError" class="error">{{ loadingError.message }}</div>

  <div v-else class="contents">
      <!-- s:: ORDER -->
      <div class="order">
        <div class="layout">
          <div class="l-cont">
            <form>
              <section>
                <h3>주문 상품 <strong>{{ loadItems.length }}</strong>건</h3>
                <div class="item-list">
                  <ul>
                    <li v-for="(item, index) in loadItems">
                      <div class="item-img">
                        <img :src="item.images[0].imageUrl" :alt="item.name"/>
                      </div>
                      <div v-if="type === 'fromCart'" class="item-info">
                        <h4>{{ item.name }}</h4>
                        <div class="quantity-wrap">{{ storageItems[index].selectedOption.size.sizeName }} / {{ storageItems[index].quantity }}개</div>
                        <p class="p-txt">{{ (storageItems[index].selectedPrice*storageItems[index].quantity).toLocaleString() }}원</p>
                      </div>
                      <div v-else class="item-info">
                        <h4>{{ item.name }}</h4>
                        <div class="quantity-wrap">{{ item.options[0].size.sizeName }} / {{ quantity }}개</div>
                        <p class="p-txt">{{ (item.options[0].price*quantity).toLocaleString() }}원</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </section>

              <section>
                <h3>결제 수단</h3>
                <fieldset>
                  <div class="form-box">
                    <div class="field-row">
                      <div class="label">
                        <span>결제수단 선택</span>
                      </div>
                      <div class="cmm-form">
                        <div class="radio-group">
                          <template v-if="!isMobile">
                            <span v-for="(paymethod, index) in paymethodList" :key="index" class="radio-type2">
                            <input
                                :value="paymethod.value"
                                type="radio"
                                name="payment-radio"
                                :id="'pay-radio' + index"
                                v-model="selectedPaymethod"
                            >
                            <label :for="'pay-radio' + index">{{ paymethod.label }}</label>
                            </span>
                          </template>
                          <template v-else>
                            <span v-for="(paymethod, index) in mobilePaymethodList" :key="index" class="radio-type2">
                            <input
                                :value="paymethod.value"
                                type="radio"
                                name="payment-radio"
                                :id="'pay-radio' + index"
                                v-model="selectedPaymethod"
                            >
                            <label :for="'pay-radio' + index">{{ paymethod.label }}</label>
                            </span>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>


                <form v-if="isMobile && (loadItems.length > 0 && paymentData)" name="mobileweb" method="post" accept-charset="EUC-KR">
                  <input v-model="selectedPaymethod" type="hidden" name="P_INI_PAYMENT">
                  <input v-model="paymentData.mid" type="hidden" name="P_MID"/>
                  <input v-model="paymentData.oid" type="hidden" name="P_OID"/>
<!--                  <input value="mobile_test1234" type="hidden" name="P_OID"/>-->
                  <input v-model="paymentData.price" type="hidden" name="P_AMT"/>
<!--                  <input value="1000" type="hidden" name="P_AMT"/>-->
                  <input v-model="loadItems[0].name" type="hidden" name="P_GOODS">
                  <input v-model="user.name" type="hidden" name="P_UNAME">
                  <input v-model="returnUrl" type="hidden" name="P_NEXT_URL">
                  <input v-model="user.phone" type="hidden" name="P_MOBILE">
                  <input v-model="user.email" type="hidden" name="P_EMAIL">
                  <input v-model="paymentData.timestamp" type="hidden" name="P_TIMESTAMP">
                  <input v-model="paymentData.chkfake" type="hidden" name="P_CHKFAKE">
                  <input type="hidden" name="P_CHARSET" value="utf8">
<!--                  <input type="hidden" name="P_RESERVED" value="centerCd=Y&amt_hash=Y">-->
                  <input type="hidden" name="P_RESERVED" value="centerCd=Y">
                </form>

                <form v-if="!isMobile && (loadItems.length > 0 && paymentData)" id="form">
                  <input type="hidden" name="version" value="1.0">
                  <input v-model="selectedPaymethod" type="hidden" name="gopaymethod">
                  <input v-model="paymentData.mid" type="hidden" name="mid"/>
                  <input v-model="paymentData.oid" type="hidden" name="oid"/>
                  <input v-model="paymentData.price" type="hidden" name="price">
                  <input v-model="paymentData.timestamp" type="hidden" name="timestamp"/>
                  <input type="hidden" value="Y" name="use_chkfake"/>
                  <input v-model="paymentData.signature" type="hidden" name="signature"/>
                  <input v-model="paymentData.verification" type="hidden" name="verification"/>
                  <input v-model="paymentData.mKey" type="hidden" name="mKey"/>
                  <input type="hidden" name="currency" value="WON">
                  <input v-model="loadItems[0].name" type="hidden" name="goodname">
                  <input v-model="user.name" type="hidden" name="buyername">
                  <input v-model="user.phone" type="hidden" name="buyertel">
                  <input v-model="user.email" type="hidden" name="buyeremail">
                  <input v-model="returnUrl" type="hidden" name="returnUrl">
                  <input v-model="closeUrl" type="hidden" name="closeUrl">
                  <input type="hidden" name="acceptmethod" value="centerCd(Y)">
                  <input type="hidden" name="idcName" value="fc">
                </form>
              </section>

              <section v-if="user">
                <h3>주문자 정보</h3>
                <fieldset>
                  <div class="form-box">
                    <div class="field-row">
                      <div class="label">
                        <span>주문자명</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <input v-model="user.name" type="text"/>
                        </div>
                      </div>
                    </div>
                    <div class="field-row">
                      <div class="label">
                        <span>연락처</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <input v-model="user.phone" type="tel"/>
                        </div>
                      </div>
                    </div>
                    <div class="field-row">
                      <div class="label">
                        <span>이메일</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <input v-model="user.email" type="text"/>
                        </div>
                        <p class="txt">주문 확인 메일이 발송 되니 정확하게 입력 해주세요.</p>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </section>

              <section>
                <h3>배송 정보</h3>
                <fieldset>
                  <div class="form-box">
<!--
                    <div class="field-row">
                      <div class="label">
                        <span>배송지목록</span>
                      </div>
                      <div class="cmm-form">
                        <div class="radio-group">
                          <span class="radio-type2">
                            <input type="radio" name="shipping-radio" id="shipping1">
                            <label for="shipping1">배송지1</label>
                          </span>
                          <span class="radio-type2">
                            <input type="radio" name="shipping-radio" id="shipping2">
                            <label for="shipping2">배송지2</label>
                          </span>
                          <span class="radio-type2">
                            <input type="radio" name="shipping-radio" id="shipping3">
                            <label for="shipping3">신규배송지</label>
                          </span>
                        </div>
                      </div>
                    </div>
-->
                    <div class="field-row">
                      <div class="label">
                        <span>수령인</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <input v-model="recipient.name" type="text"/>
                        </div>
                      </div>
                    </div>
                    <div class="field-row">
                      <div class="label">
                        <span>연락처</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <input v-model="recipient.phone" type="tel"/>
                        </div>
                      </div>
                    </div>
                    <div class="field-row">
                      <div class="label">
                        <span>주소</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <button type="button" class="btn" @click="handleSearchAddress">도로명 주소</button>
                          <div v-if="recipient.zonecode" class="input-txt">
                            ({{ recipient.zonecode }}) {{ recipient.address }} {{ recipient.buildingName }} {{ recipient.addressDetail }}
                          </div>
                          <input v-if="recipient.zonecode" v-model="recipient.addressDetail" type="text" placeholder="상세주소 입력">
                        </div>
                      </div>
                    </div>
<!--
                    <div class="field-row">
                      <div class="label">
                        <span>배송요청사항</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <input v-model="recipient.deliveryRequests" type="text" placeholder="배송요청사항을 작성해주세요.">
                        </div>
                      </div>
                    </div>
-->
<!--
                    <div class="field-row">
                      <div class="label">
                        <span>배송지 저장</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <div class="chk-group">
                            <span class="checkbox-type2">
                                <input type="checkbox" id="chk-s1">
                                <label for="chk-s1">
                                    배송지 추가
                                </label>
                            </span>
                            <span class="checkbox-type2">
                              <input type="checkbox" id="chk-s2">
                              <label for="chk-s2">
                                  기본배송지 설정
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
-->
                  </div>
                </fieldset>
              </section>
<!--
              <section>
                <h3>적립금 사용</h3>
                <fieldset>
                  <div class="form-box">
                    <div class="field-row">
                      <div class="label">
                        <span>보유 적립금</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-txt">1,000P</div>
                      </div>
                    </div>
                    <div class="field-row">
                      <div class="label">
                        <span>적립금 사용</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <input type="text" value="0">
                        </div>
                        <p class="txt">! 적립금 정책 문구</p>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </section>
-->
<!--
              <section>
                <h3>세금계산서/현금영수증 발행</h3>
                <fieldset>
                  <div class="form-box">
                    <div class="field-row">
                      <div class="label">
                        <span>영수증 종류</span>
                      </div>
                      <div class="cmm-form">
                        <div class="radio-group">
                                                <span class="radio-type2">
                                                    <input type="radio" name="receipt-radio" id="receipt-radio1">
                                                    <label for="receipt-radio1">발급안함</label>
                                                </span>
                          <span class="radio-type2">
                                                    <input type="radio" name="receipt-radio" id="receipt-radio2">
                                                    <label for="receipt-radio2">현금영수증</label>
                                                </span>
                          <span class="radio-type2">
                                                    <input type="radio" name="receipt-radio" id="receipt-radio3">
                                                    <label for="receipt-radio3">세금계산서</label>
                                                </span>
                        </div>
                        <div class="input-box" style="margin-top:10px;">
                          <input type="tel" placeholder="휴대폰 번호">
                          <input type="number" placeholder="사업자등록번호">
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </section>
-->
            </form>
          </div>
          <div class="r-cont">
            <div class="inbox">
              <div class="price-panel">
                <div class="top-box">
                  <h3>총 상품 <strong>{{ loadItems.length }}</strong>개</h3>
                  <dl class="dl-type1">
                    <dt>상품 금액</dt>
                    <dd><strong id="sum_consumer_option_price">{{ priceQuantity.toLocaleString() }}<em>원</em></strong></dd>
                    <!-- 할인금액 추가
                    <dt>할인금액</dt>
                    <dd><span class="color-pink">5,000원</span></dd>
                    -->
                    <dt>배송비</dt>
                    <dd v-if="priceQuantity < 50000" id="final_delivery_price">4,000<em>원</em></dd>
                    <dd v-else id="final_delivery_price">0<em>원</em></dd>
                  </dl>
                  <div class="total-price">
                    <span>결제 예상금액</span>
                    <p v-if="priceQuantity < 50000" class="total-payment">{{ (priceQuantity+4000).toLocaleString() }}<em>원</em></p>
                    <p v-else class="total-payment">{{ priceQuantity.toLocaleString() }}<em>원</em></p>
                  </div>
                </div>
                <div class="bottom-box">
                  <h4>유의 사항</h4>
                  <ul>
                    <li>배송비는 금액에 따라 변경될 수 있습니다.</li>
                    <li>적립금은 다음 주문/결제에서 확인가능합니다.</li>
                  </ul>
                </div>
              </div>
              <div class="button-box">
                <button @click="paybtn" type="button">상품 주문</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: CART -->
    </div>
  <!-- e::PAGE -->
</template>

<script setup>
import {onMounted, ref, watch} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useRoute} from 'vue-router';
import common from "@/assets/js/common";

const isMobile = ref(false);
const orderId = ref(null);
const route = useRoute();
const type = route.query.type;
const productId = route.query.productId;
const quantity = route.query.quantity;
const optionId = route.query.optionId;
const VUE_APP_API_BASE_URL = process.env.VUE_APP_REDIRECT_URI;
const returnUrl = ref(null);
const closeUrl = `${VUE_APP_API_BASE_URL}/shop/order`;
const loading = ref(true);
const loadingError = ref(null);
const emit = defineEmits(['mode-change']);
const API_URL = "/shop";
const storageItems = ref(JSON.parse(localStorage.getItem("cart")));
const loadItems = ref([]);
const priceQuantity = ref(0);
const selectedPaymethod = ref(null);
const paymentData = ref(null);
const user = ref(null);
const param = ref(null);
const recipient = ref({
  name: "",
  email: "",
  address: "",
  phone: "",
  zonecode: "",
});
const paymethodList = ref([
    { label: "신용카드", value: "Card" },
    { label: "실시간 계좌이체", value: "DirectBank" },
  // { label: "가상계좌", value: "VBank" },
  // { label: "휴대폰", value: "HPP" },
  // "폰빌, 전화결제": "PhoneBill",
  // "문화상품권":	"Culture",
  // "스마트문상":	"DGCL",
  // "도서문화상품권":	"Bcsh",
  // "해피머니상품권":	"HPMN",
  // "틴캐시":	"TeenCash",
  // "페이핀":	"Paypin",
  // "OK_CASH_BAG 포인트": 	"OCBPoint",
  // "포인트":	"POINT",
  // "상품권":	"GiftCard  ",
]);
const mobilePaymethodList = ref([
    { label: "신용카드", value: "CARD" },
    { label: "실시간 계좌이체", value: "BANK" },
  // { label: "가상계좌", value: "VBANK" },
  // { label: "휴대폰", value: "MOBILE" },
  // "도서문화상품권":	"BCSH",
]);


const loadData = async () => {
  const data = type === "fromCart" ? storageItems.value.map(item => item.id) : [productId];
  try {
    const response = await apiClient.post(API_URL + '/cart/list', {
      items: data
    });
    response.data.forEach(res => {
      delete res.qna;
      delete res.reviews;
    })
    loadItems.value = response.data;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }finally {
    calculatePrice();
    await fetchPaymentData();
  }
};

const fetchPaymentData = async () => {
  if(type === "fromCart"){
    storageItems.value.forEach(item => {
      item['optionId'] = item.selectedOption.id;
    });
    param.value = storageItems.value;
  }else{
    param.value = [{
      id: productId,
      quantity: quantity,
      optionId: optionId,
    }];
  }
  const data = { list : param.value };
  try {
    const response = await apiClient.post('/payment/data', data);
    paymentData.value = response.data.paymentData;
    user.value = response.data.user;
    recipient.value = response.data.user;
  } catch (error) {
    console.error('Error fetching payment data:', error);
  }finally {
    loading.value = false;
  }
};

const handleSearchAddress = async () => {
  try {
    const data = await common.searchAddress(); // 검색된 주소 데이터를 address에 저장
    recipient.value.address = data.address;
    recipient.value.zonecode = data.zonecode;
    recipient.value.buildingName = data.buildingName;
  } catch (error) {
    console.error('주소 검색 중 에러가 발생했습니다.', error);
  }
};

const paybtn = async () => {
  const data = {
    list : param.value,
    paymentMethod : selectedPaymethod.value
  }
  if(confirm("상품을 주문 하시겠습니까?")){
    if(selectedPaymethod.value){
      emit('mode-change', "pay");
      try {
        const response = await apiClient.post(API_URL + '/order/save', data);
        orderId.value = response.data.orderId;
      } catch (error) {
        console.error('Error fetching payment data:', error);
      }finally {
        if(isMobile.value){
          returnUrl.value = `${VUE_APP_API_BASE_URL}/api/payment/m/result?order_id=` + orderId.value;
        }else {
          returnUrl.value = `${VUE_APP_API_BASE_URL}/api/payment/result?order_id=` + orderId.value;
        }

        if(!orderId.value){
          setTimeout(() => {
          }, 2000);
        }else{
          setTimeout(() => {
            if(isMobile.value){
              let form = document.mobileweb;
              form.action = "https://mobile.inicis.com/smart/payment/";
              form.target = "_self";
              form.submit();
            }else{
              INIStdPay.pay('form');
            }
          }, 1000);
        }
      }
    }else{
      alert("결제수단을 선택 하세요.");
    }
  }
}
const calculatePrice = () => {
  priceQuantity.value = 0;
  if(type === "fromCart"){
    storageItems.value.forEach(item => {
      priceQuantity.value += item.quantity * item.selectedPrice;
    });
  }else{
    priceQuantity.value = loadItems.value[0].options[0].price * quantity;
  }
};

// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

// tag 값이 변경될 때마다 searchByTag 함수 호출
watch(orderId, () => {
  if(isMobile.value){
    returnUrl.value = `${VUE_APP_API_BASE_URL}/api/payment/m/result?order_id=` + orderId.value;
  }else {
    returnUrl.value = `${VUE_APP_API_BASE_URL}/api/payment/result?order_id=` + orderId.value;
  }
});

onMounted(() => {
  checkMobile();
  document.body.classList.remove('bg-white');
  loadData();
  loading.value = false;
});
</script>