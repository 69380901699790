<template>
  <!-- s::로그인 모달창 -->
  <div v-if="mode === 'login'" class="modal-popup modal-signin">
    <button type="button" class="close-modal" @click="closeModal">
      <img src="@/assets/images/icon/ico_close_b.svg" alt=""/>
    </button>
    <form @submit="handleLogin">
      <fieldset>
        <div class="field">
          <div class="label">아이디</div>
          <div class="input-box">
            <input v-model="username" @input="validateForm" name="username" type="text"/>
          </div>
          <p v-if="usernameError" class="txt" style="color: red;">{{ usernameError }}</p>
        </div>
        <div class="field">
          <div class="label">비밀번호</div>
          <div class="input-box">
            <div class="pwd-wrap">
              <input v-model="password" @input="validateForm" name="password" :type="passwordFieldType"/>
              <button @click="togglePasswordVisibility" type="button" :class="{ on: passwordFieldType === 'text'}" class="btn-pwd"></button>
            </div>
          </div>
          <p v-if="passwordError" class="txt" style="color: red;">{{ passwordError }}</p>
        </div>
        <div class="button-box">
          <button type="submit">로그인</button>
        </div>
      </fieldset>
    </form>
    <div class="link-box">
      <button type="button" class="btn modal-find" @click="changeTabMode('id')">아이디 찾기</button>
      <button type="button" class="btn modal-find" @click="changeTabMode('pwd')">비밀번호 찾기</button>
      <router-link to="/auth/signup" class="btn" @click="closeModal">회원가입</router-link>
    </div>
    <div class="sns-box">
      <OAuthLogin></OAuthLogin>
    </div>
  </div>
  <!-- e::로그인 모달창 -->

  <!-- s::아이디 비번찾기 모달창 -->
  <div class="modal-popup modal-find" v-if="mode === 'findInfo'">
    <div class="find-tab">
      <button type="button" :class="tabMode === 'id'?  'on' : ''" @click="changeTabMode('id')">아이디 찾기</button>
      <button type="button" :class="tabMode === 'pwd'?  'on' : ''" @click="changeTabMode('pwd')">비밀번호 찾기</button>
    </div>
    <div class="find-cont">
      <form ref="niceForm" name="form_chk" method="post">
        <input type="hidden" name="m" value="service"/>
        <input v-model="tokenVersionId" type="hidden" name="token_version_id"/>
        <input v-model="encData" type="hidden" name="enc_data"/>
        <input v-model="integrityValue" type="hidden" name="integrity_value"/>
      </form>
      <div id="find1" class="tab-cont" v-if="tabMode === 'id'">
        <h2>
          휴대폰 본인인증을 통해<br>
          아이디를 확인합니다.
        </h2>
        <button @click="openPop" type="button">휴대폰 본인인증</button>
        <p>
          문의 사항은 hello@article21.co.kr로 연락주시기 바랍니다.
        </p>
      </div>
      <div id="find2" class="tab-cont" v-if="tabMode === 'pwd'">
        <h2>
          가입한 <strong>아이디</strong>를 입력한 후<br>
          휴대폰 본인인증을 통해 비밀번호를 변경합니다.
        </h2>
        <div class="input-wrap">
          <input v-model="username" type="text">
        </div>
        <button @click="openPop" type="button">휴대폰 본인인증</button>
        <p>
          문의 사항은 hello@article21.co.kr로 연락주시기 바랍니다.
        </p>
      </div>
    </div>
    <div class="button-box">
      <button @click="closeModal" type="button" class="close-modal">닫기</button>
    </div>
  </div>

  <div class="modal-popup modal-find" v-if="tabMode === 'findIdResult'">
    <div class="find-cont">
      <div id="find1" class="tab-cont">
        <h2>고객님의 아이디는<br>{{ username }} 입니다.</h2>
      </div>
    </div>
    <div class="button-box">
      <button @click="closeModal" type="button" class="close-modal">닫기</button>
    </div>
  </div>

  <div class="modal-popup modal-signin" v-if="tabMode === 'findPwdResult'">
    <button type="button" class="close-modal" @click="closeModal">
      <img src="@/assets/images/icon/ico_close_b.svg" alt=""/>
    </button>
    <div class="find-cont">
      <div id="find2" class="tab-cont">
        <h2>비밀번호 변경</h2>
        <form>
          <fieldset>
            <div class="field">
              <div class="input-box">
                <div class="pwd-wrap">
                  <input ref="password1Ref" :type="password1FieldType" v-model="password1" maxlength="16"
                         @input="validatePasswordForm"
                         placeholder="8~16자의 영문 대/소문자+숫자+특수문자">
                  <button @click="togglePassword1Visibility" :class="{ on: password1FieldType === 'text'}"
                          type="button" class="btn-pwd"></button>
                </div>
              </div>
              <p v-if="passwordError1" class="txt" style="color: red;">{{ passwordError1 }}</p>
            </div>
            <div class="field">
              <div class="input-box">
                <div class="pwd-wrap">
                  <input ref="password2Ref" :type="password2FieldType" v-model="password2" maxlength="16"
                         @input="validatePasswordForm"
                         placeholder="8~16자의 영문 대/소문자+숫자+특수문자">
                  <button @click="togglePassword2Visibility" :class="{ on: password2FieldType === 'text'}"
                          type="button" class="btn-pwd"></button>
                </div>
              </div>
              <p v-if="passwordError2" class="txt" style="color: red;">{{ passwordError2 }}</p>
              <p class="txt">비밀번호를 한번 더 입력해 주세요.</p>
            </div>
            <div class="button-box">
              <button @click="changePassword" type="button">확인</button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
  <!-- e::아이디 비번찾기 모달창 -->
</template>

<script setup>
import {onMounted, ref, toRefs, watch} from 'vue';
import OAuthLogin from "@/components/auth/OAuthLogin.vue";
import {useAuthStore} from "@/store/auth.module";
import router from "@/router";
import {apiClient} from "@/services/auth-header";

const authStore = useAuthStore();
const username = ref('');
const password = ref('');
const password1 = ref('');
const password2 = ref('');
const usernameError = ref('');
const passwordError = ref('');
const tabMode = ref('findId');
const emit = defineEmits(['mode-change']);
const props = defineProps({
  mode: String
});
const validateForm = async () => {
  let isValid = true;
  if (!username.value) {
    usernameError.value = '아이디를 입력해 주세요.';
    isValid = false;
  } else {
    usernameError.value = '';
  }

  if (!password.value) {
    passwordError.value = '비밀번호를 입력해 주세요.';
    isValid = false;
  } else {
    passwordError.value = '';
  }
 return isValid;
}

const closeModal = () => {
  tabMode.value = "none";
  emit('mode-change', 'none');
};
const changeTabMode = (mode) => {
  emit('mode-change', "findInfo");
  tabMode.value = mode;
  authentication(mode)
};
const handleLogin = async (event) => {
  event.preventDefault();
  try {
    await authStore.login({ username: username.value, password: password.value });
    emit('mode-change', 'none');
    if($(".h-cont").hasClass("active")){
      $(".h-cont").removeClass("active");
    }
    if($(".nav-icon").hasClass("open")){
      $(".nav-icon").removeClass("open");
    }
    await router.push("/");
  } catch (error) {
    console.error("error", error)
  }
};
const passwordFieldType = ref('password');
const togglePasswordVisibility = () => {
  passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
};

const niceForm = ref(null);
const tokenVersionId = ref(null);
const encData = ref(null);
const integrityValue = ref(null);
const verify = ref({
  token_version_id: "",
  req_no: "",
  key: "",
  iv: "",
  hmac_key: "",
});
const authentication = async (type) => {
  localStorage.removeItem('verify');
  const returnURL = type === 'id' ? "https://article21.kr/auth/verifyResult?type=id" :
      "https://article21.kr/auth/verifyResult?type=pwd"
  // const returnURL = type === 'id' ? "http://localhost:8080/auth/verifyResult?type=id" :
  //     "http://localhost:8080/auth/verifyResult?type=pwd"
  try {
    const response = await apiClient.post('/auth/identityVerify', {
      returnURL: returnURL
    });
    tokenVersionId.value = response.data.token_version_id;
    encData.value = response.data.enc_data;
    integrityValue.value = response.data.integrity;
    verify.value.token_version_id = response.data.token_version_id;
    verify.value.req_no = response.data.req_no;
    verify.value.key = response.data.key;
    verify.value.iv = response.data.iv;
    verify.value.hmac_key = response.data.hmac_key;
    localStorage.setItem('verify', JSON.stringify(verify.value));
  } catch (error) {
    console.error(error);
  }
}

const openPop = () => {
  if(tabMode.value === "pwd"){
    if(!username.value){
      alert("아이디를 입력해 주세요.");
      return;
    }
    localStorage.setItem("username", username.value);
  }
  // 팝업 창 열기
  const popup = window.open('', 'popupChk', 'width=480,height=812,top=100,left=100,menubar=no,status=no,toolbar=no,titlebar=yes,location=no,scrollbars=no');
  if (popup) {
    niceForm.value.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
    niceForm.value.target = "popupChk";
    niceForm.value.submit();
    // 팝업 창 닫힘 감지
    const popupCheckInterval = setInterval(() => {
      if (popup.closed) {
        const userId = localStorage.getItem("username");
        if(tabMode.value === "id"){
          if(userId){
            tabMode.value = "findIdResult";
            username.value = userId;
          }
        }else{
          const jwtToken = localStorage.getItem("jwtToken");
          if(jwtToken){
            tabMode.value = "findPwdResult";
          }
        }
        localStorage.removeItem("username");
        clearInterval(popupCheckInterval);
      }
    }, 500); // 500ms 간격으로 체크
  } else {
    console.error('팝업 창이 차단되었거나 열리지 않았습니다.');
  }
};

const password1FieldType = ref('password');
const password2FieldType = ref('password');
const togglePassword1Visibility = () => {
  password1FieldType.value = password1FieldType.value === 'password' ? 'text' : 'password';
};
const togglePassword2Visibility = () => {
  password2FieldType.value = password2FieldType.value === 'password' ? 'text' : 'password';
};

const password1Ref = ref(null);
const password2Ref = ref(null);
const passwordError1 = ref('');
const passwordError2 = ref('');
const validatePasswordForm = async () => {
  // Password validation
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?`~]).{8,16}$/;
  if (!passwordRegex.test(password1.value)) {
    passwordError1.value = '8~16자의 영문 대/소문자+숫자+특수문자를 사용해 주세요.';
    password1Ref.value.focus();
    return false;
  } else {
    passwordError1.value = '';
  }

  if (!passwordRegex.test(password2.value)) {
    passwordError2.value = '8~16자의 영문 대/소문자+숫자+특수문자를 사용해 주세요.';
    password2Ref.value.focus();
    return false;
  } else if (password1.value !== password2.value) {
    passwordError2.value = '비밀번호가 일치하지 않습니다.';
    password2Ref.value.focus();
    return false;
  } else {
    passwordError2.value = '';
  }
  return true;
}

const changePassword = async (type) => {
  const isValid = await validatePasswordForm();
  if (!isValid) {
    return;
  }
  const jwtToken = localStorage.getItem('jwtToken');
  try {
    const response = await apiClient.post('/auth/identityVerify/updatePassword', {
      password: password1.value
    }, {
      headers: {
        Authorization: `Bearer ${jwtToken}` // Authorization 헤더에 JWT 토큰을 추가
      }
    });
    alert(response.data);
    localStorage.removeItem('jwtToken');
    location.reload();
  } catch (error) {
    console.error(error);
  }
}

onMounted(() => {
  emit('mode-change', 'none');
});
</script>