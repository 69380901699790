<template>
  <dl>
    <dt class="on"><a href="#">마이 페이지</a></dt>
    <dd>
      <ul>
        <li @click="toggleMenu" :class="{ on: route.path.includes('info')}">
          <router-link :to="{ name: 'MyPage'}">나의 정보</router-link>
        </li>
        <li @click="toggleMenu" :class="{ on: route.path.includes('password')}">
          <router-link :to="{ name: 'PasswordUpdate'}">비밀번호 변경</router-link>
        </li>
      </ul>
    </dd>
    <dt v-if="isArtist" :class="{ on: route.path.includes('artist')}"><a href="#">ART & ARTISTS</a></dt>
    <dd v-if="isArtist">
      <ul>
        <li @click="toggleMenu" :class="{ on: route.path.includes('profile')}">
          <router-link :to="{ name: 'MyProfile'}">프로필</router-link>
        </li>
        <li @click="toggleMenu" :class="{ on: route.path.includes('artwork')}">
          <router-link :to="{ name: 'ProfileArtwork'}">아트 워크</router-link>
        </li>
        <li @click="toggleMenu" :class="{ on: route.path.includes('portfolio')}">
          <router-link :to="{ name: 'Portfolio'}">포트폴리오</router-link>
        </li>
        <li @click="toggleMenu" :class="{ on: route.path.includes('favoriteArtist')}">
<!--          <router-link :to="{ name: 'FavoriteArtist'}">관심 아티스트</router-link>-->
          <li><a href="#">관심 아티스트</a></li>
        </li>
      </ul>
    </dd>
    <dt class="on"><a href="#">ARTICLER</a></dt>
    <dd>
      <ul>
        <li><a href="#">관심 커뮤니티</a></li>
      </ul>
    </dd>
    <dt class="on"><a href="#">ARTICLE</a></dt>
    <dd>
      <ul>
        <li @click="toggleMenu" :class="{ on: route.path.includes('Scrap')}">
          <router-link :to="{ name: 'Scrap'}">스크랩 아티클</router-link>
        </li>
      </ul>
    </dd>
    <dt class="on"><a href="#">ARTSHOP</a></dt>
    <dd>
      <ul>
        <li @click="toggleMenu" :class="{ on: route.path.includes('order')}">
          <router-link to="/my/order">주문/배송</router-link>
        </li>
        <li @click="toggleMenu" :class="{ on: route.path.includes('wishlist')}">
          <router-link to="/my/wishlist">관심 상품</router-link>
        </li>
        <li @click="toggleMenu" :class="{ on: route.path.includes('review')}">
          <router-link to="/my/review">구매 후기</router-link>
        </li>
        <li @click="toggleMenu" :class="{ on: route.path.includes('coupon')}">
          <router-link to="/my/coupon">쿠폰</router-link>
        </li>
      </ul>
    </dd>
    <dt class="on"><a href="#">고객 센터</a></dt>
    <dd>
      <ul>
        <li @click="toggleMenu" :class="{ on: route.path.includes('notice')}">
          <router-link to="/support/notice">공지 사항</router-link>
        </li>
        <li @click="toggleMenu" :class="{ on: route.path.includes('inquiry')}">
          <router-link to="/support/inquiry">1:1 문의</router-link>
        </li>
      </ul>
    </dd>
  </dl>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useRoute} from 'vue-router';
import {useAuthStore} from '@/store/auth.module';
const authStore = useAuthStore();
const route = useRoute();
const user = authStore.status.user;

const isArtist = ref(false);
if (user && user.roles && user.roles.length > 0) {
  isArtist.value = user.roles[0] === "ROLE_ARTIST";
}

const isMobile = ref(false);
const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};
// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

onMounted(() => {
  checkMobile();
});

</script>
